import React, { useState, useEffect } from 'react';

import { Alert } from '@msdyn365-commerce-modules/utilities';
import { IAddToCartFailureResult } from '@msdyn365-commerce/components';
import { IImageSettings } from '@msdyn365-commerce/core';

import { getConfigureDonationsErrors, getGenericDonationsError, getQuantityError } from '../utilities/btrts-donations-error-utilities';
import { SimpleProduct, ProductPrice, ProductAvailableQuantity, ProductDeliveryOptions, ProductWarehouseInventoryAvailability } from '@msdyn365-commerce/retail-proxy';
import { IProductInventoryInformation, FinitePromiseQueue, FinitePromiseQueueError, IPromiseQueue } from '@msdyn365-commerce-modules/retail-actions';

import { ProductDetailsAddToCartComponent } from './ecomm-form-add-to-cart-source.component';

import { ICartState } from '@msdyn365-commerce/global-state';

import {
    IProductDetailsAddtoCartCallbacks,
    IProductDetailsAddToCartButtonProps,
    IProductDetailsBtrtsBuyboxResources,
    IProductDetailsErrorState,
    IProductDetailsErrorBlockProps,
    IProductDetailsProductListState,
} from './components-interfaces-definition';

import resource from '../resources/resource.json';

export const ProductDetailsAddtoCartButton: React.FC<IProductDetailsAddToCartButtonProps> = ({
  product, simpleProductList, productsAvailability, productPriceList, cart, resources, context, config, localeCode, telemetryContent, isCustomPriceSelected, keyInPriceAmount, isPriceKeyedIn, cartAdded, matchingCartItems, events, eventIDs, serialIds, serialNumbers, serialNames, names, dedicatedBy, categories, messages, remarks, collectionOptions, hasTaxDeductions, nrics, buildingNames, unitNumbers, postalCodes, phoneNumbers,chineseNames, birthDates, birthTimes, schoolNames, blessingOptions, lightOptions, validFroms, validTos, dedicationPeriods, startDates, genders, countries, companyNames, deceasedRelationships, surgeryDates, deathDates, deathTimes, nameChanges, oldNames, quantities, subProduct, subProductQuantities, disabled, isEditMode, nameNextKins, contactNumbers, contactNumbersNextKin, passportNumbers, passportExpiryDates, passportIssuedDates,consents, isInsuranceIncluded }: any) => {
  const [callbackState, setCallBackState] = useState<IProductDetailsProductListState>({
    quantity: 1,
    min: undefined,
    max: undefined,
    errorState: {
      configureErrors: {},
      quantityError: '',
      customAmountError: '',
      otherError: '',
      errorHost: 'ADDTOCART'
    },
    selectedProduct: undefined,
    productAvailableQuantity: undefined,
    productPrice: undefined,
    productDeliveryOptions: undefined,
    modalOpen: undefined,
    isUpdatingDimension: undefined,
    isUpdatingDeliveryOptions: undefined,
    isServiceItem: undefined,
    isPriceKeyedIn: undefined,
    keyInPriceAmount: undefined,
    isCustomPriceSelected: undefined
  });

  const defaultImageSettings: IImageSettings = {
    viewports: {
      xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
      lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
      xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
    },
    lazyload: true
  };

  const defaultMinimumKeyInPrice = config.minimumKeyInPrice;
  const defaultMaximumKeyInPrice = config.maximumKeyInPrice;

  let dimensionUpdateQueue: IPromiseQueue<void> = new FinitePromiseQueue<void>(2);
  let simpleProductValue: SimpleProduct = (simpleProductList.length > 0 ? simpleProductList[0] : []);
  let productPrice: ProductPrice = productPriceList.filter((record: ProductPrice) => product.RecordId === record.ProductId);
  let cartValue: ICartState = cart;
  let dimensions: { [id: number]: string } = {};
  let id = product['RecordId'];
  let typeName = 'ecomm-product-details-form';
  let isLoadingDeliveryOptions = false;
  let isUpdatingDeliveryOptions = false;
  let isUpdatingDimension = false;
  let maximumKeyInPrice: number | undefined;
  let minimumKeyInPrice: number | undefined;
  let isEmailDelivery = false;
  let isServiceItem = Number(callbackState.keyInPriceAmount) == 0 ? true : false;
  let isOrderQuantityLimitsFeatureEnabled = false;
  let productAvailability: ProductAvailableQuantity = productsAvailability?.filter((record: ProductWarehouseInventoryAvailability) => product.RecordId === record.ProductId).map((elem: ProductWarehouseInventoryAvailability) => {
    return {
      ProductId: elem.ProductId,
      AvailableQuantity: elem.TotalAvailable,
      UnitOfMeasure: 'Unit',
      ExtensionProperties: []
    };
  });

  const dialogStrings = {
    goToCartText: resources.buyBoxGoToCartText,
    continueShoppingText: resources.buyBoxContinueShoppingText,
    headerItemOneText: resources.buyBoxSingleItemText,
    headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
    headerMessageText: resources.buyBoxHeaderMessageText,
    freePriceText: resources.priceFree,
    originalPriceText: resources.originalPriceText,
    currentPriceText: resources.currentPriceText
  };

  let addToCartCallbacks: IProductDetailsAddtoCartCallbacks = {
    updateQuantity: (newQuantity: number): boolean => {
        const errorStateVal = { ...callbackState.errorState };
        errorStateVal.quantityError = undefined;
        errorStateVal.otherError = undefined;

        setCallBackState(prevState => ({
            ...prevState,
            quantity: newQuantity,
            errorState: errorStateVal
        })
        );
        return true;
    },
    updateErrorState: (newErrorState: IProductDetailsErrorState): void => {
        setCallBackState(prevState => ({
            ...prevState,
            errorState: newErrorState
        })
        );
    },
    updateSelectedProduct: (
        newSelectedProduct: SimpleProduct,
        newInventory: IProductInventoryInformation | undefined,
        newPrice: ProductPrice | undefined,
        newDeliveryOptions: ProductDeliveryOptions | undefined
    ): void => {
        setCallBackState(prevState => ({
            ...prevState,
            selectedProduct: newSelectedProduct,
            productAvailableQuantity: newInventory,
            productDeliveryOptions: newDeliveryOptions
        }));
        // _updatePrice(newPrice);
    },
    dimensionSelectedAsync: (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
        dimensions[`${selectedDimensionId}`] = selectedDimensionValueId;
        return dimensionUpdateQueue.enqueue(() => {
            // Return Promise<void>, just temporary.
            return new Promise<void>(r => r());// _updateDimensions();
        }).catch((reason: any) => { // tslint:disable-line:no-any
            // Ignore discarded processes.
            if (reason !== FinitePromiseQueueError.ProcessWasDiscardedFromTheQueue) {
                throw reason;
            }
        });
    },
    getDropdownName: (dimensionType: number, resource: IProductDetailsBtrtsBuyboxResources): string => {
        return 'getDropDownName';
        // return this._getDropdownName(dimensionType, resources);
    },
    changeModalOpen: (isModalOpen: boolean): void => {
        setCallBackState(prevState => ({
            ...prevState,
            modalOpen: isModalOpen
        }));
    },
    changeUpdatingDimension: (isUpdDimension: boolean): void => {
        setCallBackState(prevState => ({
            ...prevState,
            isUpdatingDimension: isUpdDimension
        }));
    },
    /**
     * Update isUpdatingDeliveryOptions state.
     *
     * @param isUpdatingDeliveryOptions - The status of updating delivery options.
     */
    changeUpdatingDeliveryOptions: (isUpdDeliveryOptions: boolean): void => {
        setCallBackState(prevState => ({
            ...prevState,
            isUpdatingDeliveryOptions: isUpdDeliveryOptions
        }));
    },
    updateKeyInPrice: (customPrice: number): void => {
        const errorStateVal = { ...callbackState.errorState };
        errorStateVal.customAmountError = undefined;

        setCallBackState(prevState => ({
            ...prevState,
            isPriceKeyedIn: true,
            keyInPriceAmount: customPrice,
            errorState: errorStateVal
        }));
        // this._updatePrice(this.state.productPrice, customPrice);
    }
  };

  useEffect(() => {
    let simpleProduct: SimpleProduct = simpleProductValue;
    let productPriceVal: ProductPrice = productPriceList.filter((record: ProductPrice) => product.RecordId === record.ProductId);

    setCallBackState(prevState => ({
      ...prevState,
      selectedProduct: simpleProduct,
      productPrice: productPriceVal[0]
    }));
  }, []);

  const onAddToCartFailed = async (result: IAddToCartFailureResult) => {
    let quantityErrorForState: string | undefined;
    let customAmountErrorForState: string | undefined;
    let cartObj: ICartState = (await cart);
    let otherErrorForState: string | undefined = getGenericDonationsError(result, cartObj, resources, context, simpleProductValue, productAvailability[0], undefined);

    if (result.failureReason === 'OUTOFSTOCK') {
      quantityErrorForState = result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined;
    } else if (result.failureReason === 'CARTACTIONFAILED' && result.cartActionResult && (result.cartActionResult.substatus === 'MAXQUANTITY' || result.cartActionResult.substatus === 'QUANTITYLIMITS')) {
      quantityErrorForState = getGenericDonationsError(result, cartObj, resources, context, product, productsAvailability, undefined);

      // Prevent error duplication in otherError and quantityError.
      otherErrorForState = undefined;
    }

    if (result.failureReason === 'INVALIDCUSTOMAMOUNT') {
      if (callbackState.keyInPriceAmount === undefined || callbackState.keyInPriceAmount[0] < (minimumKeyInPrice || defaultMinimumKeyInPrice)) {
        const formatedAmountLimit = context.cultureFormatter.formatCurrency(minimumKeyInPrice || defaultMinimumKeyInPrice);

        customAmountErrorForState = resources.invalidSmallCustomAmountText.replace('{minAmount}', formatedAmountLimit);
      } else if (callbackState.keyInPriceAmount[0] > (maximumKeyInPrice || defaultMaximumKeyInPrice)) {
        const formatedAmountLimit = context.cultureFormatter.formatCurrency(maximumKeyInPrice || defaultMaximumKeyInPrice);

        customAmountErrorForState = resources.invalidLargeCustomAmountText.replace('{maxAmount}', formatedAmountLimit);
      }
    }

    setCallBackState(prevState => ({
      ...prevState,
      errorState: {
        errorHost: 'ADDTOCART',
        quantityError: quantityErrorForState,
        configureErrors: result.failureReason === 'MISSINGDIMENSION' ? getConfigureDonationsErrors(result.missingDimensions, resources, product?.IsGiftCard) : {},
        customAmountError: customAmountErrorForState,
        otherError: otherErrorForState
      }
    })
    );
  };

  const onAddToCartSuccess = () => {
    if (callbackState.errorState?.quantityError !== '' || callbackState.errorState?.customAmountError !== '' || callbackState.errorState?.otherError !== '') {
      setCallBackState(prevState => ({
        ...prevState,
        errorState: {
          configureErrors: {},
          quantityError: '',
          customAmountError: '',
          otherError: '',
          errorHost: 'ADDTOCART'
        }
      }));
    }

    // Updating state variable.
    cartAdded();
  };

  return (
    <div className={'ms-buybox__add-to-cart-container'}>
      {product && (
        <ProductDetailsAddToCartComponent
          addToCartText={isEditMode ? resource.updateCartTitle[`${localeCode}`] : resource.addToCartTitle[`${localeCode}`]}
          outOfStockText={resources.ProductDetailsOutOfStockText}
          // navigationUrl={getUrlSync('cart', context.actionContext)}
          quantity={callbackState.quantity}
          data={{ product: simpleProductValue, price: productPrice[0], cart: cartValue }}
          context={context}
          id={id}
          typeName={typeName}
          onError={onAddToCartFailed}
          onAdd={onAddToCartSuccess}
          getSelectedProduct={callbackState.selectedProduct}
          productAvailability={productAvailability}
          isLoading={false}
          isUpdatingDimension={isUpdatingDimension}
          changeUpdatingDimension={addToCartCallbacks.changeUpdatingDimension}
          isLoadingDeliveryOptions={isLoadingDeliveryOptions}
          isUpdatingDeliveryOptions={isUpdatingDeliveryOptions}
          changeUpdatingDeliveryOptions={addToCartCallbacks.changeUpdatingDeliveryOptions}
          dialogStrings={dialogStrings}
          gridSettings={context.request.gridSettings}
          imageSettings={defaultImageSettings}
          telemetryContent={telemetryContent}
          isAddServiceItemToCart={isServiceItem}
          isPriceKeyedIn={isPriceKeyedIn}
          customPriceAmount={keyInPriceAmount}
          isCustomPriceSelected={isCustomPriceSelected}
          maximumKeyInPrice={config.maximumKeyInPrice}
          minimumKeyInPrice={config.minimumKeyInPrice}
          defaultMinimumKeyInPrice={defaultMinimumKeyInPrice}
          defaultMaximumKeyInPrice={defaultMaximumKeyInPrice}
          isOrderQuantityLimitsFeatureEnabled={isOrderQuantityLimitsFeatureEnabled}
          isAddEmailDeliveryItemToCart={isEmailDelivery}
          matchingCartItems={matchingCartItems}
          events={events}
          eventIDs={eventIDs}
          serialIds={serialIds}
          serialNumbers={serialNumbers}
          serialNames={serialNames}
          names={names}
          dedicatedBy={dedicatedBy}
          categories={categories}
          messages={messages}
          remarks={remarks}
          disabled={disabled}
          collectionOptions={collectionOptions}
          hasTaxDeductions={hasTaxDeductions}
          nrics={nrics}
          buildingNames={buildingNames}
          unitNumbers={unitNumbers}
          postalCodes={postalCodes}
          phoneNumbers={phoneNumbers}
          chineseNames={chineseNames}
          birthDates={birthDates}
          birthTimes={birthTimes}
          schoolNames={schoolNames}
          blessingOptions={blessingOptions}
          lightOptions={lightOptions}
          validFroms={validFroms}
          validTos={validTos}
          dedicationPeriods={dedicationPeriods}
          startDates={startDates}
          genders={genders}
          countries={countries}
          companyNames={companyNames}
          deceasedRelationships={deceasedRelationships}
          surgeryDates={surgeryDates}
          deathDates={deathDates}
          deathTimes={deathTimes}
          nameChanges={nameChanges}
          oldNames={oldNames}
          quantities={quantities}
          subProduct={subProduct}
          subProductQuantities={subProductQuantities}
          nameNextKins={nameNextKins} 
          contactNumbers={contactNumbers} 
          contactNumbersNextKin={contactNumbersNextKin}
          passportNumbers = {passportNumbers}
          passportExpiryDates = {passportExpiryDates}
          passportIssuedDates = {passportIssuedDates}
          consents = {consents}
          isInsuranceIncluded = {isInsuranceIncluded}
        />
      )}
      <PrductDetailsAddtoCartErrorBlock
        configureErrors={callbackState.errorState.configureErrors}
        quantityError={callbackState.errorState.quantityError}
        customAmountError={callbackState.errorState.customAmountError}
        otherError={callbackState.errorState.otherError}
        resources={resources}
        showError={callbackState.errorState.errorHost === 'ADDTOCART'}
      />
    </div>
  );
};

export const PrductDetailsAddtoCartErrorBlock: React.FC<IProductDetailsErrorBlockProps> = ({ showError, configureErrors, quantityError, customAmountError, otherError, resources }) => {
  let errorMessages: (string | undefined)[] = [];

  errorMessages = Object.values(configureErrors).filter(message => message !== undefined);

  if (quantityError) {
    errorMessages.push(quantityError);
  }

  if (customAmountError) {
    errorMessages.push(customAmountError);
  }

  if (otherError) {
    errorMessages.push(otherError);
  }

  return (
    <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={resources.buyboxErrorMessageHeader} >
      <div className='msc-alert__header' aria-hidden='true'>
        <span className='msi-exclamation-triangle' />
        <span>{resources.buyboxErrorMessageHeader}</span>
      </div>
      {errorMessages.map((message, index) => {
        return <div key={index} className='msc-alert__line'>{message}</div>;
      })}
    </Alert>
  );
};
