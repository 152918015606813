import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackCategory?: any;
  callbackBuilding: any;
  callbackUnitNumber: any;
  callbackPostalCode: any;
  callbackPhoneNumber: any;
  callbackNamesEntered?: any;
  callbackAddressEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  userInfo: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const CategoryNamesAddressForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackEvent, callbackEventID, callbackName, callbackCategory, callbackBuilding, callbackUnitNumber,
  callbackPostalCode, callbackPhoneNumber, callbackNamesEntered, callbackAddressEntered,
  formItems, config, localeCode, actionContext, userInfo, isMobile, isEditMode, productEvents, allowMultipleRegistrations }) => {
  const [eventNames, setEventNames] = useState(['']);
  const [eventIDs, setEventIDs] = useState(['']);
  const [categories, setCategories] = useState([resource.categoryFamilyLabel[`${localeCode}`]]);
  const [names, setNames] = useState(['']);
  const [buildingNames, setBuildingNames] = useState(['']);
  const [unitNumbers, setUnitNumbers] = useState(['']);
  const [postalCodes, setPostalCodes] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);

  const formLabels = resource.categoryNamesAddressForm[`${localeCode}`];

  const namesCount = formScheme.find(form => form.field_name === 'name')!.field_number!;
  const companyCount = formScheme.find(form => form.field_name === 'name')!.field_number2!;
  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _onChangeCategory = (event: any, index: number) => {
    _setCategory(event.target.value, index);

    const namesArray = names.slice();

    let value = namesArray[`${index}`];

    if (value) {
      const count = event.target.value === resource.categoryFamilyLabel[`${localeCode}`] ? namesCount : companyCount;

      let valueArray = value.split(';');

      if (valueArray.length < count) {
        value += ';'.repeat(count - valueArray.length - 1);
      } else { 
        while (valueArray.length > count) {
          valueArray.pop();
        }

        value = valueArray.join(';');
      }

      namesArray[`${index}`] = value;

      setNames(namesArray);

      callbackName(namesArray);
    }
  }

  const _setCategory = (value: string, index: number) => {
    const valueArray = categories.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    valueArray[`${index}`] = value;

    setCategories(valueArray);

    callbackCategory(valueArray);
  }

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _formatNameInput = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleNames = names[`${index}`];

    if (inputMultipleNames) {
      if (!inputMultipleNames.includes(';')) {
        inputMultipleNames += ';'.repeat(noOfNames - 1);
      }

      const newValues = inputMultipleNames.split(';');

      newValues[`${nameIndex}`] = inputName;
      inputName = newValues.join(';');
    }
    
    _setName(inputName, index);
  }

  const _setName = (name: string, index: number) => {
    const ceremonyNameArray = names.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setNames(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNameEntered(ceremonyNameArray);
  }

  const _setBuildingName = (value: string, index: number) => {
    const valueArray = buildingNames.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setBuildingNames(valueArray);

    callbackBuilding(valueArray);

    _checkAddressEntered(valueArray, postalCodes);
  }

  const _setUnitNumber = (value: string, index: number) => {
    const valueArray = unitNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setUnitNumbers(valueArray);

    callbackUnitNumber(valueArray);
  }

  const _setPostalCode = (value: string, index: number) => {
    const valueArray = postalCodes.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    if(value.length<=6){
      valueArray[`${index}`] = value;
    }else{
      toast.warning(resource.invalidPostalCodeMessage[`${localeCode}`]);
    }

    setPostalCodes(valueArray);

    callbackPostalCode(valueArray);

    _checkAddressEntered(buildingNames, valueArray);
  }

  const _setPhoneNumber = (value: string, index: number) => {
    const valueArray = phoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setPhoneNumbers(valueArray);

    callbackPhoneNumber(valueArray);
  }

  const _checkNameEntered = (valueArray: string[]) => {
    let checked = true;

    if (!valueArray || valueArray.length === 0) {
      checked = false;
    }

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        checked = false; break;
      }
    }

    callbackNamesEntered(checked);
  }

  const _checkAddressEntered = (buildingNamesArray: string[], postalCodesArray: string[]) => {
    let result = true;

    for (let i = 0; i < buildingNamesArray.length; ++i) {
      result = result && buildingNamesArray[`${i}`] !== '' && postalCodesArray[`${i}`] !== '';
    }

    callbackAddressEntered(result);
  }

  const _initBuildingField = () => {
    if (userValid) {
      return userInfo.gmb_blockno.trim() + (userInfo.gmb_buildingno !== '' ? ', ' + userInfo.gmb_buildingno.trim() : '') + (userInfo.gmb_buildingname !== '' ? ', ' + userInfo.gmb_buildingname.trim() : '') + (userInfo.address1_line1 !== '' ? ', ' + userInfo.address1_line1.trim() : '');
    }

    return '';
  }

  const _addItem = () => {
    addRow(1);

    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();
    const namesArray = names.slice();
    const categoriesArray = categories.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();
    const phoneNumbersArray = phoneNumbers.slice();

    eventNamesArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
    eventIDsArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
    namesArray.push('');
    categoriesArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    phoneNumbersArray.push('');

    if (userValid) {
      buildingNamesArray.push(_initBuildingField());
      unitNumbersArray.push(userInfo.gmb_unitnofloorapartmentno);
      postalCodesArray.push(userInfo.address1_postalcode);
    } else {
      buildingNamesArray.push('');
      unitNumbersArray.push('');
      postalCodesArray.push('');
    }

    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);
    setNames(namesArray);
    setCategories(categoriesArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);
    setPhoneNumbers(phoneNumbersArray);

    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackName(namesArray);
    callbackCategory(categoriesArray);
    callbackBuilding(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);
    callbackPhoneNumber(phoneNumbersArray);

    callbackNamesEntered(false);
    
    _checkAddressEntered(buildingNamesArray, postalCodesArray);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
        
          formItems.splice(index, 1);
        
          _updateStateData(index);
        }

      } else {
        deleteRow(index);

        _updateStateData(index);
      }
    } else {
      deleteRow(index);

      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();
    const namesArray = names.slice();
    const categoriesArray = categories.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();

    if (typeof eventNamesArray[`${index}`] !== 'undefined') {
      if (eventNamesArray.length > 1) {
        eventNamesArray.splice(index, 1);
      } else {
        eventNamesArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      }
    }

    if (typeof eventIDsArray[`${index}`] !== 'undefined') {
      if (eventIDsArray.length > 1) {
        eventIDsArray.splice(index, 1);
      } else {
        eventIDsArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';
      }
    }

    if (typeof namesArray[`${index}`] !== 'undefined') {
      if (namesArray.length > 1) {
        namesArray.splice(index, 1);
      } else {
        namesArray[0] = '';
      }
    }

    if (typeof categoriesArray[`${index}`] !== 'undefined') {
      if (categoriesArray.length > 1) {
        categoriesArray.splice(index, 1);
      } else {
        categoriesArray[0] = resource.categoryFamilyLabel[`${localeCode}`];
      }
    }

    if (typeof buildingNamesArray[`${index}`] !== 'undefined') {
      if (buildingNamesArray.length > 1) {
        buildingNamesArray.splice(index, 1);
      } else {
        buildingNamesArray[0] = '';
      }
    }

    if (typeof unitNumbersArray[`${index}`] !== 'undefined') {
      if (unitNumbersArray.length > 1) {
        unitNumbersArray.splice(index, 1);
      } else {
        unitNumbersArray[0] = '';
      }
    }

    if (typeof postalCodesArray[`${index}`] !== 'undefined') {
      if (postalCodesArray.length > 1) {
        postalCodesArray.splice(index, 1);
      } else {
        postalCodesArray[0] = '';
      }
    }

    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);
    setNames(namesArray);
    setCategories(categoriesArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);

    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackName(namesArray);
    callbackCategory(categoriesArray);
    callbackAddressEntered(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);

    _checkNameEntered(namesArray);
    _checkAddressEntered(buildingNamesArray, postalCodesArray);
  }

  useEffect(() => {
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const namesArray: string[] = [];
    const categoriesArray: string[] = [];
    const buildingNamesArray: string[] = [];
    const unitNumbersArray: string[] = [];
    const postalCodesArray: string[] = [];
    const phoneNumbersArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const itemNames: string[] = formItems[`${i}`]['names'];
        const itemCategory = formItems[`${i}`]['category'];

        const itemNameCount = itemCategory === resource.categoryFamilyLabel[`${localeCode}`] ? namesCount : companyCount;

        let itemName = '';

        itemNames.forEach((name, index) => itemName += index != 0 && index < itemNameCount ? ';' + name : name);

        eventNamesArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
        eventIDsArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
        namesArray.push(itemName);
        categoriesArray.push(itemCategory);
        buildingNamesArray.push(formItems[`${i}`]['buildingName']);
        unitNumbersArray.push(formItems[`${i}`]['unitNumber']);
        postalCodesArray.push(formItems[`${i}`]['postalCode']);
        phoneNumbersArray.push(formItems[`${i}`]['phoneNumber']);
      }

      setNames(namesArray);

      callbackName(namesArray);

      _checkNameEntered(namesArray);
    } else {
      eventNamesArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
      eventIDsArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
      categoriesArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
      buildingNamesArray.push(_initBuildingField());
      phoneNumbersArray.push('');

      if (userValid) {
        unitNumbersArray.push(userInfo.gmb_unitnofloorapartmentno);
        postalCodesArray.push(userInfo.address1_postalcode);
      } else {
        unitNumbersArray.push('');
        postalCodesArray.push('');
      }
    }

    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);
    setCategories(categoriesArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);
    setPhoneNumbers(phoneNumbersArray);

    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackCategory(categoriesArray);
    callbackBuilding(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);
    callbackPhoneNumber(phoneNumbersArray);

    _checkAddressEntered(buildingNamesArray, postalCodesArray);
  }, [formItems]);

  const _buildCategoryField = (idx: number): JSX.Element | null => {
    if (typeof categories[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <p className='form-label'>{formLabels.category}</p>
          <div className='form-field row'>
            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.categoryFamilyLabel[`${localeCode}`]}
                checked={categories[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`]).valueOf()}
                aria-checked={categories[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`]).valueOf()}
                onChange={(event) => _onChangeCategory(event, idx)}
              />
              {resource.categoryFamilyLabel[`${localeCode}`]}
            </label>

            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.categoryCompanyLabel[`${localeCode}`]}
                checked={categories[`${idx}`] === String(resource.categoryCompanyLabel[`${localeCode}`])}
                aria-checked={categories[`${idx}`] === String(resource.categoryCompanyLabel[`${localeCode}`])}
                onChange={(event) => _onChangeCategory(event, idx)}
              />
              {resource.categoryCompanyLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    );
  }

  const _buildNameGroup = (idx: number, noOfNames: number): JSX.Element[] => {
    const nameLabel = formLabels.name;
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{nameLabel.endsWith('*') ? nameIndex === 0 ? nameLabel.substring(0, nameLabel.length - 1) + ' ' + (nameIndex + 1) + '*' : nameLabel.replace('*', ' ' + (nameIndex + 1)) : nameLabel + ' ' + nameIndex + 1}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayName(names[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={(event) => _formatNameInput(event, idx, nameIndex, noOfNames)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
              autoComplete="none"
            />
          </div>
        </div>
      );
    });
  }

  const _buildAddressField = (formScheme: IField[], idx: number): JSX.Element => {
    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.addressPrompt}</p>
        <div className='col-lg-6 row'>
          <div className='col-12 form-field row'>
            <p className='form-label'>{formLabels.addressBuilding}</p>
            <input
              type='text'
              inputMode='text'
              value={buildingNames[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              name={formScheme[2].field_name}
              onChange={(event: any) => _setBuildingName(event.target.value, idx)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
              autoComplete="none"
            />
          </div>

          <div className='col-12 form-field row'>
            <p className='form-label'>{formLabels.addressUnit}</p>
            <input
              type='text'
              inputMode='text'
              value={unitNumbers[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              name={formScheme[3].field_name}
              onChange={(event: any) => _setUnitNumber(event.target.value, idx)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
              autoComplete="none"
            />
          </div>
        </div>

        <div className='col-lg-10 row'>
          <div className='col-lg-4 form-field'>
            <p className='form-label'>{formLabels.addressPostalCode}</p>
            <input
              type='text'
              inputMode='text'
              value={postalCodes[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setPostalCode(event.target.value, idx)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              autoComplete="none"
            />
          </div>

          <div className='col-lg-5 offset-lg-1 form-field'>
            <p className='form-label'>{formLabels.addressCountry}</p>
            <p className='form-text'>{formLabels.addressCountryPrompt}</p>
          </div>
        </div>

        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.addressPhone}</p>
          <input
            type='number'
            inputMode='tel'
            value={phoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setPhoneNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildCategoryField(idx)}
                          {categories[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`]) && namesCount > 0 && _buildNameGroup(idx, namesCount)}
                          {categories[`${idx}`] === String(resource.categoryCompanyLabel[`${localeCode}`]) && companyCount > 0 && _buildNameGroup(idx, companyCount)}
                          {_buildAddressField(formScheme, idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default CategoryNamesAddressForm;