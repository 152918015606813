import React, { useState, useEffect } from 'react';

import Select from 'react-select';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import countries from '../resources/countries.json';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackBuilding: any;
  callbackUnitNumber: any;
  callbackPostalCode: any;
  callbackCountry: any;
  callbackPhoneNumber: any;
  callbackNamesEntered?: any;
  callbackAddressEntered: any;
  callbackPhoneNumbersEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  userInfo: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const NamesAddressContactnoForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackEvent, callbackEventID, callbackName, callbackBuilding, callbackUnitNumber, callbackPostalCode, callbackCountry, callbackPhoneNumber, callbackNamesEntered, callbackAddressEntered, callbackPhoneNumbersEntered, formItems, config, localeCode, actionContext, userInfo, isMobile, isEditMode, productEvents, allowMultipleRegistrations }) => {
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [ceremonyValue, setCeremonyValue] = useState(['']);
  const [ceremonyIDValue, setCeremonyIDValue] = useState(['']);
  const [serialNumber, setSerialNumber] = useState(['']);
  const [buildingName, setBuildingName] = useState(['']);
  const [unitNumber, setUnitNumber] = useState(['']);
  const [postalCode, setPostalCode] = useState(['']);
  const [countryName, setCountry] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);

  const formLabels = resource.namesAddressContactnoForm[`${localeCode}`];
  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const noOfNames = formScheme.find(form => form.field_type === 'textBox')!.field_number!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  const { handleSubmit, reset } = useForm();

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];

    if (!inputMultipleName.includes(';')) {
      inputMultipleName += ';'.repeat(noOfNames - 1);
    }

    const inputMutlipleNameSplit = inputMultipleName.split(';');

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(';');

    _setCeremonyNameArray(inputName, index);
  };

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray);
  };

  const _setBuildingName = (value: string, index: number) => {
    const valueArray = buildingName.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setBuildingName(valueArray);

    callbackBuilding(valueArray);

    _checkAddressEntered(buildingName, postalCode);
  }

  const _setUnitNumber = (value: string, index: number) => {
    const valueArray = unitNumber.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setUnitNumber(valueArray);

    callbackUnitNumber(valueArray);
  }

  const _setPostalCode = (value: string, index: number) => {
    const valueArray = postalCode.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    if(value.length<=6){
      valueArray[`${index}`] = value;
    }else{
      toast.warning(resource.invalidPostalCodeMessage[`${localeCode}`]);
    }

    valueArray[`${index}`].trim();

    setPostalCode(valueArray);

    callbackPostalCode(valueArray);

    _checkAddressEntered(buildingName, postalCode);
  }

  const _setCountry = (event: any, index: number) => {
    const valueArray = countryName.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = event.label;

    setCountry(valueArray);

    callbackCountry(valueArray);
  }

  const _setPhoneNumber = (value: string, index: number) => {
    const valueArray = phoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setPhoneNumbers(valueArray);

    callbackPhoneNumber(valueArray);

    _checkPhoneNumberEntered(valueArray);
  }

  const _checkNamesEntered = (ceremonyNameArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkAddressEntered = (buildingArray: string[], postalCodeArray: string[]) => {
    let result = true;

    for (let i = 0; i < buildingArray.length; ++i) {
      result = result && buildingArray[`${i}`] !== '' && postalCodeArray[`${i}`] !== '';
    }

    callbackAddressEntered(result);
  }

  const _checkPhoneNumberEntered = (phoneNumbersArray: string[]) => {
    let phoneNumbersEntered = phoneNumbersArray.length > 0? true: false;

    for (let i = 0; i < phoneNumbersArray.length; i++) {
      if (phoneNumbersArray[`${i}`].trim() === '') {
        phoneNumbersEntered = false;
        break;
      }
    }

    callbackPhoneNumbersEntered(phoneNumbersEntered);
  }

  const _initBuildingField = () => {
    if (userValid) {
      return userInfo.gmb_blockno.trim() + (userInfo.gmb_buildingno !== '' ? ', ' + userInfo.gmb_buildingno.trim() : '') + (userInfo.gmb_buildingname !== '' ? ', ' + userInfo.gmb_buildingname.trim() : '') + (userInfo.address1_line1 !== '' ? ', ' + userInfo.address1_line1.trim() : '') + (userInfo.address1_city !== '' ? ', ' + userInfo.address1_city.trim() : '');
    }

    return '';
  }

  const _initCountryField = () => {
    if (userValid) {
      const states = countries[`${localeCode}`].countries;

      const location = states.find((place: { value: string, label: string }) => place.label.toUpperCase() === userInfo.address1_country.toUpperCase());

      return location ? location.label : '';
    }

    return '';
  }

  const _addItem = () => {
    addRow(1);

    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();

    const buildingNameArray = buildingName.slice();
    const unitNumberArray = unitNumber.slice();
    const postalCodeArray = postalCode.slice();
    const countryArray = countryName.slice();
    const phoneNumbersArray = phoneNumbers.slice();

    ceremonyValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
    ceremonyIDValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
    ceremonyNameArray.push('');
    phoneNumbersArray.push('');

    if (userValid) {
      buildingNameArray.push(_initBuildingField());
      unitNumberArray.push(userInfo.gmb_unitnofloorapartmentno);
      postalCodeArray.push(userInfo.address1_postalcode);
      countryArray.push(_initCountryField());
    } else {
      buildingNameArray.push('');
      unitNumberArray.push('');
      postalCodeArray.push('');
      countryArray.push(countries[`${localeCode}`].countries[0].label);
    }

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);

    setBuildingName(buildingNameArray);
    setUnitNumber(unitNumberArray);
    setPostalCode(postalCodeArray);
    setCountry(countryArray);
    setPhoneNumbers(phoneNumbersArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);

    callbackBuilding(buildingNameArray);
    callbackUnitNumber(unitNumberArray);
    callbackPostalCode(postalCodeArray);
    callbackCountry(countryArray);
    callbackPhoneNumber(phoneNumbersArray);

    callbackNamesEntered(false);

    _checkAddressEntered(buildingName, postalCode);

    _checkPhoneNumberEntered(phoneNumbersArray);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const serialNumberArray = serialNumber.slice();

    const buildingNameArray = buildingName.slice();
    const unitNumberArray = unitNumber.slice();
    const postalCodeArray = postalCode.slice();
    const countryArray = countryName.slice();
    const phoneNumbersArray = phoneNumbers.slice();

    if (typeof ceremonyValueArray[`${index}`] !== 'undefined') {
      if (ceremonyValueArray.length > 1) {
        ceremonyValueArray.splice(index, 1);
      } else {
        ceremonyValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      }
    }

    if (typeof ceremonyIDValueArray[`${index}`] !== 'undefined') {
      if (ceremonyIDValueArray.length > 1) {
        ceremonyIDValueArray.splice(index, 1);
      } else {
        ceremonyIDValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    if (typeof serialNumberArray[`${index}`] !== 'undefined') {
      if (serialNumberArray.length > 1) {
        serialNumberArray.splice(index, 1);
      } else {
        serialNumberArray[0] = '';
      }
    }

    if (typeof buildingNameArray[`${index}`] !== 'undefined') {
      if (buildingNameArray.length > 1) {
        buildingNameArray.splice(index, 1);
      } else {
        buildingNameArray[0] = '';
      }

      setBuildingName(buildingNameArray);

      callbackAddressEntered(buildingNameArray);
    }

    if (typeof unitNumberArray[`${index}`] !== 'undefined') {
      if (unitNumberArray.length > 1) {
        unitNumberArray.splice(index, 1);
      } else {
        unitNumberArray[0] = '';
      }

      setUnitNumber(unitNumberArray);

      callbackUnitNumber(unitNumberArray);
    }

    if (typeof postalCodeArray[`${index}`] !== 'undefined') {
      if (postalCodeArray.length > 1) {
        postalCodeArray.splice(index, 1);
      } else {
        postalCodeArray[0] = '';
      }

      setPostalCode(postalCodeArray);

      callbackPostalCode(postalCodeArray);
    }

    if (typeof countryArray[`${index}`] !== 'undefined') {
      if (countryArray.length > 1) {
        countryArray.splice(index, 1);
      } else {
        countryArray[0] = countries[`${localeCode}`].countries[0].label;
      }

      setCountry(countryArray);

      callbackCountry(countryArray);
    }

    if (typeof phoneNumbersArray[`${index}`] !== 'undefined') {
      if (phoneNumbersArray.length > 1) {
          phoneNumbersArray.splice(index, 1);
      } else {
          phoneNumbersArray[0] = '';
      }

      setPhoneNumbers(phoneNumbersArray);

      callbackPhoneNumber(phoneNumbersArray);
    }

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);
    setSerialNumber(serialNumberArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray);

    _checkAddressEntered(buildingName, postalCode);

    _checkPhoneNumberEntered(phoneNumbersArray);
  }

  useEffect(() => {
    const existingEventName: string[] = [];
    const existingEventID: string[] = [];
    const existingCeremonyName: string[] = [];

    const existingBuildingName: string[] = [];
    const existingUnitNumber: string[] = [];
    const existingPostalCode: string[] = [];
    const existingCountry: string[] = [];
    const phoneNumbersArray: string[] = [];

    // formItems could possibly be populated by past transactions/drafts/coming from cart.
    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const itemCommentParts = formItems[`${i}`]['names'];
        
        let count = 0;
        let ceremonyName = '';

        while (typeof itemCommentParts[`${count}`] !== 'undefined') {
          ceremonyName += itemCommentParts[`${count}`];

          if (typeof itemCommentParts[count + 1] !== 'undefined') {
            ceremonyName += ';';
          }

          count++;
        }

        const eventName = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
        const eventID = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';

        existingEventName.push(eventName);
        existingEventID.push(eventID);
        existingCeremonyName.push(ceremonyName);

        existingBuildingName.push(formItems[`${i}`]['buildingName']);
        existingUnitNumber.push(formItems[`${i}`]['unitNumber']);
        existingPostalCode.push(formItems[`${i}`]['postalCode']);
        existingCountry.push(formItems[`${i}`]['country']);
        phoneNumbersArray.push(formItems[`${i}`]['phoneNumber']);
      }

      setCeremonyName(existingCeremonyName);
      setBuildingName(existingBuildingName);
      setUnitNumber(existingUnitNumber);
      setPostalCode(existingPostalCode);
      setCountry(existingCountry);
      setPhoneNumbers(phoneNumbersArray);

      callbackName(existingCeremonyName);
      callbackBuilding(existingBuildingName);
      callbackUnitNumber(existingUnitNumber);
      callbackPostalCode(existingPostalCode);
      callbackCountry(existingCountry);
      callbackPhoneNumber(phoneNumbersArray);

      _checkNamesEntered(existingCeremonyName);
      _checkAddressEntered(existingBuildingName, existingPostalCode);
      _checkPhoneNumberEntered(phoneNumbersArray);
    } else {
      const eventName = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      const eventID = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';

      existingEventName.push(eventName);
      existingEventID.push(eventID);
      phoneNumbersArray.push('');

      if (userValid) {
        existingBuildingName.push(_initBuildingField());
        existingUnitNumber.push(userInfo.gmb_unitnofloorapartmentno);
        existingPostalCode.push(userInfo.address1_postalcode);
        existingCountry.push(_initCountryField());

        setBuildingName(existingBuildingName);
        setUnitNumber(existingUnitNumber);
        setPostalCode(existingPostalCode);
        setCountry(existingCountry);
        setPhoneNumbers(phoneNumbersArray);

        callbackBuilding(existingBuildingName);
        callbackUnitNumber(existingUnitNumber);
        callbackPostalCode(existingPostalCode);
        callbackCountry(existingCountry);
        callbackPhoneNumber(phoneNumbersArray);

        _checkAddressEntered(existingBuildingName, existingPostalCode);
        _checkPhoneNumberEntered(phoneNumbersArray);
      }
    }

    setCeremonyValue(existingEventName);
    setCeremonyIDValue(existingEventID);

    callbackEvent(existingEventName);
    callbackEventID(existingEventID);
  }, [formItems]);

  const _buildNameGroup = (idx: number, noOfNames: number): JSX.Element[] => {
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{formLabels.name.endsWith('*') ? nameIndex === 0 ? formLabels.name.substring(0, formLabels.name.length - 1) + ' ' + (nameIndex + 1) + '*' : formLabels.name.replace('*', ' ' + (nameIndex + 1)) : formLabels.name + ' ' + nameIndex + 1}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayName(ceremonyName[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            />
          </div>
        </div>
      );
    });
  }

  const _buildAddressField = (idx: number): JSX.Element => {
    const states = countries[`${localeCode}`].countries;

    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.addressPrompt}</p>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.addressBuilding}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={(event: any) => _setBuildingName(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={buildingName[`${idx}`]}
            type='text'
            autoComplete="none"
          />

          <p className='form-label'>{formLabels.addressUnit}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={(event: any) => _setUnitNumber(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={unitNumber[`${idx}`]}
            type='text'
            autoComplete="none"
          />
        </div>

        <div className='col-lg-10 row'>
          <div className='col-lg-4 form-field'>
            <p className='form-label'>{formLabels.addressPostalCode}</p>
            <input
              type='text'
              inputMode='text'
              value={postalCode[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setPostalCode(event.target.value, idx)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              autoComplete="none"
            />
          </div>

          <div className='col-lg-5 offset-lg-1 form-field'>
            <p className='form-label'>{formLabels.addressCountry}</p>
            <Select
              placeholder={countryName[`${idx}`]}
              options={states}
              onChange={(event: any) => _setCountry(event, idx)}
              className={'select'}
              isSearchable={true}
            />
          </div>
        </div>

        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.addressPhone}</p>
          <input
            type='number'
            inputMode='tel'
            value={phoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setPhoneNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {noOfNames > 0 && _buildNameGroup(idx, noOfNames)}
                          {_buildAddressField(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default NamesAddressContactnoForm;