import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IEventDetails, IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

import Select from 'react-select'

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackPrice?: any;
  callbackName?: any;
  callbackEmailId?:any;
  callbackContactNumber?:any;
  callbackEventID?:any;
  callbackEvent?: any;
  callbackNamesEntered?: any;
  callbackChineseNamesEntered?: any;
  callbackPricesEntered?: any;
  callbackMultiplier: any;
  callbackEventsSelected?: any;
  callbackPhoneNumber: any;
  callbackChineseName: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  userInfo: any;
  productEvents: any[];
  callbackPhoneNumbersEntered: any;
  allowMultipleRegistrations: boolean;
  makeChinesNameMandatory: boolean;
}

const EventChineseNameForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackPrice, callbackName, callbackNamesEntered, callbackPricesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, userInfo,productEvents,callbackEventID,callbackEvent , callbackMultiplier, callbackEventsSelected,  callbackPhoneNumber, callbackChineseName, callbackPhoneNumbersEntered,allowMultipleRegistrations,callbackChineseNamesEntered, makeChinesNameMandatory}) => {

  const [customPrice, setCustomPrice] = useState([0]);
  const [inputValue, setInputValue] = useState(['']);
  const [attendeeName, setAttendeeName] = useState(['']);
  const [coursesEventId, setCoursesEventId] = useState(['']);
  const [selectValue, setSelectValue] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [chineseName, setChineseName] = useState(['']);
  
  const [multipliers, setMultiplers] = useState([1]);
  const[showAddMoreButton, setShowAddMoreButton] = useState(false);
  const[remainingSlots, setRemainingSlots] = useState(0);

 


  const formLabels = resource.coursesForm[`${localeCode}`];

  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();


  const _setNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setAttendeeNameArray(event.target.value.substring(0, maxLength - 1), index);

     
  };

  const _setChineseNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setChineseNameArray(event.target.value.substring(0, maxLength - 1), index);
  
     
  };


  //   const coursesEventIDArray = coursesEventId.slice();

  //   while (typeof coursesEventIDArray[`${index}`] === 'undefined') {
  //     coursesEventIDArray.push('');
  //   }

  //   coursesEventIDArray[`${index}`] = eventId;

  //   coursesEventIDArray[`${index}`].trim();

  //   setCoursesEventId(coursesEventIDArray);

   

  //   callbackEventID(coursesEventIDArray);
  // };

  const _setAttendeeNameArray = (name: string, index: number) => {
    const attendeeNameArray = attendeeName.slice();

    while (typeof attendeeNameArray[`${index}`] === 'undefined') {
      attendeeNameArray.push('');
    }

    attendeeNameArray[`${index}`] = name;

    attendeeNameArray[`${index}`].trim();

    setAttendeeName(attendeeNameArray);

    callbackName(attendeeNameArray);

    _checkNamesEntered(attendeeNameArray);
  };

  const _setPhoneNumber = (value: string, index: number) => {
    const valueArray = phoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setPhoneNumbers(valueArray);

    callbackPhoneNumber(valueArray);

    _checkPhoneNumberEntered(valueArray);
  }

  const _setChineseNameArray = (name: string, index: number) => {
    const chineseNameArray = chineseName.slice();

    while (typeof chineseNameArray[`${index}`] === 'undefined') {
        chineseNameArray.push('');
    }

    chineseNameArray[`${index}`] = name;

    chineseNameArray[`${index}`].trim();

    setChineseName(chineseNameArray);

    callbackChineseName(chineseNameArray);

    _checkChineseNamesEntered(chineseNameArray);

  };

 
  //const isNumeric = (num: any): boolean => { return !isNaN(num); };

  const _checkNamesEntered = (attendeeNameArray: string[]) => {
    let namesEntered = attendeeNameArray.length > 0? true: false;

    for (let i = 0; i < attendeeNameArray.length; i++) {
      if (attendeeNameArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkChineseNamesEntered = (chineseNameArray: string[]) => {
    let chineseNamesEntered = chineseNameArray.length > 0? true: false;

    for (let i = 0; i < chineseNameArray.length; i++) {
      if (chineseNameArray[`${i}`].trim() === '') {
        chineseNamesEntered = false;
        break;
      }
    }

    callbackChineseNamesEntered(chineseNamesEntered);
  }

  const _checkPhoneNumberEntered = (phoneNumbersArray: string[]) => {
    let phoneNumbersEntered = phoneNumbersArray.length > 0? true: false;

    for (let i = 0; i < phoneNumbersArray.length; i++) {
      if (phoneNumbersArray[`${i}`].trim() === '') {
        phoneNumbersEntered = false;
        break;
      }
    }

    callbackPhoneNumbersEntered(phoneNumbersEntered);
  }

  const _checkPricesEntered = (customPriceArray: number[]) => {
    let pricesEntered = true;

    for (let i = 0; i < customPriceArray.length; i++) {
      if (customPriceArray[`${i}`] == 0) {
        pricesEntered = false;
        break;
      }
    }

    callbackPricesEntered(pricesEntered);
  }



  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _addItem = () => {
    addRow(1);

    const attendeeNameArray = attendeeName.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const chineseNamesArray = chineseName.slice();

   
   setShowAddMoreButton(false);
   setRemainingSlots(0);
   setAttendeeName(attendeeNameArray);
   setSelectValue(coursesEventArray);
   setCoursesEventId(coursesEventIDArray);
   setPhoneNumbers(phoneNumbersArray);
   setChineseName(chineseNamesArray);

   callbackEvent(coursesEventArray);
   callbackEventID(coursesEventIDArray);
   callbackName(attendeeNameArray);
   callbackPhoneNumber(phoneNumbersArray);
   callbackChineseName(chineseNamesArray);

   callbackNamesEntered(false);
   callbackEventsSelected(false);
   callbackChineseNamesEntered(false);

  }

  const _updateStateData = (index: number) => {
    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const inputValueArray = inputValue.slice();
    const attendeeNameArray = attendeeName.slice();
    const multiplierArray = multipliers.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const chineseNamesArray = chineseName.slice();
 
    
    if (typeof customPriceArray[`${index}`] !== 'undefined') {
      if (customPriceArray.length > 1) {
        customPriceArray.splice(index, 1);
      } else {
        customPriceArray[0] = parseInt(element!.field_options![0]);
      }
    }

    if (typeof inputValueArray[`${index}`] !== 'undefined') {
      if (inputValueArray.length > 1) {
        inputValueArray.splice(index, 1);
      } else {
        inputValueArray[0] = '';
      }
    }

    if (typeof attendeeNameArray[`${index}`] !== 'undefined') {
      if (attendeeNameArray.length > 1) {
        attendeeNameArray.splice(index, 1);
      } else {
        attendeeNameArray[0] = '';
      }
    }
    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = 1;
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof coursesEventArray[`${index}`] !== 'undefined') {
      if (coursesEventArray.length > 1) {
        coursesEventArray.splice(index, 1);
      } else {
        coursesEventArray[0] = '';
      }
    }

    if (typeof coursesEventIDArray[`${index}`] !== 'undefined') {
      if (coursesEventIDArray.length > 1) {
        coursesEventIDArray.splice(index, 1);
      } else {
        coursesEventIDArray[0] = '';
      }
    }

    if (typeof phoneNumbersArray[`${index}`] !== 'undefined') {
        if (phoneNumbersArray.length > 1) {
            phoneNumbersArray.splice(index, 1);
        } else {
            phoneNumbersArray[0] = '';
        }
      }

    if (typeof chineseNamesArray[`${index}`] !== 'undefined') {
        if (chineseNamesArray.length > 1) {
            chineseNamesArray.splice(index, 1);
        } else {
            chineseNamesArray[0] = '';
        }
      }  
     
   

    

    setCustomPrice(customPriceArray);
    setInputValue(inputValueArray);
    setAttendeeName(attendeeNameArray);
    setSelectValue(coursesEventArray);
    setCoursesEventId(coursesEventIDArray);
    setPhoneNumbers(phoneNumbersArray);
    setChineseName(chineseNamesArray);


    callbackEvent(coursesEventArray);
    callbackEventID(coursesEventIDArray);
    callbackName(attendeeNameArray);
    callbackPrice(customPriceArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackChineseName(chineseNamesArray);

    _checkEventsEntered(coursesEventArray);
    _checkNamesEntered(attendeeNameArray);
    _checkPricesEntered(customPriceArray);
    _checkPhoneNumberEntered(phoneNumbersArray);
   _checkChineseNamesEntered(chineseNamesArray);

  }

  useEffect(() => {
    const element = formScheme.find(element => element.field_name === 'amount');

    var minDonationvalue = Number(element?.field_options![0]);
    var minDonationString = String(element?.field_options![0]);

    const existingCustomPriceAmount: number[] = [];
    const existingInputValue: string[] = [];
    const existingAttendeeName: string[] = [];
    const existingDonationAmount: string[] = [];
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const existingPhoneNumbersArray: string[] = [];
    const existingChineseNamesArray : string[]=[];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        var customPriceAmount: number = formItems[`${i}`]['customPriceAmount'];

        const eventName: string = formItems[`${i}`]['eventName'];

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        existingPhoneNumbersArray.push(formItems[`${i}`]['phoneNumber']);
        existingChineseNamesArray.push(formItems[`${i}`]['chineseName']);

         
        existingAttendeeName.push(formItems[`${i}`]['names'][0]);
        
        existingCustomPriceAmount.push(customPriceAmount);
      }
      

      setAttendeeName(existingAttendeeName);
      setPhoneNumbers(existingPhoneNumbersArray);
      setChineseName(existingChineseNamesArray);
    

      callbackName(existingAttendeeName);
     

      _checkNamesEntered(existingAttendeeName);
      _checkChineseNamesEntered(existingChineseNamesArray);
      _checkPhoneNumberEntered(existingPhoneNumbersArray);
    } else {
      existingDonationAmount.push(minDonationString);
      existingCustomPriceAmount.push(minDonationvalue);
      existingInputValue.push('');
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
    }

    //setDonationAmount(existingDonationAmount);
    if(userValid && existingAttendeeName.length <=0)
    {
      
        existingAttendeeName.push(userInfo.firstname+' '+userInfo.lastname);
        existingChineseNamesArray.push(userInfo.gmb_fullnamechinese);
        existingPhoneNumbersArray.push(userInfo.mobilephone)
    }

    setAttendeeName(existingAttendeeName);
    setCustomPrice(existingCustomPriceAmount);
    setInputValue(existingInputValue);
    setMultiplers(multipliersArray);
    setSelectValue(eventNamesArray);
    setCoursesEventId(eventIDsArray);
    setPhoneNumbers(existingPhoneNumbersArray);
    setChineseName(existingChineseNamesArray);

    callbackMultiplier(multipliersArray);
    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);

    _checkEventsEntered(eventNamesArray);

    callbackPrice(existingCustomPriceAmount);
    callbackName(existingAttendeeName);
    callbackPhoneNumber(existingPhoneNumbersArray);
    callbackChineseName(existingChineseNamesArray);

    _checkPricesEntered(existingCustomPriceAmount);
    _checkNamesEntered(existingAttendeeName);
    _checkChineseNamesEntered(existingChineseNamesArray);
    _checkPhoneNumberEntered(existingPhoneNumbersArray);
    
  }, [formItems])

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (selectValue.length > 0) {
      const selectValueArray = selectValue.slice();
      const eventIDArray = coursesEventId.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _checkEventsEntered = (cousesEventArray: string[]) => {
    let eventsSelected = cousesEventArray.length > 0 ? true : false;

    for (let i = 0; i < cousesEventArray.length; i++) {
      if (cousesEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false;
        break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    
      inputEventDates += event.label;
      inputEventIDs += event.value;
    

    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);

    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(event.length, index);

    _checkEventsEntered(selectValueArray);
  }


  const _buildEventDropdown = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.coursesEvents}</p>
          <Select
            value={_displayEventDates(idx)}
            closeMenuOnSelect={true}
            options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
            onChange={(event: any) => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  }

  const _buildName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.attendeeName}</p>
          <input
            type='text'
            value={attendeeName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildChineseName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{makeChinesNameMandatory?formLabels.chineseName:formLabels.chineseNameNotMandatory}</p>
          <input
            type='text'
            value={chineseName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setChineseNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildContactNumber = ( idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.phoneNumber}</p>
          <input
            type='number'
            inputMode='tel'
            value={phoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setPhoneNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }



  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return <div className='form-record'>
              <div key={`row${i.id}`} className='form-details row'>
                {formScheme.map((element: IField, index: number) => {

                  if (element.field_name === 'attendeeName') {
                    return (
                      <div className='form-details-item col-lg-9'>
                        {remainingSlots !== 0 && <p className='form-label col-12'>{formLabels.availableSlots}{remainingSlots}</p>}
                        {_buildEventDropdown(idx)}
                        {_buildName(idx)}
                        {_buildChineseName(idx)}
                        {_buildContactNumber(idx)}

                        {/* {_buildAmountField(element, idx)} */}
                      </div>
                    );
                  }

                  if (!isMobile && element.field_type === 'product_price') {
                    return (
                      <div className='form-details-item bordered centred col-2'>
                        <p key={index} className='subtotal'>
                          ${selectedProduct.result?.Price! === 0 ? typeof customPrice[`${idx}`] !== 'undefined' ? customPrice[`${idx}`] : '0' : selectedProduct.result?.Price!}
                        </p>
                      </div>
                    );
                  }

                  if (element.field_type === 'action') {
                    if (isMobile) {
                      const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                      return (
                        <div className={style}>
                           {!isEditMode && allowMultipleRegistrations && showAddMoreButton && <div className='col-10'>
                            <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                          </div>}
                          <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                        </div>
                      );
                    }

                    return (
                      <div className='form-details-item centred col-1'>
                        <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                      </div>
                    );
                  }

                  return;
                })}
              </div>
            </div>;
          })}
        </div>

         {!isMobile && !isEditMode && allowMultipleRegistrations && showAddMoreButton &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>} 
      </form>
    </div>
  );
};

export default EventChineseNameForm;