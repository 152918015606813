import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackName?: any;
  callbackMessage?: any;
  callbackRemarks?: any;
  callbackNamesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
  showRemarksField: boolean;
}

const OfferingForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackName, callbackMessage, callbackRemarks, callbackNamesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, allowMultipleRegistrations,showRemarksField }) => {
  const [offeringName, setOfferingName] = useState(['']);
  const [offeringMessage, setOfferingMessage] = useState(['']);
  const [offeringRemark, setOfferingRemark] = useState(['']);

  const formLabels = resource.offeringForm[`${localeCode}`];

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();

  const _setNameInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setOfferingNameArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setOfferingNameArray = (name: string, index: number) => {
    const offeringNameArray = offeringName.slice();

    while (typeof offeringNameArray[`${index}`] === 'undefined') {
      offeringNameArray.push('');
    }

    offeringNameArray[`${index}`] = name;

    offeringNameArray[`${index}`].trim();

    setOfferingName(offeringNameArray);

    callbackName(offeringNameArray);

    _checkNamesEntered(offeringNameArray);
  }

  const _setMessageInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForMessages ? config.charactersLimitForMessages : 20;

    _setOfferingMessageArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setOfferingMessageArray = (name: string, index: number) => {
    const offeringMessageArray = offeringMessage.slice();

    while (typeof offeringMessageArray[`${index}`] === 'undefined') {
      offeringMessageArray.push('');
    }

    offeringMessageArray[`${index}`] = name;

    offeringMessageArray[`${index}`].trim();

    setOfferingMessage(offeringMessageArray);

    callbackMessage(offeringMessageArray);
  }

  const _setRemarksInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 100;

    _setOfferingRemarkArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setOfferingRemarkArray = (name: string, index: number) => {
    const offeringRemarkArray = offeringRemark.slice();

    while (typeof offeringRemarkArray[`${index}`] === 'undefined') {
      offeringRemarkArray.push('');
    }

    offeringRemarkArray[`${index}`] = name;

    offeringRemarkArray[`${index}`].trim();

    setOfferingRemark(offeringRemarkArray);

    callbackRemarks(offeringRemarkArray);
  }

  const _checkNamesEntered = (offeringNameArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < offeringNameArray.length; i++) {
      if (offeringNameArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _addItem = () => {
    addRow(1);

    const offeringNameArray = offeringName.slice();
    const offeringMessageArray = offeringMessage.slice();
    const offeringRemarkArray = offeringRemark.slice();

    offeringNameArray.push('');
    offeringMessageArray.push('');
    offeringRemarkArray.push('');

    setOfferingName(offeringNameArray);
    setOfferingMessage(offeringMessageArray);
    setOfferingRemark(offeringRemarkArray);

    callbackName(offeringNameArray);
    callbackMessage(offeringMessageArray);
    callbackRemarks(offeringRemarkArray);

    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const offeringNameArray = offeringName.slice();
    const offeringMessageArray = offeringMessage.slice();
    const offeringRemarkArray = offeringRemark.slice();

    if (typeof offeringNameArray[`${index}`] !== 'undefined') {
      if (offeringNameArray.length > 1) {
        offeringNameArray.splice(index, 1);
      } else {
        offeringNameArray[0] = '';
      }
    }

    if (typeof offeringMessageArray[`${index}`] !== 'undefined') {
      if (offeringMessageArray.length > 1) {
        offeringMessageArray.splice(index, 1);
      } else {
        offeringMessageArray[0] = '';
      }
    }

    if (typeof offeringRemarkArray[`${index}`] !== 'undefined') {
      if (offeringRemarkArray.length > 1) {
        offeringRemarkArray.splice(index, 1);
      } else {
        offeringRemarkArray[0] = '';
      }
    }

    setOfferingName(offeringNameArray);
    setOfferingMessage(offeringMessageArray);
    setOfferingRemark(offeringRemarkArray);

    callbackName(offeringNameArray);
    callbackMessage(offeringMessageArray);
    callbackRemarks(offeringRemarkArray);

    _checkNamesEntered(offeringNameArray);
  }

  useEffect(() => {
    const existingOfferingName: string[] = [];
    const existingOfferingMessage: string[] = [];
    const existingOfferingRemark: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        let itemNames: string[] = formItems[`${i}`]['names'];

        // Offering form has only one name, that's why the first element is pushed.
        if (itemNames) {
          existingOfferingName.push(itemNames[0]);
        }

        existingOfferingMessage.push(formItems[`${i}`]['message']);
        existingOfferingRemark.push(formItems[`${i}`]['remarks']);
      }

      setOfferingName(existingOfferingName);
      setOfferingMessage(existingOfferingMessage);
      setOfferingRemark(existingOfferingRemark);

      callbackName(existingOfferingName);
      callbackMessage(existingOfferingMessage);
      callbackRemarks(existingOfferingRemark);

      _checkNamesEntered(existingOfferingName);
    }
  }, [formItems])

  const _buildNameField = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.name}</p>
          <input
            type='text'
            inputMode='text'
            value={offeringName[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildMessage = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.message}</p>
          <input
            type='text'
            inputMode='text'
            value={offeringMessage[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setMessageInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForMessages ? config.charactersLimitForMessages : 31}
          />
        </div>
      </div>
    );
  }

  const _buildRemarks = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.remarks}</p>
          <textarea
            inputMode='text'
            value={offeringRemark[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setRemarksInputValue(event, idx)}
            className='form-control'
            maxLength={config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 101}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildNameField(idx)}
                          {_buildMessage(idx)}
                          {showRemarksField && _buildRemarks(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  )
};

export default OfferingForm;