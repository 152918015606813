import { ICoreContext, IActionContext } from '@msdyn365-commerce/core';

import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';

import { AsyncResult, Customer, Product, ProductSearchCriteria, ProductSearchResult, SimpleProduct, SalesOrder } from '@msdyn365-commerce/retail-proxy';
import { getOrderHistoryAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';
import { searchAsync, searchByCategoryAsync, getByIdsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { v4 as uuidv4, validate as uuidValidate, version as uuidVersion } from 'uuid';

import { createOrUpdateBTGPCartLineAsync, createBTGPErrorLogAsync, getByCustomerAllBTGPTransactionAsync, getByCustomerAllBTGPTransactionFromHqAsync } from '../actions/DataActionExtension.g';
import { CategoryProducts, FormDetails, PastTransaction, CartItem, Transaction } from '../actions/ecomm-interfaces';

import DedicationPeriod from '../resources/dedicationPeriod.json';
import { ICartState} from '@msdyn365-commerce/global-state';
import { ReceiptItem } from './kioskv3-interfaces';

export async function getProductsByCategoryId(context: ICoreContext, categoryId: number): Promise<void | SimpleProduct[]> {
  const searchResult = await searchByCategoryAsync({ callerContext: context.actionContext }, context.actionContext.requestContext.apiSettings.channelId, context.actionContext.requestContext.apiSettings.catalogId, categoryId).catch(error => console.log('[getProductsByCategoryId] searchByCategoryAsync error: ', error));

  if (searchResult && searchResult.length > 0) {
    searchResult.sort((a: ProductSearchResult, b: ProductSearchResult) => ((a.DisplayOrder!) < (b.DisplayOrder!) ? -1 : 1));

    const productIds: number[] = [];

    searchResult.map(item => productIds.push(item.RecordId));

    // const availability = await getEstimatedAvailabilityAsync(context, { ProductIds: productIds, DefaultWarehouseOnly: true }).
    //   catch(error => console.log('[getProductsByCategoryId] getEstimatedAvailabilityAsync error: ', error));

    // if (availability && availability.ProductWarehouseInventoryAvailabilities) {
    //   const warehouse = availability.ProductWarehouseInventoryAvailabilities;

    //   if (warehouse.length > 0) {
    //     const availableIds: number[] = [];

    //     searchResult.map(item => {
    //       const found = warehouse.find(stock => stock.ProductId === item.RecordId);

    //       if (found && (found.PhysicalAvailableInventoryLevelCode && found.PhysicalAvailableInventoryLevelCode === InventoryLvls.AVAIL)) {
    //         availableIds.push(item.RecordId);
    //       }
    //     });

    //     const products = await getByIdsAsync(context, channelId, availableIds).catch(error => console.log('[getProductsByCategoryId] getByIdsAsync error: ', error));

    //     return products;
    //   }
    // }

    const products = await getByIdsAsync({ callerContext: context.actionContext }, context.actionContext.requestContext.apiSettings.channelId, productIds).catch(error => console.log('[getProductsByCategoryId] getByIdsAsync error: ', error));

    return products;
  }

  return [];
}

export async function getProducts(context: ICoreContext, categoryIDs: number[]): Promise<Product[]> {
  if (categoryIDs.length > 0) {
    const projectDomain = { ChannelId: context.actionContext.requestContext.apiSettings.channelId, CatalogId: context.actionContext.requestContext.apiSettings.catalogId };

    const criterias: ProductSearchCriteria = { Context: projectDomain, CategoryIds: categoryIDs, IncludeAttributes: true };
  
    const products = await searchAsync({ callerContext: context.actionContext }, criterias);
  
    return products;
  }

  return [];
}

export async function getCustomerOrderHistory(context: ICoreContext, hierarchy: AsyncResult<CategoryHierarchy[]>, customer: Customer, organisation: string, isUat: boolean, limit: number, source: string) {
  const dictionary = await generateDictionary(context, hierarchy);
  const orderHistory: PastTransaction[] = [];
  let btgpTransactions;
  let orders : SalesOrder[]= [];  


  if (source =="CH")
  {
    orders = await getOrderHistoryAsync({ callerContext: context.actionContext }, customer.AccountNumber);

    btgpTransactions = await getByCustomerAllBTGPTransactionAsync({ callerContext: context.actionContext }, customer.AccountNumber, customer.Email!, organisation, isUat, limit);    
  }
  else{
    btgpTransactions = await getByCustomerAllBTGPTransactionFromHqAsync({ callerContext: context.actionContext }, isUat, customer.AccountNumber, customer.Email!, (organisation=="BT"?"BTRT":"GPBT")); 
  }


  if (btgpTransactions)
  {
    for (let i = 0; i < btgpTransactions.length; i++) {
      const transaction: Transaction = JSON.parse(btgpTransactions[`${i}`]);
      
      if (transaction['errorCode']) {
        console.log(transaction['errorMessage']);
      } else {
        const transactionNames: string[] = [];

        for (let j = 1; j <= 20; j++) {
          const nameID = 'Name_' + j;

          if (transaction[`${nameID}`]) {
            transactionNames.push(transaction[`${nameID}`]);
          }
        }

        let mainName = '';
        let mainID = 0;
        let price = 0;

        if (transaction.ProductCategoryID === '' || transaction.ProductCategoryID === null) {
          dictionary.forEach(record => {
            const found = record.Products.find(item => item.RecordId === parseInt(transaction.ProductID));

            if (found) { 
              mainName = record.Main.Name!;               
              mainID = record.Main.RecordId;
              price = found.Price ? found.Price : 0;
            }
          });
        } else {
          mainName = transaction.ProductCategory;
          mainID = Number(transaction.ProductCategoryID);
          price = transaction.ProductPrice;
        }

        if (transaction.DedicationPeriod !== '') {
          let found = DedicationPeriod.en.period.findIndex(item => item.label === transaction.DedicationPeriod);

          // Look through chinese version.
          if (found < 0) {
            found = DedicationPeriod.zh.period.findIndex(item => item.label === transaction.DedicationPeriod);
          }

          // Found matching dedication period.
          if (found >= 0) {
            price = price * DedicationPeriod.scale[`${found}`];
          }
        }

        const matchingOrder = orders.find(order => order.Id === transaction.SalesOrderID);
        const orderReceiptNo = transaction.ReceiptNo === '' ?  matchingOrder && matchingOrder.SalesId ? matchingOrder.SalesId : 'Pending' : transaction.ReceiptNo;

        let order = transaction;
        order.ProductPrice = price;
        order.ProductCategory = mainName;
        order.ProductCategoryID = mainID.toString();

        orderHistory.push({ 
          EventDates: [transaction.EventName],
          Names: transactionNames,
          OrderDate: transaction.DateOfTransaction ? new Date(transaction.DateOfTransaction) : new Date(),
          ReceiptNo: [orderReceiptNo],
          Record: order
        });
      }
    }
  }

  orderHistory.sort((a, b) => {
    return b.OrderDate.getTime() - a.OrderDate.getTime();
  });  

  return orderHistory;
}

export async function generateErrorLog(context: ICoreContext, errorLogName: string, errorDetails: string, item: FormDetails) {
  await createBTGPErrorLogAsync({ callerContext: context.actionContext }, generateUUID(), errorLogName, errorDetails, new Date().toISOString(), item.CartLineID, item.CartID, item.ProductID, item.EventID, item.EventName, item.SerialID, item.SerialNumber, item.SerialName, item.Name_1, item.Name_2, item.Name_3, item.Name_4, item.Name_5, item.Name_6, item.Name_7, item.Name_8, item.Name_9, item.Name_10, item.Name_11, item.Name_12, item.Name_13, item.Name_14, item.Name_15, item.Name_16, item.Name_17, item.Name_18, item.Name_19, item.Name_20, item.Remarks, item.Message, item.Category, item.DedicatedBy, item.HasTaxDeduction, item.Nric, item.BuildingName, item.UnitNumber, item.PostalCode, item.BirthDate, item.PhoneNumber, item.SchoolName, item.DonationOption, item.BlessingOption, item.CartLineComment, item.BirthTime, item.LightOption, item.ValidFrom, item.ValidTo, item.ParentCartLineID, item.DedicationPeriod, item.Country, item.CompanyName, item.DeceasedRelationship, item.SurgeryDate, item.DeathDate, item.DeathTime, item.NameChange, item.OldName_1, item.OldName_2, item.BirthDate_2, item.BirthTime_2, item.StartDate, item.Gender, item.ChineseName, item.ContactNumber, item.NameNextKin, item.ContactNumberNextKin, item.PassportNumber, item.PassportExpiryDate, item.PassportIssuedDate);
}

export async function createOrUpdateCartline(context: ICoreContext, item: CartItem): Promise<boolean> {
  const result =  await createOrUpdateBTGPCartLineAsync({ callerContext: context.actionContext }, item.Form.CartLineID, item.Form.CartID, item.Form.ProductID, item.Form.EventID, item.Form.EventName, item.Form.SerialID, item.Form.SerialNumber, item.Form.SerialName, item.Form.Name_1, item.Form.Name_2, item.Form.Name_3, item.Form.Name_4, item.Form.Name_5, item.Form.Name_6, item.Form.Name_7, item.Form.Name_8, item.Form.Name_9, item.Form.Name_10, item.Form.Name_11, item.Form.Name_12, item.Form.Name_13, item.Form.Name_14, item.Form.Name_15, item.Form.Name_16, item.Form.Name_17, item.Form.Name_18, item.Form.Name_19, item.Form.Name_20, item.Form.Remarks, item.Form.Message, item.Form.Category, item.Form.DedicatedBy, item.Form.HasTaxDeduction, item.Form.Nric, item.Form.BuildingName, item.Form.UnitNumber, item.Form.PostalCode, item.Form.BirthDate, item.Form.PhoneNumber, item.Form.SchoolName, item.Form.DonationOption, item.Form.BlessingOption, item.Form.CartLineComment, item.Form.BirthTime, item.Form.LightOption, item.Form.ValidFrom, item.Form.ValidTo, item.Form.ParentCartLineID, item.Form.DedicationPeriod, item.Form.Country, item.Form.CompanyName, item.Form.DeceasedRelationship, item.Form.SurgeryDate, item.Form.DeathDate, item.Form.DeathTime, item.Form.NameChange, item.Form.OldName_1, item.Form.OldName_2, item.Form.BirthDate_2, item.Form.BirthTime_2, item.IsValid, item.InvalidType, item.InCart, item.IsSubProduct, item.Form.StartDate, item.Form.Gender, item.Form.ChineseName, item.Form.NameNextKin, item.Form.ContactNumber, item.Form.ContactNumberNextKin, item.Form.PassportNumber, item.Form.PassportExpiryDate, item.Form.PassportIssuedDate, item.Form.Consent, item.Form.IsInsuranceIncluded);

  return result;
}

export async function generateDictionary(context: ICoreContext, hierarchy: AsyncResult<CategoryHierarchy[]>): Promise<CategoryProducts[]> {
  const dictionary: CategoryProducts[] = [];

  if (hierarchy.result) {
    hierarchy.result.forEach(record => {
      if (record.Children && record.Children.length > 0) {
        dictionary.push({ Main: record, SubIDs: record.Children.map(item => item.RecordId), Products: [] });
      }
    });
  }

  for (let j = 0; j < dictionary.length; ++j) {
    dictionary[`${j}`].Products = await getProducts(context, dictionary[`${j}`].SubIDs);
  }

  return dictionary;
}

export function generateUUID(): string {
  let uuid = uuidv4();

  while (!(uuidValidate(uuid) && uuidVersion(uuid) == 4)) {
    uuid = uuidv4();
  }

  return uuid;
}

export function showNextOrPreviousPage(selectors: string, showClassName: string, hideClassName: string, showNextPage: boolean) {
  let showPageIndex;
  const elements = document.querySelectorAll(selectors);

  // to get show page index
  for (let i = 0 ; i < elements.length; i++){
    const htmlElement = elements[`${i}`] as HTMLElement;
    if (htmlElement.classList.contains(showClassName)) {
      showPageIndex = showNextPage ? i + 1 : i - 1;
    }
    htmlElement.classList.remove(showClassName);
    htmlElement.classList.add(hideClassName);
  }

  const elementToShow = elements[`${showPageIndex}`] as HTMLElement;
  elementToShow.classList.remove(hideClassName);
  elementToShow.classList.add(showClassName);
}

export function loadingProductListInHomePageOrPrecheckoutPage(className: string){
  const productListEl = document.getElementsByClassName(className)[0];
  const overlayEl = document.getElementsByClassName("loading-overlay-parent")[0] as HTMLElement;

  if (!productListEl.hasChildNodes()){
    overlayEl.style.display = "block";

    const intervalId = setInterval(()=>{
      if (document.getElementsByClassName(className)[0].hasChildNodes()) {
        overlayEl.style.display = "none";
        clearInterval(intervalId);
      }
    }, 200);
  }
  else{
    overlayEl.style.display = "none";
  }
}

export function showLoadingScreen(show: boolean){
  const overlayEl = document.getElementsByClassName("loading-overlay-parent")[0] as HTMLElement;
  // show ? overlayEl.style.display = "block" : overlayEl.style.display = "none";
  let overlayTimeout: any = "";

  if (show){
    overlayTimeout = setTimeout(()=>{
      overlayEl.style.display = "block";
    }, 1000);
  }
  else{
    overlayEl.style.display = "none";
  }


  return overlayTimeout;
}

export function showLoadingScreenInstant(show: boolean){
  const overlayEl = document.getElementsByClassName("loading-overlay-parent")[0] as HTMLElement;
  show ? overlayEl.style.display = "block" : overlayEl.style.display = "none";
}

export function showPrintReceiptPopUp(show: boolean){
  const overlayEl = document.getElementsByClassName("try-again-overlay-parent")[0] as HTMLElement;
  show ? overlayEl.style.display = "block" : overlayEl.style.display = "none";
}

export function clearOverlayTimeout(overlayTimeout: NodeJS.Timeout){
  clearTimeout(overlayTimeout);
}

export function showStartOverBtnInHeader2(show: boolean){
  let elements: Element[] = Array.from(document.getElementsByClassName("start-over"));
  elements.forEach(element =>{
    if (show){
      (element as HTMLElement).style.display = "block";
    }
    else{
      (element as HTMLElement).style.display = "none";
    }
  });
}


export async function buildKioskv3ReceiptItems(cart: ICartState, actionContext: IActionContext, channelId: number): Promise<ReceiptItem[]>{
  let productIds: number[] = [];
  cart.cart.CartLines?.map(cartLine => productIds.push(Number(cartLine.ProductId)));

  let simpleProductsResults = await searchAsync({ callerContext: actionContext }, { Ids: productIds });

  let receiptItems: ReceiptItem[] = [];

  cart.cart.CartLines?.map(cartLine => {
      if (cartLine.ProductId) {
          let receiptItem: ReceiptItem = {
              quantity: cartLine.Quantity!,
              productName: simpleProductsResults.find(product => product.RecordId == cartLine.ProductId)!.ProductName!,
              price: cartLine.Price!,
              totalAmount: cartLine.Quantity! * cartLine.Price!,
              comment: cartLine.Comment!
          };
          receiptItems.push(receiptItem);
      }
  })

  return receiptItems;
}