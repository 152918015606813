import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackRemarks?: any;
  callbackNamesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
  showRemarksField: boolean;
}

const NamesRemarksForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackEvent, callbackEventID, callbackName, callbackRemarks, callbackNamesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents, allowMultipleRegistrations,showRemarksField }) => {
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [ceremonyValue, setCeremonyValue] = useState(['']);
  const [ceremonyIDValue, setCeremonyIDValue] = useState(['']);
  const [serialNumber, setSerialNumber] = useState(['']);
  const [remark, setRemark] = useState(['']);

  const formLabels = resource.namesRemarksForm[`${localeCode}`];

  const noOfNames = formScheme.find(form => form.field_type === 'textBox')!.field_number!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  const { handleSubmit, reset } = useForm();

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];

    if (!inputMultipleName.includes(';')) {
      inputMultipleName += ';'.repeat(noOfNames - 1);
    }

    const inputMutlipleNameSplit = inputMultipleName.split(';');

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(';');

    _setCeremonyNameArray(inputName, index);
  };

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray);
  };

  const _setRemarksInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 100;

    _setRemarkArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setRemarkArray = (name: string, index: number) => {
    const remarkArray = remark.slice();

    while (typeof remarkArray[`${index}`] === 'undefined') {
      remarkArray.push('');
    }

    remarkArray[`${index}`] = name;

    remarkArray[`${index}`].trim();

    setRemark(remarkArray);

    callbackRemarks(remarkArray);
  }

  const _checkNamesEntered = (ceremonyNameArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _addItem = () => {
    addRow(1);

    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const remarkArray = remark.slice();

    ceremonyValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
    ceremonyIDValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
    ceremonyNameArray.push('');
    remarkArray.push('');

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);
    setRemark(remarkArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);
    callbackRemarks(remarkArray);

    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const serialNumberArray = serialNumber.slice();
    const remarkArray = remark.slice();

    if (typeof ceremonyValueArray[`${index}`] !== 'undefined') {
      if (ceremonyValueArray.length > 1) {
        ceremonyValueArray.splice(index, 1);
      } else {
        ceremonyValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      }
    }

    if (typeof ceremonyIDValueArray[`${index}`] !== 'undefined') {
      if (ceremonyIDValueArray.length > 1) {
        ceremonyIDValueArray.splice(index, 1);
      } else {
        ceremonyIDValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    if (typeof serialNumberArray[`${index}`] !== 'undefined') {
      if (serialNumberArray.length > 1) {
        serialNumberArray.splice(index, 1);
      } else {
        serialNumberArray[0] = '';
      }
    }

    if (typeof remarkArray[`${index}`] !== 'undefined') {
      if (remarkArray.length > 1) {
        remarkArray.splice(index, 1);
      } else {
        remarkArray[0] = '';
      }
    }

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);
    setSerialNumber(serialNumberArray);
    setRemark(remarkArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);
    callbackRemarks(remarkArray);

    _checkNamesEntered(ceremonyNameArray);
  }

  useEffect(() => {
    const existingEventName: string[] = [];
    const existingEventID: string[] = [];
    const existingCeremonyName: string[] = [];
    const existingRemark: string[] = [];

    // formItems could possibly be populated by past transactions/drafts/coming from cart.
    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const itemCommentParts = formItems[`${i}`]['names'];
        
        let count = 0;
        let ceremonyName = '';

        while (typeof itemCommentParts[`${count}`] !== 'undefined') {
          ceremonyName += itemCommentParts[`${count}`];

          if (typeof itemCommentParts[count + 1] !== 'undefined') {
            ceremonyName += ';';
          }

          count++;
        }

        const eventName = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
        const eventID = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';

        existingEventName.push(eventName);
        existingEventID.push(eventID);
        existingCeremonyName.push(ceremonyName);
        existingRemark.push(formItems[`${i}`]['remarks']);
      }

      setCeremonyName(existingCeremonyName);
      setRemark(existingRemark);

      callbackName(existingCeremonyName);
      callbackRemarks(existingRemark);

      _checkNamesEntered(existingCeremonyName);
    } else {
      const eventName = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      const eventID = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';

      existingEventName.push(eventName);
      existingEventID.push(eventID);
    }

    setCeremonyValue(existingEventName);
    setCeremonyIDValue(existingEventID);

    callbackEvent(existingEventName);
    callbackEventID(existingEventID);
  }, [formItems]);

  const _buildNameGroup = (idx: number, noOfNames: number): JSX.Element[] => {
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{formLabels.name.endsWith('*') ? nameIndex === 0 ? formLabels.name.substring(0, formLabels.name.length - 1) + ' ' + (nameIndex + 1) + '*' : formLabels.name.replace('*', ' ' + (nameIndex + 1)) : formLabels.name + ' ' + nameIndex + 1}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayName(ceremonyName[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            />
          </div>
        </div>
      );
    });
  }

  const _buildRemarks = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.remarks}</p>
          <textarea
            inputMode='text'
            value={remark[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setRemarksInputValue(event, idx)}
            className='form-control'
            maxLength={config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 101}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {noOfNames > 0 && _buildNameGroup(idx, noOfNames)}
                          {showRemarksField && _buildRemarks(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default NamesRemarksForm;