import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackBirthDate: any;
  callbackSchoolName: any;
  callbackBlessingOption: any;
  callbackNamesEntered?: any;
  callbackSchoolsEntered: any;
  callbackDateEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const NameBirthdaySchoolForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackEvent, callbackEventID, callbackName, callbackBirthDate, callbackSchoolName, callbackBlessingOption, callbackNamesEntered, callbackSchoolsEntered, callbackDateEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents, allowMultipleRegistrations }) => {

  const [ceremonyValue, setCeremonyValue] = useState(['']);
  const [ceremonyIDValue, setCeremonyIDValue] = useState(['']);
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [birthDate, setBirthDate] = useState([new Date().toISOString()]);
  const [schoolName, setSchoolName] = useState(['']);
  const [optionValue, setOptionValue] = useState([resource.optionInPersonLabel[`${localeCode}`]]);

  const formLabels = resource.nameBirthdaySchoolForm[`${localeCode}`];

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _setNameInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setCeremonyNameArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setCeremonyNameArray = (name: string, index: number) => {
    const valueArray = ceremonyName.slice();

    // To handle adding of a new form record.
    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = name;

    valueArray[`${index}`].trim();

    setCeremonyName(valueArray);

    callbackName(valueArray);

    _checkNameEntered(valueArray);
  }

  const _setBirthDate = (value: Date, index: number) => {
    const valueArray = birthDate.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(new Date().toISOString());
    }

    if (value) {
      valueArray[`${index}`] = value.toISOString();
    }
    setBirthDate(valueArray);

    callbackBirthDate(valueArray);

    _checkDateEntered(valueArray);
  }

  const _setSchoolName = (name: string, index: number) => {
    const valueArray = schoolName.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = name;

    valueArray[`${index}`].trim();

    setSchoolName(valueArray);

    callbackSchoolName(valueArray);

    _checkSchoolEntered(valueArray);
  }

  const _setOptionValueArray = (value: string, index: number) => {
    const valueArray = optionValue.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(resource.optionInPersonLabel[`${localeCode}`]);
    }

    valueArray[`${index}`] = value;

    setOptionValue(valueArray);

    callbackBlessingOption(valueArray);
  }

  const _checkNameEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        checked = false; break;
      }
    }

    callbackNamesEntered(checked);
  }

  const _checkSchoolEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        checked = false; break;
      }
    }

    callbackSchoolsEntered(checked);
  }

  const _checkDateEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`] === null) {
        checked = false; break;
      }
    }

    callbackDateEntered(checked);
  }

  const _addItem = () => {
    addRow(1);

    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dateValueArray = birthDate.slice();
    const schoolNameArray = schoolName.slice();
    const radioValueArray = optionValue.slice();

    ceremonyValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
    ceremonyIDValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
    ceremonyNameArray.push('');
    dateValueArray.push(new Date().toISOString());
    schoolNameArray.push('');
    radioValueArray.push(resource.optionInPersonLabel[`${localeCode}`]);

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);
    setBirthDate(dateValueArray);
    setSchoolName(schoolNameArray);
    setOptionValue(radioValueArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);
    callbackBirthDate(dateValueArray);
    callbackSchoolName(schoolNameArray);
    callbackBlessingOption(radioValueArray);

    callbackNamesEntered(false);
    callbackSchoolsEntered(false);
    callbackDateEntered(true);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const blessingOptionArray = optionValue.slice();
    const schoolNameArray = schoolName.slice();
    const birthDateArray = birthDate.slice();

    if (typeof ceremonyValueArray[`${index}`] !== 'undefined') {
      if (ceremonyValueArray.length > 1) {
        ceremonyValueArray.splice(index, 1);
      } else {
        ceremonyValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      }
    }

    if (typeof ceremonyIDValueArray[`${index}`] !== 'undefined') {
      if (ceremonyIDValueArray.length > 1) {
        ceremonyIDValueArray.splice(index, 1);
      } else {
        ceremonyIDValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }

      setCeremonyName(ceremonyNameArray);

      callbackDateEntered(ceremonyNameArray);
    }

    if (typeof blessingOptionArray[`${index}`] !== 'undefined') {
      if (blessingOptionArray.length > 1) {
        blessingOptionArray.splice(index, 1);
      } else {
        blessingOptionArray[0] = resource.optionInPersonLabel[`${localeCode}`];
      }

      setOptionValue(blessingOptionArray);

      callbackBlessingOption(blessingOptionArray);
    }

    if (typeof schoolNameArray[`${index}`] !== 'undefined') {
      if (schoolNameArray.length > 1) {
        schoolNameArray.splice(index, 1);
      } else {
        schoolNameArray[0] = '';
      }

      setSchoolName(schoolNameArray);

      callbackSchoolName(schoolNameArray);
    }

    if (typeof birthDateArray[`${index}`] !== 'undefined') {
      if (birthDateArray.length > 1) {
        birthDateArray.splice(index, 1);
      } else {
        birthDateArray[0] = new Date().toISOString();
      }

      setBirthDate(birthDateArray);

      callbackName(birthDateArray);
    }

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);

    _checkNameEntered(ceremonyNameArray);
    _checkSchoolEntered(schoolNameArray);
    _checkDateEntered(birthDateArray);
  }

  useEffect(() => {
    const existingCeremony: string[] = [];
    const existingCeremonyID: string[] = [];
    const existingCeremonyName: string[] = [];
    const existingRadioValue: string[] = [];
    const existingSchoolName: string[] = [];
    const existingBirthDate: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const birthDate = formItems[`${i}`]['birthDate'] !== '' ? new Date(formItems[`${i}`]['birthDate']) : new Date();

        existingCeremony.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
        existingCeremonyID.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
        existingCeremonyName.push(formItems[`${i}`]['names'][0]);
        existingRadioValue.push(formItems[`${i}`]['blessingOption'] !== '' ? formItems[`${i}`]['blessingOption'] : resource.optionInPersonLabel[`${localeCode}`]);
        existingSchoolName.push(formItems[`${i}`]['schoolName']);
        existingBirthDate.push(birthDate.toISOString());
      }

      setCeremonyName(existingCeremonyName);
      setSchoolName(existingSchoolName);

      callbackName(existingCeremonyName);
      callbackSchoolName(existingSchoolName);

      _checkNameEntered(existingCeremonyName);
      _checkSchoolEntered(existingSchoolName);
    } else {
      existingCeremony.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
      existingCeremonyID.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
      existingBirthDate.push(new Date().toISOString());
      existingRadioValue.push(resource.optionInPersonLabel[`${localeCode}`]);
    }

    setCeremonyValue(existingCeremony);
    setCeremonyIDValue(existingCeremonyID);
    setOptionValue(existingRadioValue);
    setBirthDate(existingBirthDate);

    callbackEvent(existingCeremony);
    callbackEventID(existingCeremonyID);
    callbackBlessingOption(existingRadioValue);
    callbackBirthDate(existingBirthDate);

    _checkDateEntered(existingBirthDate);
  }, [formItems])

  const _buildNameField = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.name}</p>
          <input
            type='text'
            inputMode='text'
            value={ceremonyName[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildBirthDate = (idx: number): JSX.Element | null => {
    if (typeof birthDate[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.birthDate}</p>
          <DatePicker
            dateFormat='dd/MM/yyyy'
            selected={new Date(birthDate[`${idx}`])}
            onChange={(date: Date) => _setBirthDate(date, idx)}
            placeholderText='DD/MM/YYYY'
          />
        </div>
      </div>
    );
  }

  const _buildSchoolField = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.schoolName}</p>
          <input
            type='text'
            inputMode='text'
            value={schoolName[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setSchoolName(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildOptionField = (idx: number): JSX.Element | null => {
    return (
      <div className='row'>
        <div className='col-12'>
          <p className='form-label'>{formLabels.option}</p>
          <div className='form-field row'>
            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.optionInPersonLabel[`${localeCode}`]}
                checked={optionValue[`${idx}`] === String(resource.optionInPersonLabel[`${localeCode}`])}
                aria-checked={optionValue[`${idx}`] === String(resource.optionInPersonLabel[`${localeCode}`])}
                onChange={(event: any) => _setOptionValueArray(event.target.value, idx)}
              />
              {resource.optionInPersonLabel[`${localeCode}`]}
            </label>

            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.optionOnlineLabel[`${localeCode}`]}
                checked={optionValue[`${idx}`] === String(resource.optionOnlineLabel[`${localeCode}`])}
                aria-checked={optionValue[`${idx}`] === String(resource.optionOnlineLabel[`${localeCode}`])}
                onChange={(event: any) => _setOptionValueArray(event.target.value, idx)}
              />
              {resource.optionOnlineLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildNameField(idx)}
                          {_buildBirthDate(idx)}
                          {_buildSchoolField(idx)}
                          {_buildOptionField(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>
        }
      </form>
    </div>
  );
};

export default NameBirthdaySchoolForm;