import React, { useState, useEffect } from 'react';

import DatePicker from 'react-date-picker';
import Select from 'react-select';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import countries from '../resources/countries.json';
import period from '../../../resources/dedicationPeriod.json';
import resource from '../resources/resource.json';
import time from '../resources/time.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState, ITimeRange } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackMultiplier: any;
  callbackDedicationPeriod: any;
  callbackStartDate: any;
  callbackName: any;
  callbackGender: any;
  callbackBirthDate: any;
  callbackBirthTime: any;
  callbackBuilding: any;
  callbackUnitNumber: any;
  callbackPostalCode: any;
  callbackCountry: any;
  callbackNamesEntered: any;
  callbackDateEntered: any;
  callbackAddressEntered: any;
  callbackRemarks?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  userInfo: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
  showRemarksField: boolean;
}

const ServicesGeneralForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackMultiplier, callbackDedicationPeriod, callbackStartDate, callbackName, callbackGender, callbackBirthDate, callbackBirthTime,
  callbackBuilding, callbackUnitNumber, callbackPostalCode, callbackCountry,
  callbackNamesEntered, callbackDateEntered, callbackAddressEntered, callbackRemarks,
  formItems, config, localeCode, actionContext, userInfo, isMobile, isEditMode, allowMultipleRegistrations,showRemarksField }) => {
  const [dedicationPeriods, setDedicationPeriods] = useState([period[`${localeCode}`].period[0].label]);
  const [startDates, setStartDates] = useState(['']);
  const [names, setNames] = useState(['']);
  const [genders, setGenders] = useState([resource.genderMaleLabel[`${localeCode}`]]);
  const [birthDates, setBirthDates] = useState(['']);
  const [birthTimes, setBirthTimes] = useState(['']);  
  const [buildingNames, setBuildingNames] = useState(['']);
  const [unitNumbers, setUnitNumbers] = useState(['']);
  const [postalCodes, setPostalCodes] = useState(['']);
  const [countryNames, setCountries] = useState(['']);
  const [remarks, setRemarks] = useState(['']);

  const [timeRanges, setTimeRanges] = useState<{ value: string, label: string }[][]>([]);
  const [multipliers, setMultiplers] = useState([1]);

  const formLabels = resource.servicesGeneralForm[`${localeCode}`];
  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const startDate = new Date();
  startDate.setDate(startDate.getDate() + 1);
  startDate.setHours(0, 0, 0, 0);

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _setMultipler = (periodLabel: string, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(period.scale[0]);
    }

    const scaleIdx = period[`${localeCode}`].period.findIndex((item: { value: number; label: string; }) => item.label === periodLabel);

    valueArray[`${index}`] = period.scale[`${scaleIdx}`];

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setDedicationPeriod = (event: any, index: number) => {
    const valueArray = dedicationPeriods.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(period[`${localeCode}`].period[0].label);
    }

    valueArray[`${index}`] = event.label;

    setDedicationPeriods(valueArray);

    callbackDedicationPeriod(valueArray);

    _setMultipler(event.label, index);
  }

  const _setStartDate = (value: Date, index: number) => {
    const valueArray = startDates.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(startDate.toISOString());
    }

    value.setHours(0, 0, 0, 0);

    const date = value ? value.toISOString() : startDate.toISOString();

    valueArray[`${index}`] = date;

    setStartDates(valueArray);

    callbackStartDate(valueArray);

    _checkDateEntered(valueArray, birthDates);
  }

  const _formatNameInput = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setName(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setName = (name: string, index: number) => {
    const valueArray = names.slice();

    // To handle adding of a new form record.
    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = name;

    valueArray[`${index}`].trim();

    setNames(valueArray);

    callbackName(valueArray);

    _checkNameEntered(valueArray);
  }

  const _setGender = (event: any, index: number) => {
    const valueArray = genders.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(resource.genderMaleLabel[`${localeCode}`]);
    }

    valueArray[`${index}`] = event.target.value;

    setGenders(valueArray);
    
    callbackGender(valueArray);
  }

  const _setBirthDate = (value: Date, index: number) => {
    const valueArray = birthDates.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    const date = value ? value.toISOString() : '';

    valueArray[`${index}`] = date;

    _setTimeRange(value, index);

    setBirthDates(valueArray);

    callbackBirthDate(valueArray);

    _checkDateEntered(startDates, valueArray);
  }

  const _setBirthTime = (event: any, index: number) => {
    const valueArray = birthTimes.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = event.label;

    setBirthTimes(valueArray);

    callbackBirthTime(valueArray);
  }

  const _setBuildingName = (value: string, index: number) => {
    const valueArray = buildingNames.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setBuildingNames(valueArray);

    callbackBuilding(valueArray);

    _checkAddressEntered(valueArray, postalCodes, countryNames);
  }

  const _setUnitNumber = (value: string, index: number) => {
    const valueArray = unitNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setUnitNumbers(valueArray);

    callbackUnitNumber(valueArray);
  }

  const _setPostalCode = (value: string, index: number) => {
    const valueArray = postalCodes.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    if(value.length<=6){
      valueArray[`${index}`] = value;
    }else{
      toast.warning(resource.invalidPostalCodeMessage[`${localeCode}`]);
    }

    valueArray[`${index}`].trim();

    setPostalCodes(valueArray);

    callbackPostalCode(valueArray);

    _checkAddressEntered(buildingNames, valueArray, countryNames);
  }

  const _setCountry = (event: any, index: number) => {
    const valueArray = countryNames.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = event.label;

    setCountries(valueArray);

    callbackCountry(valueArray);

    _checkAddressEntered(buildingNames, postalCodes, valueArray);
  }

  const _setTimeRange = (value: Date, index: number) => {
    const valueArray = timeRanges.slice();

    while (valueArray.length < dedicationPeriods.length) {
      valueArray.push(time.intervals);
    }

    const date = value ? value : new Date();

    const now = new Date();

    date.setHours(0, 0, 0, 0);

    if (date.getFullYear() >= now.getFullYear() && date.getMonth() >= now.getMonth() && date.getDate() >= now.getDate()) {
      const ranges: { value: string; label: string; }[] = [];

      time.intervals.map(range => {
        const interval = range.label.split(' ');

        const firstHour = parseInt(interval[0].split(':')[0]);
        const lastHour = parseInt(interval[interval.length - 1].split(':')[0]);

        if (firstHour <= now.getHours() && lastHour <= now.getHours() || firstHour <= now.getHours() && now.getHours() <= lastHour) {
          ranges.push(range);
        }
      });

      valueArray[`${index}`] = ranges;
    } else {
      valueArray[`${index}`] = time.intervals;
    }

    setTimeRanges(valueArray);
  }

  const _displayDedicationPeriod = (index: number): { value: string, label: string } => {
    if (dedicationPeriods.length > 0) {
      const selectValueArray = dedicationPeriods.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        return period[`${localeCode}`].period.find((item: { value: string, label: string }) => item.label === selectValueArray[`${index}`]);
      }
    }

    return { value: '', label: '' };
  }

  const _checkNameEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        checked = false; break;
      }
    }

    callbackNamesEntered(checked);
  }

  const _checkDateEntered = (startDatesArray: string[], birthDatesArray: string[]) => {
    let result = true;

    const count = startDatesArray.length < birthDatesArray.length ? startDatesArray.length : birthDatesArray.length;

    for (let i = 0; i < count; i++) {
      const startDateInvalid = startDatesArray[`${i}`] === undefined || startDatesArray[`${i}`] === '' ? true : false;

      const birthDate = new Date(birthDatesArray[`${i}`]);

      const birthDateInvalid = birthDatesArray[`${i}`] === undefined || birthDatesArray[`${i}`] === '' || birthDate.getFullYear() <= 1900 || birthDate.getMonth() > 12 || birthDate.getDate() > 31 ? true : false;

      if (startDateInvalid || birthDateInvalid) {
        result = false; break;
      }
    }

    callbackDateEntered(result);
  }

  const _checkAddressEntered = (buildingNamesArray: string[], postalCodesArray: string[], countriesArray: string[]) => {
    let result = true;

    for (let i = 0; i < buildingNamesArray.length; ++i) {
      result = result && buildingNamesArray[`${i}`] !== '' && postalCodesArray[`${i}`] !== '' && countriesArray[`${i}`] !== '';
    }

    callbackAddressEntered(result);
  }

  const _setRemarksInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 100;

    _setRemarkArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setRemarkArray = (name: string, index: number) => {
    const remarkArray = remarks.slice();

    while (typeof remarkArray[`${index}`] === 'undefined') {
      remarkArray.push('');
    }

    remarkArray[`${index}`] = name;

    remarkArray[`${index}`].trim();

    setRemarks(remarkArray);

    callbackRemarks(remarkArray);
  }

  const _initBuildingField = () => {
    if (userValid) {
      return userInfo.gmb_blockno.trim() + (userInfo.gmb_buildingno !== '' ? ', ' + userInfo.gmb_buildingno.trim() : '') + (userInfo.gmb_buildingname !== '' ? ', ' + userInfo.gmb_buildingname.trim() : '') + (userInfo.address1_line1 !== '' ? ', ' + userInfo.address1_line1.trim() : '') + (userInfo.address1_city !== '' ? ', ' + userInfo.address1_city.trim() : '');
    }

    return '';
  }

  const _initCountryField = () => {
    if (userValid) {
      const states = countries[`${localeCode}`].countries;

      const location = states.find((place: { value: string, label: string }) => place.label.toUpperCase() === userInfo.address1_country.toUpperCase());

      return location ? location.label : '';
    }

    return '';
  }

  const _addItem = () => {
    addRow(1);

    const multipliersArray = multipliers.slice();
    const dedicationPeriodsArray = dedicationPeriods.slice();
    const startDatesArray = startDates.slice();
    const namesArray = names.slice();
    const gendersArray = genders.slice();
    const birthDatesArray = birthDates.slice();
    const birthTimesArray = birthTimes.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();
    const countriesArray = countryNames.slice();
    const remarksArray = remarks.slice();

    multipliersArray.push(period.scale[0]);
    dedicationPeriodsArray.push(period[`${localeCode}`].period[0].label);
    startDatesArray.push(startDate.toDateString());
    gendersArray.push(resource.genderMaleLabel[`${localeCode}`]);
    namesArray.push('');
    birthDatesArray.push('');
    birthTimesArray.push('');
    remarksArray.push('');

    if (userValid) {
      buildingNamesArray.push(_initBuildingField());
      unitNumbersArray.push(userInfo.gmb_unitnofloorapartmentno);
      postalCodesArray.push(userInfo.address1_postalcode);
      countriesArray.push(_initCountryField());
    } else {
      buildingNamesArray.push('');
      unitNumbersArray.push('');
      postalCodesArray.push('');
      countriesArray.push(countries[`${localeCode}`].countries[0].label);
    }

    setMultiplers(multipliersArray);
    setDedicationPeriods(dedicationPeriodsArray);
    setStartDates(startDatesArray);
    setNames(namesArray);
    setGenders(gendersArray);
    setBirthDates(birthDatesArray);
    setBirthTimes(birthTimesArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);
    setCountries(countriesArray);
    setRemarks(remarksArray);

    _setTimeRange(new Date(), dedicationPeriodsArray.length - 1);

    callbackMultiplier(multipliersArray);
    callbackDedicationPeriod(dedicationPeriodsArray);
    callbackStartDate(startDatesArray);
    callbackName(namesArray);
    callbackGender(gendersArray);
    callbackBirthDate(birthDatesArray);
    callbackBirthTime(birthTimesArray);
    callbackBuilding(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);
    callbackCountry(countriesArray);
    callbackRemarks(remarksArray);

    callbackNamesEntered(false);
    
    _checkDateEntered(startDatesArray, birthDatesArray);
    _checkAddressEntered(buildingNamesArray, postalCodesArray, countriesArray);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const multipliersArray = multipliers.slice();
    const dedicationPeriodsArray = dedicationPeriods.slice();
    const startDatesArray = startDates.slice();
    const namesArray = names.slice();
    const gendersArray = genders.slice();
    const birthDatesArray = birthDates.slice();
    const birthTimesArray = birthTimes.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();
    const countriesArray = countryNames.slice();
    const remarksArray = remarks.slice();

    if (typeof multipliersArray[`${index}`] !== 'undefined') {
      if (multipliersArray.length > 1) {
        multipliersArray.splice(index, 1);
      } else {
        multipliersArray[0] = period.scale[0];
      }

      setMultiplers(multipliersArray);

      callbackMultiplier(multipliersArray);
    }

    if (typeof dedicationPeriodsArray[`${index}`] !== 'undefined') {
      if (dedicationPeriodsArray.length > 1) {
        dedicationPeriodsArray.splice(index, 1);
      } else {
        dedicationPeriodsArray[0] = period[`${localeCode}`].period[0].label;
      }

      setDedicationPeriods(dedicationPeriodsArray);

      callbackDedicationPeriod(dedicationPeriodsArray);
    }

    if (typeof startDatesArray[`${index}`] !== 'undefined') {
      if (startDatesArray.length > 1) {
        startDatesArray.splice(index, 1);
      } else {
        startDatesArray[0] = startDate.toISOString();
      }

      setStartDates(startDatesArray);

      callbackStartDate(startDatesArray);
    }

    if (typeof namesArray[`${index}`] !== 'undefined') {
      if (namesArray.length > 1) {
        namesArray.splice(index, 1);
      } else {
        namesArray[0] = '';
      }

      setNames(namesArray);

      callbackName(namesArray);
    }

    if (typeof gendersArray[`${index}`] !== 'undefined') {
      if (gendersArray.length > 1) {
        gendersArray.splice(index, 1);
      } else {
        gendersArray[0] = resource.genderMaleLabel[`${localeCode}`];
      }

      setGenders(gendersArray);

      callbackGender(gendersArray);
    }

    if (typeof birthDatesArray[`${index}`] !== 'undefined') {
      if (birthDatesArray.length > 1) {
        birthDatesArray.splice(index, 1);
      } else {
        birthDatesArray[0] = '';
      }

      setBirthDates(birthDatesArray);

      callbackBirthDate(birthDatesArray);
    }

    if (typeof birthTimesArray[`${index}`] !== 'undefined') {
      if (birthTimesArray.length > 1) {
        birthTimesArray.splice(index, 1);
      } else {
        birthTimesArray[0] = '';
      }

      setBirthTimes(birthTimesArray);

      callbackBirthTime(birthTimesArray);
    }

    if (typeof buildingNamesArray[`${index}`] !== 'undefined') {
      if (buildingNamesArray.length > 1) {
        buildingNamesArray.splice(index, 1);
      } else {
        buildingNamesArray[0] = '';
      }

      setBuildingNames(buildingNamesArray);

      callbackAddressEntered(buildingNamesArray);
    }

    if (typeof unitNumbersArray[`${index}`] !== 'undefined') {
      if (unitNumbersArray.length > 1) {
        unitNumbersArray.splice(index, 1);
      } else {
        unitNumbersArray[0] = '';
      }

      setUnitNumbers(unitNumbersArray);

      callbackUnitNumber(unitNumbersArray);
    }

    if (typeof postalCodesArray[`${index}`] !== 'undefined') {
      if (postalCodesArray.length > 1) {
        postalCodesArray.splice(index, 1);
      } else {
        postalCodesArray[0] = '';
      }

      setPostalCodes(postalCodesArray);

      callbackPostalCode(postalCodesArray);
    }

    if (typeof countriesArray[`${index}`] !== 'undefined') {
      if (countriesArray.length > 1) {
        countriesArray.splice(index, 1);
      } else {
        countriesArray[0] = countries[`${localeCode}`].countries[0].label;
      }

      setCountries(countriesArray);

      callbackCountry(countriesArray);
    }

    if (typeof timeRanges[`${index}`] !== 'undefined') {
      if (timeRanges.length > 1) {
        timeRanges.splice(index, 1);
      } else {
        _setTimeRange(new Date(), 0);
      }
    }

    if (typeof remarksArray[`${index}`] !== 'undefined') {
      if (remarksArray.length > 1) {
        remarksArray.splice(index, 1);
      } else {
        remarksArray[0] = '';
      }

      setRemarks(postalCodesArray);

      callbackRemarks(postalCodesArray);
    }

    _checkNameEntered(namesArray);
    _checkDateEntered(startDatesArray, birthDatesArray);
    _checkAddressEntered(buildingNamesArray, postalCodesArray, countriesArray);
  }

  useEffect(() => {
    const multipliersArray: number[] = [];
    const dedicatedPeriodsArray: string[] = [];
    const startDatesArray: string[] = [];
    const namesArray: string[] = [];
    const gendersArray: string[] = [];
    const birthDatesArray: string[] = [];
    const birthTimesArray: string[] = [];
    const buildingNamesArray: string[] = [];
    const unitNumbersArray: string[] = [];
    const postalCodesArray: string[] = [];
    const countriesArray: string[] = [];
    const remarksArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const selectedPeriod = formItems[`${i}`]['dedicationPeriod'] !== '' ? formItems[`${i}`]['dedicationPeriod'] : period[`${localeCode}`].period[0].label;
        const index = period[`${localeCode}`].period.findIndex((item: { value: number; label: string; }) => item.label === selectedPeriod);

        const gender = formItems[`${i}`]['gender'] !== '' ? formItems[`${i}`]['gender'] : resource.genderMaleLabel[`${localeCode}`];

        const birthDate = formItems[`${i}`]['birthDate'] !== '' ? new Date(formItems[`${i}`]['birthDate']) : new Date();

        multipliersArray.push(period.scale[`${index}`]);
        dedicatedPeriodsArray.push(selectedPeriod);
        startDatesArray.push(formItems[`${i}`]['startDate'] ? formItems[`${i}`]['startDate'] : startDate.toISOString());
        namesArray.push(formItems[`${i}`]['names'][0]);
        gendersArray.push(gender);
        birthDatesArray.push(birthDate.toISOString());
        birthTimesArray.push(formItems[`${i}`]['birthTime']);
        buildingNamesArray.push(formItems[`${i}`]['buildingName']);
        unitNumbersArray.push(formItems[`${i}`]['unitNumber']);
        postalCodesArray.push(formItems[`${i}`]['postalCode']);
        countriesArray.push(formItems[`${i}`]['country']);
        remarksArray.push(formItems[`${i}`]['remarks']);
        
        _setTimeRange(birthDate, i);
      }

      setMultiplers(multipliersArray);
      setDedicationPeriods(dedicatedPeriodsArray);
      setNames(namesArray);
      setGenders(gendersArray);
      setBirthDates(birthDatesArray);
      setBirthTimes(birthTimesArray);
      setBuildingNames(buildingNamesArray);
      setUnitNumbers(unitNumbersArray);
      setPostalCodes(postalCodesArray);
      setCountries(countriesArray);
      setRemarks(remarksArray);

      callbackMultiplier(multipliersArray);
      callbackDedicationPeriod(dedicatedPeriodsArray);
      callbackName(namesArray);
      callbackGender(gendersArray);
      callbackBirthDate(birthDatesArray);
      callbackBirthTime(birthTimesArray);
      callbackBuilding(buildingNamesArray);
      callbackUnitNumber(unitNumbersArray);
      callbackPostalCode(postalCodesArray);
      callbackCountry(countriesArray);
      callbackRemarks(remarksArray);

      _checkNameEntered(namesArray);
      _checkAddressEntered(buildingNamesArray, postalCodesArray, countriesArray);
    } else {
      if (userValid) {
        buildingNamesArray.push(_initBuildingField());
        unitNumbersArray.push(userInfo.gmb_unitnofloorapartmentno);
        postalCodesArray.push(userInfo.address1_postalcode);
        countriesArray.push(_initCountryField());

        setBuildingNames(buildingNamesArray);
        setUnitNumbers(unitNumbersArray);
        setPostalCodes(postalCodesArray);
        setCountries(countriesArray);

        callbackBuilding(buildingNamesArray);
        callbackUnitNumber(unitNumbersArray);
        callbackPostalCode(postalCodesArray);
        callbackCountry(countriesArray);

        _checkAddressEntered(buildingNamesArray, postalCodesArray, countriesArray);
      }

      startDatesArray.push(startDate.toISOString());
      gendersArray.push(resource.genderMaleLabel[`${localeCode}`]);

      _setTimeRange(new Date(), 0);

      callbackDedicationPeriod(dedicationPeriods);
      callbackGender(gendersArray);
    }

    setStartDates(startDatesArray);

    callbackStartDate(startDatesArray);

    _checkDateEntered(startDatesArray, birthDatesArray);
  }, [formItems])

  const _buildDedicationPeriod = (idx: number): JSX.Element | null => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.dedicationPeriod}</p>
          <Select
            value={_displayDedicationPeriod(idx)}
            options={period[`${localeCode}`].period}
            onChange={(event: any) => _setDedicationPeriod(event, idx)}
            className={'select'}
            isSearchable={false}
          />
        </div>

        <div className='col-lg-4 offset-lg-1 form-field'>
          <p className='form-label'>{formLabels.startDate}</p>
          <DatePicker
            onChange={(date: Date) => _setStartDate(date, idx)}
            value={startDates[`${idx}`] === '' || startDates[`${idx}`].includes('1900') ? null : new Date(startDates[`${idx}`])}
            format={'dd-MM-y'}
            minDate={startDate}
            className={'datepicker'}
            dayPlaceholder={'DD'}
            monthPlaceholder={'MM'}
            yearPlaceholder={'YYYY'}
          />
        </div>
      </div>
    );
  }

  const _buildNameField = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.name}</p>
          <input
            type='text'
            inputMode='text'
            value={names[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _formatNameInput(event, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            autoComplete='none'
          />
        </div>
      </div>
    );
  }

  const _buildGenderField = (idx: number): JSX.Element | null => {
    if (typeof genders[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <p className='form-label'>{formLabels.gender}</p>
          <div className='form-field row'>
            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.genderMaleLabel[`${localeCode}`]}
                checked={genders[`${idx}`] === String(resource.genderMaleLabel[`${localeCode}`])}
                aria-checked={genders[`${idx}`] === String(resource.genderMaleLabel[`${localeCode}`])}
                onChange={(event: any) => _setGender(event, idx)}
              />
              {resource.genderMaleLabel[`${localeCode}`]}
            </label>

            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.genderFemaleLabel[`${localeCode}`]}
                checked={genders[`${idx}`] === String(resource.genderFemaleLabel[`${localeCode}`])}
                aria-checked={genders[`${idx}`] === String(resource.genderFemaleLabel[`${localeCode}`])}
                onChange={(event: any) => _setGender(event, idx)}
              />
             {resource.genderFemaleLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    );
  }

  const _buildBirthDateTime = (idx: number): JSX.Element | null => {
    if (timeRanges[`${idx}`] === undefined || birthDates[`${idx}`] === undefined) {
      return null;
    }

    const found = timeRanges[`${idx}`].find((slot: ITimeRange) => slot.label === birthTimes[`${idx}`]);

    const displayTime = found ? found : { value: '', label: 'Select time' };

    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.birthDate}</p>
          <DatePicker
            onChange={(date: Date) => _setBirthDate(date, idx)}
            value={birthDates[`${idx}`] === '' || birthDates[`${idx}`].includes('1900') ? null : new Date(birthDates[`${idx}`])}
            format={'dd-MM-y'}
            maxDate={new Date()}
            className={'datepicker'}
            dayPlaceholder={'DD'}
            monthPlaceholder={'MM'}
            yearPlaceholder={'YYYY'}
          />
        </div>

        <div className='col-lg-4 offset-lg-1 form-field'>
          <p className='form-label'>{formLabels.birthTime}</p>
          <Select
            value={displayTime}
            options={timeRanges[`${idx}`]}
            onChange={(event: any) => _setBirthTime(event, idx)}
            className={'select'}
            isSearchable={false}
          />
        </div>
      </div>
    );
  }

  const _buildAddressField = (idx: number): JSX.Element => {
    const states = countries[`${localeCode}`].countries;

    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.addressPrompt}</p>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.addressBuilding}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={(event: any) => _setBuildingName(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={buildingNames[`${idx}`]}
            type='text'
            autoComplete='none'
          />

          <p className='form-label'>{formLabels.addressUnit}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={(event: any) => _setUnitNumber(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={unitNumbers[`${idx}`]}
            type='text'
            autoComplete='none'
          />
        </div>

        <div className='col-lg-10 row'>
          <div className='col-lg-4 form-field'>
            <p className='form-label'>{formLabels.addressPostalCode}</p>
            <input
              type='text'
              inputMode='text'
              value={postalCodes[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setPostalCode(event.target.value, idx)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              autoComplete='none'
            />
          </div>

          <div className='col-lg-5 offset-lg-1 form-field'>
            <p className='form-label'>{formLabels.addressCountry}</p>
            <Select
              placeholder={countryNames[`${idx}`]}
              options={states}
              onChange={(event: any) => _setCountry(event, idx)}
              className={'select'}
              isSearchable={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const _buildRemarks = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.remarks}</p>
          <textarea
            inputMode='text'
            value={remarks[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setRemarksInputValue(event, idx)}
            className='form-control'
            maxLength={config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 101}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'period') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildDedicationPeriod(idx)}
                          {_buildNameField(idx)}
                          {_buildGenderField(idx)}
                          {_buildBirthDateTime(idx)}
                          {_buildAddressField(idx)}
                          {showRemarksField && _buildRemarks(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${multipliers[`${idx}`] * selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default ServicesGeneralForm;