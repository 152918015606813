import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import moment from "moment";

import resource from "../resources/resource.json";

import { getCartState } from "@msdyn365-commerce/global-state";
import { SimpleProduct, AsyncResult } from "@msdyn365-commerce/retail-proxy";

import {
  deleteBTGPCartLineAsync,
  getAllBTGPCartLineAsync,
} from "../../../actions/DataActionExtension.g";

import { IField, IdState } from "../ecomm-product-details-form.data";
import { IEcommProductDetailsFormConfig } from "../ecomm-product-details-form.props.autogenerated";

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  renewableLamps: [];
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  replaceRow(noOfRows: number, idx: number[]): void;
  deleteRow(idx: number): void;
  removeLamp(serialId: string): void;
  callbackName?: any;
  callbackCategory?: any;
  callbackNoOfYear?: any;
  callbackLightOption?: any;
  callbackValidFrom?: any;
  callbackValidTo?: any;
  callbackOldName?: any;
  callbackNameChange?: any;
  callbackSerialNumber?: any;
  callbackSerialId?: any;
  callbackSerialNames: any;
  callbackNamesEntered?: any;
  formItems: any[];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  addRenewLamp: boolean;
  itemUnavailable: boolean;
  allowMultipleRegistrations: boolean;
}

const NoOfYearsCategoryNamesForm: React.FC<IEcommFormProps> = ({
  formScheme,
  selectedProduct,
  renewableLamps,
  rowsData,
  addRow,
  replaceRow,
  deleteRow,
  removeLamp,
  callbackName,
  callbackCategory,
  callbackNoOfYear,
  callbackLightOption,
  callbackValidFrom,
  callbackValidTo,
  callbackOldName,
  callbackNameChange,
  callbackSerialId,
  callbackSerialNumber,
  callbackSerialNames,
  callbackNamesEntered,
  formItems,
  config,
  localeCode,
  actionContext,
  isMobile,
  isEditMode,
  addRenewLamp,
  itemUnavailable,
  allowMultipleRegistrations
}) => {
  const [serialNumberValue, setSerialNumberValue] = useState([""]);
  const [serialIdValue, setSerialIdValue] = useState([""]);
  const [serialNames, setSerialNames] = useState([""]);
  const [lightValue, setLightValue] = useState(["New"]);
  const [yearValue, setYearValue] = useState([1]);
  const [startYearValue, setStartYearValue] = useState([
    new Date().toISOString(),
  ]);
  const [endYearValue, setEndYearValue] = useState([
    new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).toISOString(),
  ]);
  const [nameChangeValue, setNameChangeValue] = useState([""]);
  const [oldNameValue, setOldNameValue] = useState([""]);
  const [radioValue, setRadioValue] = useState([
    resource.categoryFamilyLabel[`${localeCode}`],
  ]);
  const [ceremonyName, setCeremonyName] = useState([""]);
  //const [clickAddItem, setAddItem] = useState(false);

  const formLabels = resource.noOfYearsCategoryNamesForm[`${localeCode}`];
  const noOfNames = formScheme.find((form) => form.field_type === "textBox")!
    .field_number!;
  const companyNames = formScheme.find((form) => form.field_type === "textBox")!
    .field_number2!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _onChangeYearValue = (event: any, index: number) => {
    _setYearValueArray(event.target.value, index);
    _setValidValueArray(event.target.value, index);
  };

  const _onChangeNameChangeValue = (event: any, index: number) => {
    _setNameChangeValueArray(event.target.value, index);
    _setOldNameValueArray(event.target.value, index);
  };

  const _onChangeRadioValue = (event: any, index: number) => {
    _setRadioValueArray(event.target.value, index);

    const ceremonyNameArray = ceremonyName.slice();

    let existingCeremonyName = ceremonyNameArray[`${index}`];

    const existingNameArray = existingCeremonyName.split(";");

    if (event.target.value === resource.categoryFamilyLabel[`${localeCode}`]) {
      if (existingNameArray.length < noOfNames) {
        existingCeremonyName += ";".repeat(
          noOfNames - existingNameArray.length - 1
        );
      } else {
        // More than
        while (existingNameArray.length > noOfNames) {
          existingNameArray.pop();
        }

        existingCeremonyName = existingNameArray.join(";");
      }
    } else {
      if (existingNameArray.length < companyNames) {
        existingCeremonyName += ";".repeat(
          companyNames - existingNameArray.length - 1
        );
      } else {
        // More than
        while (existingNameArray.length > companyNames) {
          existingNameArray.pop();
        }
        existingCeremonyName = existingNameArray.join(";");
      }
    }

    ceremonyNameArray[`${index}`] = existingCeremonyName;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);
  };

  const _setNameChangeValueArray = (value: string, index: number) => {
    const nameChangeValueArray = nameChangeValue.slice();

    while (typeof nameChangeValueArray[`${index}`] === "undefined") {
      nameChangeValueArray.push("");
    }

    nameChangeValueArray[`${index}`] = value;

    nameChangeValueArray[`${index}`].trim();

    setNameChangeValue(nameChangeValueArray);
    callbackNameChange(nameChangeValueArray);
  };

  const _setOldNameValueArray = (value: string, index: number) => {
    const oldNameValueArray = oldNameValue.slice();
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof oldNameValueArray[`${index}`] === "undefined") {
      oldNameValueArray.push("");
    }

    if (oldNameValueArray[`${index}`] === "") {
      if (value === "Yes") {
        oldNameValueArray[`${index}`] = ceremonyName[`${index}`];
      }

      setOldNameValue(oldNameValueArray);
      callbackOldName(oldNameValueArray);
    } else {
      if (value === "No") {
        ceremonyNameArray[`${index}`] = oldNameValueArray[`${index}`];
        oldNameValueArray[`${index}`] = "";

        setCeremonyName(ceremonyNameArray);
        callbackName(ceremonyNameArray);
        setOldNameValue(oldNameValueArray);
        callbackOldName(oldNameValueArray);
      }
    }
  };

  const _setYearValueArray = (value: string, index: number) => {
    let yearValueArray = yearValue.slice();

    while (typeof yearValueArray[`${index}`] === "undefined") {
      yearValueArray.push(1);
    }

    yearValueArray[`${index}`] = +value;

    setYearValue(yearValueArray);
    callbackNoOfYear(yearValueArray);
  };

  const _setValidValueArray = (value: string, index: number) => {
    let startYearValueArray = startYearValue.slice();
    let endYearValueArray = endYearValue.slice();

    while (typeof startYearValueArray[`${index}`] === "undefined") {
      startYearValueArray.push(new Date().toISOString());
    }

    while (typeof endYearValueArray[`${index}`] === "undefined") {
      endYearValueArray.push(
        new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        ).toISOString()
      );
    }

    startYearValueArray[`${index}`] = new Date(
      startYearValueArray[`${index}`]
    ).toISOString();
    endYearValueArray[`${index}`] = new Date(
      new Date(startYearValueArray[`${index}`]).setFullYear(
        new Date(startYearValueArray[`${index}`]).getFullYear() + +value
      )
    ).toISOString();

    setStartYearValue(startYearValueArray);
    setEndYearValue(endYearValueArray);
    callbackValidFrom(startYearValueArray);
    callbackValidTo(endYearValueArray);
  };

  const _setRadioValueArray = (value: string, index: number) => {
    let radioValueArray = radioValue.slice();

    while (typeof radioValueArray[`${index}`] === "undefined") {
      radioValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    radioValueArray[`${index}`] = value;

    setRadioValue(radioValueArray);

    callbackCategory(radioValueArray);
  };

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(";");

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : "";
    }

    return "";
  };

  const _setNameMultipleInputValue = (
    event: any,
    index: number,
    nameIndex: number,
    noOfNames: number
  ) => {
    const maxLength = config.charactersLimitForNames
      ? config.charactersLimitForNames
      : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];

    if (!inputMultipleName.includes(";")) {
      inputMultipleName += ";".repeat(noOfNames - 1);
    }

    let inputMutlipleNameSplit = inputMultipleName.split(";");

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(";");

    _setCeremonyNameArray(inputName, index);
  };

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === "undefined") {
      ceremonyNameArray.push("");
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray);
  };

  const _checkNamesEntered = (ceremonyNameArray: string[]) => {
    let namesEntered = true;
    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`].replace(/;/g, "").trim() === "") {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  };

  const _addItem = () => {
    addRow(1);

    const serialNumberValueArray = serialNumberValue.slice();
    const serialIdValueArray = serialIdValue.slice();
    const serialNamesArray = serialNames.slice();
    const lightOptionValueArray = lightValue.slice();
    const startYearValueArray = startYearValue.slice();
    const endYearValueArray = endYearValue.slice();
    const yearValueArray = yearValue.slice();
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();

    serialNumberValueArray.push("");
    serialIdValueArray.push("");
    serialNamesArray.push("");
    lightOptionValueArray.push("New");
    startYearValueArray.push(new Date().toISOString());
    endYearValueArray.push(
      new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toISOString()
    );
    yearValueArray.push(1);
    categoryValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    ceremonyNameArray.push("");

    setSerialNumberValue(serialNumberValueArray);
    setSerialIdValue(serialIdValueArray);
    setSerialNames(serialNamesArray);
    setLightValue(lightOptionValueArray);
    setStartYearValue(startYearValueArray);
    setEndYearValue(endYearValueArray);
    setYearValue(yearValueArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);

    callbackSerialNumber(serialNumberValueArray);
    callbackSerialId(serialIdValueArray);
    callbackSerialNames(serialNamesArray);
    callbackLightOption(lightOptionValueArray);
    callbackValidFrom(startYearValueArray);
    callbackValidTo(endYearValueArray);
    callbackNoOfYear(yearValueArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);

    callbackNamesEntered(false);
  };

  const _addItemRenewSerial = async () => {
    let serialNumberValueArray = serialNumberValue.slice();
    let serialIdValueArray = serialIdValue.slice();
    let serialNamesArray = serialNames.slice();
    let lightOptionValueArray = lightValue.slice();
    let startYearValueArray = startYearValue.slice();
    let endYearValueArray = endYearValue.slice();
    let yearValueArray = yearValue.slice();
    let categoryValueArray = radioValue.slice();
    let ceremonyNameArray = ceremonyName.slice();
    let nameChangeValueArray = nameChangeValue.slice();
    let oldNameValueArray = oldNameValue.slice();

    let indexArray = [];
   

    for(let j=0; j< serialNumberValue.length; j++){

    for (let i = 0; i < serialNumberValueArray.length; i++) {
      if (
        serialNumberValueArray[`${i}`] == "" &&
        lightOptionValueArray[`${i}`] == "New" &&
        ceremonyNameArray[`${i}`] == ""
      ) {

        indexArray.push(i);

        serialNumberValueArray.splice(i,1);
        serialIdValueArray.splice(i,1);
        serialNamesArray.splice(i,1);
        lightOptionValueArray.splice(i,1);
        startYearValueArray.splice(i,1);
        endYearValueArray.splice(i,1);
        yearValueArray.splice(i,1);
        categoryValueArray.splice(i,1);
        ceremonyNameArray.splice(i,1);
        nameChangeValueArray.splice(i,1);

        break
      }
    }
  }

    let numberOfLamps = 0;

    for (let i = 0; i < renewableLamps.length; i++) {
      if (
        renewableLamps[`${i}`]["isAdded"] == false &&
        renewableLamps[`${i}`]["isChecked"] == true &&
        serialIdValueArray.findIndex(
          (id) => id === renewableLamps[`${i}`]["serialId"]
        ) < 0
      ) {
        numberOfLamps++;

        serialNumberValueArray.push(renewableLamps[`${i}`]["serialNumber"]);
        serialIdValueArray.push(renewableLamps[`${i}`]["serialId"]);
        serialNamesArray.push(renewableLamps[`${i}`]["serialName"]);
        lightOptionValueArray.push("Renew");
        startYearValueArray.push(
          new Date(renewableLamps[`${i}`]["validTo"]).toISOString()
        );
        endYearValueArray.push(
          new Date(
            new Date(renewableLamps[`${i}`]["validTo"]).setFullYear(
              new Date(renewableLamps[`${i}`]["validTo"]).getFullYear() + 1
            )
          ).toISOString()
        );
        yearValueArray.push(1);
        categoryValueArray.push(renewableLamps[`${i}`]["category"]);
        ceremonyNameArray.push(
          renewableLamps[`${i}`]["name_1"] +
            ";" +
            renewableLamps[`${i}`]["name_2"]
        );
        nameChangeValueArray.push("No");
        oldNameValueArray.push("");
      }
    }

    if (indexArray.length > 0) {
      replaceRow(numberOfLamps, indexArray);
    } else {
      addRow(numberOfLamps);
    }
  

    setSerialNumberValue(serialNumberValueArray);
    setSerialIdValue(serialIdValueArray);
    setSerialNames(serialNamesArray);
    setLightValue(lightOptionValueArray);
    setStartYearValue(startYearValueArray);
    setEndYearValue(endYearValueArray);
    setYearValue(yearValueArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);
    setNameChangeValue(nameChangeValueArray);

    callbackSerialNumber(serialNumberValueArray);
    callbackSerialId(serialIdValueArray);
    callbackSerialNames(serialNamesArray);
    callbackLightOption(lightOptionValueArray);
    callbackValidFrom(startYearValueArray);
    callbackValidTo(endYearValueArray);
    callbackNoOfYear(yearValueArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);
    callbackNameChange(nameChangeValueArray);

    _checkNamesEntered(ceremonyNameArray);
  };

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== "undefined") {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync(
        { callerContext: actionContext },
        cartState.cart.Id
      );

      if (cartItems.length > 0 && formItems[`${index}`]["cartLineId"]) {
        let removeCartLinesResult = await cartState.removeCartLines({
          cartLineIds: [formItems[`${index}`]["cartLineId"]],
        });

        if (removeCartLinesResult.status === "SUCCESS") {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync(
          { callerContext: actionContext },
          formItems[`${index}`]["cartLineId"]
        );

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }
      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  };

  const _updateStateData = (index: number) => {
    const serialNumberValueArray = serialNumberValue.slice();
    const serialIdValueArray = serialIdValue.slice();
    const serialNamesArray = serialNames.slice();
    const lightOptionValueArray = lightValue.slice();
    const startYearValueArray = startYearValue.slice();
    const endYearValueArray = endYearValue.slice();
    const yearValueArray = yearValue.slice();
    const nameChangeArray = nameChangeValue.slice();
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const oldNameArray = oldNameValue.slice();


    if (typeof serialNumberValueArray[`${index}`] !== "undefined") {
      if (serialNumberValueArray.length > 1) {
        serialNumberValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        serialNumberValueArray[0] = "";
      }
    }

    if (typeof serialIdValueArray[`${index}`] !== "undefined") {
      if (serialIdValueArray[`${index}`] !== "") {
        removeLamp(serialIdValueArray[`${index}`]);
      }
      if (serialIdValueArray.length > 1) {
        serialIdValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        serialIdValueArray[0] = "";
      }
    }

    if (typeof serialNamesArray[`${index}`] !== "undefined") {

      if (serialNamesArray.length > 1) {
        
        serialNamesArray.splice(index, 1);
      } 
      else if(!itemUnavailable){
        serialNamesArray[0] = "";
      }
    }

    if (typeof lightOptionValueArray[`${index}`] !== "undefined") {

      if (lightOptionValueArray.length > 1) {
        lightOptionValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable){
        lightOptionValueArray[0] = "New";
      }
    }

    if (typeof startYearValueArray[`${index}`] !== "undefined") {

      if (startYearValueArray.length > 1) {
        startYearValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        startYearValueArray[0] = new Date().toISOString();
      }
    }

    if (typeof endYearValueArray[`${index}`] !== "undefined") {

      if (endYearValueArray.length > 1) {
        endYearValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        endYearValueArray[0] = new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        ).toISOString();
      }
    }

    if (typeof yearValueArray[`${index}`] !== "undefined") {

      if (yearValueArray.length > 1) {
        yearValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        yearValueArray[0] = 1;
      }
    }

    if (typeof nameChangeArray[`${index}`] !== "undefined") {

      if (nameChangeArray.length > 1) {
        nameChangeArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        nameChangeArray[0] = "";
      }
    }

    if (typeof categoryValueArray[`${index}`] !== "undefined") {

      if (categoryValueArray.length > 1) {
        categoryValueArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        categoryValueArray[0] = resource.categoryFamilyLabel[`${localeCode}`];
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== "undefined") {

      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        ceremonyNameArray[0] = "";
      }
    }

    if (typeof oldNameArray[`${index}`] !== "undefined") {

      if (oldNameArray.length >= 1) {
        oldNameArray.splice(index, 1);
      } 
      else if(!itemUnavailable) {
        oldNameArray[0] = "";
      }
    }

    setSerialNumberValue(serialNumberValueArray);
    setSerialIdValue(serialIdValueArray);
    setSerialNames(serialNamesArray);
    setLightValue(lightOptionValueArray);
    setStartYearValue(startYearValueArray);
    setEndYearValue(endYearValueArray);
    setYearValue(yearValueArray);
    setNameChangeValue(nameChangeArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);
    setOldNameValue(oldNameArray);

    callbackSerialNumber(serialNumberValueArray);
    callbackSerialId(serialIdValueArray);
    callbackSerialNames(serialNamesArray);
    callbackLightOption(lightOptionValueArray);
    callbackValidFrom(startYearValueArray);
    callbackValidTo(endYearValueArray);
    callbackNoOfYear(yearValueArray);
    callbackNameChange(nameChangeArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);
    callbackOldName(oldNameArray);

    _checkNamesEntered(ceremonyNameArray);
  };

  useEffect(() => {
    const existingCeremonyName: string[] = [];
    const existingStartYearValue: string[] = [];
    const existingEndYearValue: string[] = [];
    const existingYearValue: number[] = [];
    const existingRadioValue: string[] = [];
    const existingLightOptionValue: string[] = [];
    const existingNameChangeValue: string[] = [];
    const existingOldNameValue: string[] = [];

    if (
      formItems.length > 0 &&
      typeof formItems[0]["isPastTransaction"] !== "undefined" &&
      formItems[0]["isPastTransaction"] != true
    ) {
      for (let i = 0; i < formItems.length; i++) {
        const itemNames: string[] = formItems[`${i}`]["names"];
        const itemYear = formItems[`${i}`]["quantity"];
        const itemCategory = formItems[`${i}`]["category"];
        const itemLightOption = formItems[`${i}`]["lightOption"];
        const itemValidFrom = formItems[`${i}`]["validFrom"];
        const itemValidTo = formItems[`${i}`]["validTo"];
        const itemNameChange = formItems[`${i}`]["nameChange"];
        const itemOldName_1 = formItems[`${i}`]["oldName_1"];
        const itemOldName_2 = formItems[`${i}`]["oldName_2"];

        let itemNameCount = 0;

        if (itemCategory === resource.categoryFamilyLabel[`${localeCode}`]) {
          itemNameCount = noOfNames;
        } else {
          itemNameCount = companyNames;
        }

        let itemName = "";

        itemNames.map((name, index) => {
          if (index != 0 && index < itemNameCount) {
            itemName += ";" + name;
          } else {
            itemName += name;
          }
        });

        existingCeremonyName.push(itemName);

        if (itemValidFrom) {
          existingStartYearValue.push(new Date(itemValidFrom).toISOString());
        } else {
          existingStartYearValue.push(new Date().toISOString());
        }

        if (itemValidTo) {
          existingEndYearValue.push(new Date(itemValidTo).toISOString());
        } else {
          existingEndYearValue.push(
            new Date(
              new Date().setFullYear(new Date().getFullYear() + itemYear)
            ).toISOString()
          );
        }

        existingYearValue.push(itemYear);
        existingRadioValue.push(itemCategory);
        existingLightOptionValue.push(
          itemLightOption !== "" ? itemLightOption : "New"
        );
        existingNameChangeValue.push(itemNameChange);

        if (itemOldName_1 !== "") {
          let oldName = itemOldName_1 + ";" + itemOldName_2;
          existingOldNameValue.push(oldName);
        }
      }

      setCeremonyName(existingCeremonyName);
      setStartYearValue(existingStartYearValue);
      setEndYearValue(existingEndYearValue);
      setYearValue(existingYearValue);
      setRadioValue(existingRadioValue);
      setLightValue(existingLightOptionValue);
      setNameChangeValue(existingNameChangeValue);
      setOldNameValue(existingOldNameValue);

      callbackName(existingCeremonyName);
      callbackValidFrom(existingStartYearValue);
      callbackValidTo(existingEndYearValue);
      callbackNoOfYear(existingYearValue);
      callbackCategory(existingRadioValue);
      callbackLightOption(existingLightOptionValue);
      callbackNameChange(existingNameChangeValue);
      callbackOldName(existingOldNameValue);

      _checkNamesEntered(existingCeremonyName);
    } else {
      callbackCategory(radioValue);
      callbackLightOption(lightValue);
      callbackValidFrom(startYearValue);
      callbackValidTo(endYearValue);
    }
    if (addRenewLamp == true) {
      _addItemRenewSerial();
    }
  }, [formItems, addRenewLamp]);

  const _buildNoOfYearsField = (idx: number): JSX.Element => {
    return (
      <div className="row">
        <div className="col-12">
          <p className="form-label">{formLabels.years}</p>
          <div className="form-field row">
            <label className="radio-option col-12 col-lg-3">
              <input
                type="radio"
                value={1}
                checked={yearValue[`${idx}`] == 1}
                aria-checked={yearValue[`${idx}`] == 1}
                onChange={(event) => _onChangeYearValue(event, idx)}
              />
              {formLabels.oneYearTitle}
            </label>

            <label className="radio-option col-12 col-lg-3">
              <input
                type="radio"
                value={2}
                checked={yearValue[`${idx}`] == 2}
                aria-checked={yearValue[`${idx}`] == 2}
                onChange={(event) => _onChangeYearValue(event, idx)}
              />
              {formLabels.twoYearTitle}
            </label>
          </div>
        </div>
      </div>
    );
  };

  const _buildNameChangeField = (idx: number): JSX.Element => {
    return (
      <div className="row">
        <div className="col-12">
          <p className="form-label">{formLabels.nameChangePrompt}</p>
          <div className="form-field row">
            <label className="radio-option col-12 col-lg-4">
              <input
                type="radio"
                value={"No"}
                checked={
                  nameChangeValue[`${idx}`] === "" ||
                  nameChangeValue[`${idx}`] === "No"
                }
                aria-checked={
                  nameChangeValue[`${idx}`] === "" ||
                  nameChangeValue[`${idx}`] === "No"
                }
                onChange={(event) => _onChangeNameChangeValue(event, idx)}
              />
              {formLabels.keepNameTitle}
            </label>

            <label className="radio-option col-12 col-lg-4">
              <input
                type="radio"
                value={"Yes"}
                checked={nameChangeValue[`${idx}`] === "Yes"}
                aria-checked={nameChangeValue[`${idx}`] === "Yes"}
                onChange={(event) => _onChangeNameChangeValue(event, idx)}
              />
              {formLabels.changeNameTitle}
            </label>
          </div>
        </div>
      </div>
    );
  };

  const _buildSponsorshipPeriodField = (idx: number): JSX.Element => {
    return (
      <div className="row">
        <div className="col-12">
          <p className="form-label">{formLabels.period}</p>
          <p className="form-label">
            {moment(startYearValue[`${idx}`]).format("DD MMM YYYY")} -{" "}
            {moment(endYearValue[`${idx}`]).format("DD MMM YYYY")}
          </p>
        </div>
      </div>
    );
  };

  const _buildNameGroup = (idx: number, noOfNames: number): JSX.Element[] => {
    const nameLabel = formLabels.name;
    const namesArray = Array.apply(null, Array(noOfNames)).map(function(x, i) {
      return i;
    });

    if (
      lightValue[`${idx}`] === "New" ||
      (lightValue[`${idx}`] === "Renew" && nameChangeValue[`${idx}`] === "Yes")
    ) {
      return namesArray.map((nameIndex) => {
        return (
          <div className="row">
            <div className="col-lg-6 form-field">
              <p className="form-label">
                {nameLabel.endsWith("*")
                  ? nameIndex === 0
                    ? nameLabel.substring(0, nameLabel.length - 1) +
                      " " +
                      (nameIndex + 1) +
                      "*"
                    : nameLabel.replace("*", " " + (nameIndex + 1))
                  : nameLabel + " " + nameIndex + 1}
              </p>
              <input
                type="text"
                inputMode="text"
                value={_displayName(ceremonyName[`${idx}`], nameIndex)}
                aria-live="polite"
                role="spinbutton"
                onChange={(event) =>
                  _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)
                }
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                  }
                }}
                className="form-control"
                maxLength={
                  config.charactersLimitForNames
                    ? config.charactersLimitForNames
                    : 31
                }
              />
            </div>
          </div>
        );
      });
    } else {
      return namesArray.map((nameIndex) => {
        return (
          <div className="row">
            <div className="col-lg-6">
              <p className="form-label">
                {nameLabel.endsWith("*")
                  ? nameIndex === 0
                    ? nameLabel.substring(0, nameLabel.length - 1) +
                      " " +
                      (nameIndex + 1) +
                      "*"
                    : nameLabel.replace("*", " " + (nameIndex + 1))
                  : nameLabel + " " + nameIndex + 1}
              </p>
              <p className="form-field-value" style={{ color: "white" }}>
                {_displayName(ceremonyName[`${idx}`], nameIndex)}
              </p>
            </div>
          </div>
        );
      });
    }
  };

  const _buildCategoryField = (idx: number): JSX.Element | null => {
    if (typeof radioValue[`${idx}`] === "undefined") {
      return null;
    }

    return (
      <div className="row">
        <div className="col-12">
          <p className="form-label">{formLabels.category}</p>
          <div className="form-field row">
            <label className="radio-option col-12 col-lg-3">
              <input
                type="radio"
                value={resource.categoryFamilyLabel[`${localeCode}`]}
                checked={
                  radioValue[`${idx}`] ===
                  String(resource.categoryFamilyLabel[`${localeCode}`])
                }
                aria-checked={
                  radioValue[`${idx}`] ===
                  String(resource.categoryFamilyLabel[`${localeCode}`])
                }
                onChange={(event) => _onChangeRadioValue(event, idx)}
              />
              {resource.categoryFamilyLabel[`${localeCode}`]}
            </label>

            <label className="radio-option col-12 col-lg-3">
              <input
                type="radio"
                value={resource.categoryCompanyLabel[`${localeCode}`]}
                checked={
                  radioValue[`${idx}`] ===
                  String(resource.categoryCompanyLabel[`${localeCode}`])
                }
                aria-checked={
                  radioValue[`${idx}`] ===
                  String(resource.categoryCompanyLabel[`${localeCode}`])
                }
                onChange={(event) => _onChangeRadioValue(event, idx)}
              />
              {resource.categoryCompanyLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="ecomm-purchase-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
        autoComplete="off"
      >
        <div className="ecomm-purchase-form-table">
          {rowsData.map((i, idx) => {
            return (
              <div className="form-record">
                <div key={`row${i.id}`} className="form-details row">
                  {formScheme.map((element: IField, index: number) => {
                    if (element.field_name === "noOfYears") {
                      return (
                        <div className="form-details-item col-lg-9">
                          {lightValue[`${idx}`] === "Renew" && (
                            <div className="row">
                              <h4>
                                {formLabels.renewalTitle +
                                  serialNumberValue[`${idx}`]}
                              </h4>
                            </div>
                          )}
                          {_buildNoOfYearsField(idx)}
                          {_buildSponsorshipPeriodField(idx)}
                          {lightValue[`${idx}`] === "Renew" &&
                            _buildNameChangeField(idx)}
                          {_buildCategoryField(idx)}
                          {radioValue[`${idx}`] ===
                            String(
                              resource.categoryFamilyLabel[`${localeCode}`]
                            ) &&
                            noOfNames > 0 &&
                            _buildNameGroup(idx, noOfNames)}
                          {radioValue[`${idx}`] ===
                            String(
                              resource.categoryCompanyLabel[`${localeCode}`]
                            ) &&
                            companyNames > 0 &&
                            _buildNameGroup(idx, companyNames)}
                        </div>
                      );
                    }
                    if (!isMobile && element.field_type === "product_price") {
                      return (
                        <div className="form-details-item bordered centred col-2">
                          <p key={index} className="subtotal">
                            $
                            {selectedProduct.result?.Price! *
                              yearValue[`${idx}`] }
                          </p>
                        </div>
                      );
                    }
                    if (element.field_type === "action") {
                      if (isMobile) {
                        const style = !isEditMode
                          ? "form-details-item"
                          : "form-details-item edit";

                        return (
                          <div className={style}>
                            {!isEditMode && !itemUnavailable && allowMultipleRegistrations && (
                              <div className="col-10">
                                <button
                                  className="add-row"
                                  type="submit"
                                  onClick={_addItem}
                                >
                                  {
                                    resource.addAnotherItemTitle[
                                      `${localeCode}`
                                    ]
                                  }
                                </button>
                              </div>
                            )}
                            <div className="delete-wrapper col-2">
                              <button
                                className={element.action}
                                onClick={() => _deleteCartLine(idx)}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div className="form-details-item centred col-sm-1">
                          <button
                            className={element.action}
                            onClick={() => _deleteCartLine(idx)}
                          />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && !itemUnavailable && allowMultipleRegistrations && (
          <div className="ecomm-purchase-form-add">
            <button type="submit" onClick={_addItem}>
              {resource.addAnotherItemTitle[`${localeCode}`]}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default NoOfYearsCategoryNamesForm;
