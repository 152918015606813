import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackPrice?: any;
  callbackName?: any;
  callbackNamesEntered?: any;
  callbackPricesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
}

const DonationForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackPrice, callbackName, callbackNamesEntered, callbackPricesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, allowMultipleRegistrations }) => {
  var minDonationNumberOption = Number(formScheme[1].field_options![0]);
  var minDonationstringOption = formScheme[1].field_options![0];
  const [customPrice, setCustomPrice] = useState([minDonationNumberOption]);
  const [inputValue, setInputValue] = useState(['']);
  const [donationName, setDonationName] = useState(['']);
  const [donationAmount, setDonationAmount] = useState([minDonationstringOption]);

  const formLabels = resource.donationForm[`${localeCode}`];

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();

  const _onChangePriceValue = (event: any, index: number) => {
    _setRadioValueArray(event.target.value, index);

    if (event.target.value === resource.otherLabel[`${localeCode}`]) {
      _setInputValueArray('0', index);
      _setCustomPriceArray(0, index);
    } else {
      _setCustomPriceArray(Number(event.target.value), index);
      _setInputValueArray('', index);
    }
  };

  const _setCustomPriceArray = (price: number, index: number) => {
    const customPriceArray = customPrice.slice();

    while (typeof customPriceArray[`${index}`] === 'undefined') {
      customPriceArray.push(0);
    }

    customPriceArray[`${index}`] = price;

    setCustomPrice(customPriceArray);

    callbackPrice(customPriceArray);

    _checkPricesEntered(customPriceArray);
  };

  const _setRadioValueArray = (value: string, index: number) => {
    const donationValueArray = donationAmount.slice();

    while (typeof donationValueArray[`${index}`] === 'undefined') {
      donationValueArray.push('');
    }

    donationValueArray[`${index}`] = value;

    setDonationAmount(donationValueArray);
  };

  const _setInputValue = (event: any, index: number) => {
    if (isNumeric(event.target.value)) {
      _setInputValueArray(event.target.value, index);
      _setRadioValueArray(resource.otherLabel[`${localeCode}`], index);
      _setCustomPriceArray(Number(event.target.value), index);
    }
  };

  const _setInputValueArray = (value: string, index: number) => {
    const inputValueArray = inputValue.slice();

    while (typeof inputValueArray[`${index}`] === 'undefined') {
      inputValueArray.push('');
    }

    inputValueArray[`${index}`] = value;

    inputValueArray[`${index}`].trim();

    setInputValue(inputValueArray);
  };

  const _setNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setDonationNameArray(event.target.value.substring(0, maxLength - 1), index);
  };

  const _setDonationNameArray = (name: string, index: number) => {
    const donationNameArray = donationName.slice();

    while (typeof donationNameArray[`${index}`] === 'undefined') {
      donationNameArray.push('');
    }

    donationNameArray[`${index}`] = name;

    donationNameArray[`${index}`].trim();

    setDonationName(donationNameArray);

    callbackName(donationNameArray);

    _checkNamesEntered(donationNameArray);
  };

  const isNumeric = (num: any): boolean => { return !isNaN(num); };

  const _checkNamesEntered = (donationNameArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < donationNameArray.length; i++) {
      if (donationNameArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkPricesEntered = (customPriceArray: number[]) => {
    let pricesEntered = true;

    for (let i = 0; i < customPriceArray.length; i++) {
      if (customPriceArray[`${i}`] == 0) {
        pricesEntered = false;
        break;
      }
    }

    callbackPricesEntered(pricesEntered);
  }

  const _addItem = () => {
    addRow(1);

    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const donationValueArray = donationAmount.slice();
    const donationNameArray = donationName.slice();

    customPriceArray.push(parseInt(element!.field_options![0]));
    donationNameArray.push('');
    donationValueArray.push(element!.field_options![0]);

    setCustomPrice(customPriceArray);
    setDonationName(donationNameArray);
    setDonationAmount(donationValueArray);

    callbackPrice(customPriceArray);
    callbackName(donationNameArray);

    callbackNamesEntered(false);
    callbackPricesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const inputValueArray = inputValue.slice();
    const donationNameArray = donationName.slice();
    const donationValueArray = donationAmount.slice();

    if (typeof customPriceArray[`${index}`] !== 'undefined') {
      if (customPriceArray.length > 1) {
        customPriceArray.splice(index, 1);
      } else {
        customPriceArray[0] = parseInt(element!.field_options![0]);
      }
    }

    if (typeof inputValueArray[`${index}`] !== 'undefined') {
      if (inputValueArray.length > 1) {
        inputValueArray.splice(index, 1);
      } else {
        inputValueArray[0] = '';
      }
    }

    if (typeof donationNameArray[`${index}`] !== 'undefined') {
      if (donationNameArray.length > 1) {
        donationNameArray.splice(index, 1);
      } else {
        donationNameArray[0] = '';
      }
    }

    if (typeof donationValueArray[`${index}`] !== 'undefined') {
      if (donationValueArray.length > 1) {
        donationValueArray.splice(index, 1);
      } else {
        donationValueArray[0] = element!.field_options![0];
      }
    }

    setCustomPrice(customPriceArray);
    setInputValue(inputValueArray);
    setDonationName(donationNameArray);
    setDonationAmount(donationValueArray);

    callbackName(donationNameArray);
    callbackPrice(customPriceArray);

    _checkNamesEntered(donationNameArray);
    _checkPricesEntered(customPriceArray);
  }

  useEffect(() => {
    const element = formScheme.find(element => element.field_name === 'amount');

    var minDonationvalue = Number(element?.field_options![0]);
    var minDonationString = String(element?.field_options![0]);

    const existingCustomPriceAmount: number[] = [];
    const existingInputValue: string[] = [];
    const existingDonationName: string[] = [];
    const existingDonationAmount: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        var customPriceAmount: number = formItems[`${i}`]['customPriceAmount'];

        switch (customPriceAmount) {
          case 0:
            customPriceAmount = Number(minDonationvalue);
            existingDonationAmount.push(minDonationString);
            existingInputValue.push('');
            break;

          case 2:
            existingDonationAmount.push('2');
            existingInputValue.push('');
            break;

          case 5:
            existingDonationAmount.push('5');
            existingInputValue.push('');
            break;

          case 10:
            existingDonationAmount.push('10');
            existingInputValue.push('');
            break;

          case 50:
            existingDonationAmount.push('50');
            existingInputValue.push('');
            break;

          default:
            if (customPriceAmount) {
              existingDonationAmount.push(resource.otherLabel[`${localeCode}`]);
              existingInputValue.push(customPriceAmount.toString());
            } else {
              customPriceAmount = Number(minDonationvalue);
              existingDonationAmount.push(minDonationString);
              existingInputValue.push('');
            }
            break;
        }

        existingDonationName.push(formItems[`${i}`]['names'][0]);
        existingCustomPriceAmount.push(customPriceAmount);
      }


      setDonationName(existingDonationName);

      callbackName(existingDonationName);

      _checkNamesEntered(existingDonationName);
    } else {
      existingDonationAmount.push(minDonationString);
      existingCustomPriceAmount.push(minDonationvalue);
      existingInputValue.push('');
    }

    setDonationAmount(existingDonationAmount);
    setCustomPrice(existingCustomPriceAmount);
    setInputValue(existingInputValue);

    callbackPrice(existingCustomPriceAmount);

    _checkPricesEntered(existingCustomPriceAmount);
  }, [formItems])

  const _buildName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.name}</p>
          <input
            type='text'
            value={donationName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildAmountField = (element: IField, idx: number): JSX.Element | null => {
    if (element.field_options) {
      return (
        <div className='row'>
          <div className='col-12 form-field'>
            <p className='form-label'>{formLabels.amount}</p>
            {element.field_options.map((option: string) => {
              return (
                <label className='radio-option'>
                  <input
                    type='radio'
                    value={option}
                    checked={donationAmount[`${idx}`] === String(option)}
                    aria-checked={donationAmount[`${idx}`] === String(option)}
                    onChange={(event) => _onChangePriceValue(event, idx)}
                  />

                  {option === resource.otherLabel[`${localeCode}`] ? '' : '$'}{option}

                  {option !== resource.otherLabel[`${localeCode}`] ? null :
                    <input
                      type='text'
                      value={inputValue[`${idx}`]}
                      inputMode='numeric'
                      pattern='[0-9]*'
                      aria-live='polite'
                      role='spinbutton'
                      aria-valuemin={10}
                      aria-valuemax={10000}
                      aria-valuenow={Number(inputValue[`${idx}`])}
                      onChange={(event: any) => _setInputValue(event, idx)}
                      onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                      onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
                      className='form-control form-control-other'
                    />}
                </label>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return <div className='form-record'>
              <div key={`row${i.id}`} className='form-details row'>
                {formScheme.map((element: IField, index: number) => {

                  if (element.field_name === 'amount') {
                    return (
                      <div className='form-details-item col-lg-9'>
                        {_buildName(idx)}
                        {_buildAmountField(element, idx)}
                      </div>
                    );
                  }

                  if (!isMobile && element.field_type === 'product_price') {
                    return (
                      <div className='form-details-item bordered centred col-2'>
                        <p key={index} className='subtotal'>
                          ${selectedProduct.result?.Price! === 0 ? typeof customPrice[`${idx}`] !== 'undefined' ? customPrice[`${idx}`] : '0' : selectedProduct.result?.Price!}
                        </p>
                      </div>
                    );
                  }

                  if (element.field_type === 'action') {
                    if (isMobile) {
                      const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                      return (
                        <div className={style}>
                          {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                            <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                          </div>}
                          <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                        </div>
                      );
                    }

                    return (
                      <div className='form-details-item centred col-1'>
                        <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                      </div>
                    );
                  }

                  return;
                })}
              </div>
            </div>;
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default DonationForm;