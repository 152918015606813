import classnames from 'classnames';

import React, { useState } from 'react';

import { v4 as uuidv4, validate as uuidValidate, version as uuidVersion } from 'uuid';

import { getPayloadObject, getTelemetryAttributes, IPopupProps, ITelemetryContent, Popup } from '@msdyn365-commerce-modules/utilities';

import { IComponent, IComponentProps, IGridSettings, IImageSettings, msdyn365Commerce, TelemetryEvent } from '@msdyn365-commerce/core';

import { getCartState, ICartState, ICartActionResult } from '@msdyn365-commerce/global-state';

import { CartLine, ProductAvailableQuantity, ProductDimension, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';
import { FormDetails,CartItem } from '../../../actions/ecomm-interfaces';
import { createOrUpdateCartline, generateErrorLog } from '../../../actions/helper-functions';

import { ProductDetailsPriceComponent } from './ecomm-form-price-component';

export interface IProductDetailsAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct; price?: ProductPrice, cart: ICartState }> {
  className?: string;
  addToCartText: string;
  outOfStockText: string;
  disabled?: boolean;
  quantity?: number;
  navigationUrl?: string;
  productAvailability?: ProductAvailableQuantity;
  getSelectedProduct?: SimpleProduct;

  imageSettings: IImageSettings;
  gridSettings: IGridSettings;

  isLoading?: boolean;
  isUpdatingDimension?: boolean;
  isLoadingDeliveryOptions?: boolean;
  isUpdatingDeliveryOptions?: boolean;
  isAddServiceItemToCart?: boolean;
  isAddEmailDeliveryItemToCart?: boolean;
  isPriceKeyedIn?: boolean;
  isOrderQuantityLimitsFeatureEnabled?: boolean;
  customPriceAmount?: number[];

  matchingCartItems?: [];

  events?: string[];
  eventIDs: string[];

  serialIds: string[];
  serialNumbers: string[];
  serialNames: string[];
  
  names: string[];
  dedicatedBy: string[];
  
  categories: string[];
  messages?: string[];
  remarks?: string[];
  
  hasTaxDeductions: boolean[];
  nrics: string[];
  
  buildingNames: string[];
  unitNumbers: string[];
  postalCodes: string[];
  phoneNumbers: number[];
  chineseNames: string[];
  countries: string[];

  nameNextKins: string[];
  contactNumbers: string[]
  contactNumbersNextKin: string[];
  passportNumbers: string[];
  passportExpiryDates: string[];
  passportIssuedDates: string[];
  consents: number[];
  isInsuranceIncluded: number[];

  birthDates: string[];
  birthTimes: string[];

  deathDates: string[];
  deathTimes: string[];

  surgeryDates: string[];
  
  schoolNames: string[];
  companyNames: string[];
  
  blessingOptions: string[];
  collectionOptions: string[];
  lightOptions: string[];
  
  validFroms: string[];
  validTos: string[];
  
  dedicationPeriods: string[];
  startDates: string[];

  genders: string[];

  deceasedRelationships: string[];
  
  nameChanges: string[];
  oldNames: string[];
  
  quantities: number[];
  
  subProduct: SimpleProduct;
  subProductQuantities: number[];

  dialogStrings?: {
    goToCartText: string;
    continueShoppingText: string;
    headerItemOneText: string;
    headerItemFormatText: string;
    headerMessageText: string;
    freePriceText: string;
    originalPriceText: string;
    currentPriceText: string;
  };

  telemetryContent?: ITelemetryContent;

  isCustomPriceSelected?: boolean;
  maximumKeyInPrice?: number;
  minimumKeyInPrice?: number;
  defaultMaximumKeyInPrice?: number;
  defaultMinimumKeyInPrice?: number;

  onAdd?(): void;
  onError?(result: IProductDetailsAddToCartFailureResult): void;
  changeUpdatingDimension?(isUpdatingDimension: boolean): void;
  changeUpdatingDeliveryOptions?(isUpdatingDeliveryOptions: boolean): void;
}

export declare type IProductDetailsCartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED' | 'INVALIDCUSTOMAMOUNT';

export interface IProductDetailsAddToCartFailureResult {
    failureReason: IProductDetailsCartActionFailureReason;
    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IProductDetailsAddtoCartComponent extends IComponent<IProductDetailsAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IProductDetailsAddToCartComponentProps) => void;
}

const onClick = async (_event: React.MouseEvent<HTMLElement>, props: IProductDetailsAddToCartComponentProps, setDisabled: (disabled: boolean) => void, openModal: (opened: boolean) => void): Promise<void> => {
  const cartError = addToCartError(props);

  let productToAdd = props.data.product;

  if (cartError) {
    propagateError(props, cartError);
    
    return;
  }

  setDisabled(true);

  if (!(props.getSelectedProduct === undefined)) {
    productToAdd = (await props.getSelectedProduct) || props.data.product;
  }

  const cartState = await getCartState(props.context.actionContext);

  if (props.matchingCartItems && props.matchingCartItems.length > 0) {
    const matchingCartLineIds: string[] = [];

    props.matchingCartItems.map((value: string, index: number) => {
      if (value['cartLineId'] !== '') {
        matchingCartLineIds.push(value['cartLineId']);
      }
    });

    if (matchingCartLineIds.length > 0) {
      const cartLinesResult = await getAllBTGPCartLineAsync({ callerContext: props.context.actionContext }, cartState.cart.Id);
      
      const cartLineData: any[] = [];

      if (cartLinesResult.length > 0) {
        cartLinesResult.forEach(record => cartLineData.push(JSON.parse(record)));
      }

      const cartIdToRemove: string[] = [matchingCartLineIds[0]];

      cartLineData.forEach(cl => {
        if (cl['ParentCartLineID'] === cartIdToRemove[0]) {
          cartIdToRemove.push(cl['CartLineID']);
        }
      });

      const removeCartResult = await cartState.removeCartLines({ cartLineIds: cartIdToRemove });

      if (removeCartResult.status !== 'SUCCESS') {
        const errorMessageId = removeCartResult.errorDetails ? removeCartResult.errorDetails.ErrorResourceId ? removeCartResult.errorDetails.ErrorResourceId : '' : '';
        const errorMessageDetail = removeCartResult.errorDetails ? removeCartResult.errorDetails.LocalizedMessage ? removeCartResult.errorDetails.LocalizedMessage : '' : '';

        const item: FormDetails = {
          CartLineID: '', CartID: cartState.cart.Id, CartLineComment: '', ParentCartLineID: '',
          ProductID: '', EventID: '', EventName: '',
          SerialID: '', SerialNumber: '', SerialName: '',
          Name_1: '', Name_2: '', Name_3: '', Name_4: '', Name_5: '', Name_6: '', Name_7: '', Name_8: '', Name_9: '', Name_10: '', 
          Name_11: '', Name_12: '', Name_13: '', Name_14: '', Name_15: '', Name_16: '', Name_17: '', Name_18: '', Name_19: '', Name_20: '',
          Remarks: '', Message: '',
          Category: '', DedicatedBy: '',
          HasTaxDeduction: false, Nric: '',
          BuildingName: '', UnitNumber: '', PostalCode: '', PhoneNumber: '', ChineseName:'', Country: '',
          SchoolName: '', CompanyName: '',
          DonationOption: '', BlessingOption: '', LightOption: '',
          ValidFrom: '', ValidTo: '',
          DedicationPeriod: '',
          StartDate: '',
          Gender: '',
          SurgeryDate: '',
          BirthDate: '', BirthTime: '',
          BirthDate_2: '', BirthTime_2: '',
          DeathDate: '', DeathTime: '',
          DeceasedRelationship: '',
          NameChange: '', 
          OldName_1: '', OldName_2: '',
          NameNextKin:'',
          ContactNumber:'',
          ContactNumberNextKin:'',
          PassportNumber:'',
          PassportExpiryDate:'',
          PassportIssuedDate:'',
          Consent:0,
          IsInsuranceIncluded:0
        };

        await generateErrorLog(props.context, 'ERROR ecomm-form-add-to-cart-source cartState.removeCartLines', errorMessageId + ': ' + errorMessageDetail, item);

        propagateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: removeCartResult });

        setDisabled(false);
      }

      for (let i = 0; i < matchingCartLineIds.length; i++) {
        const result = await deleteBTGPCartLineAsync({ callerContext: props.context.actionContext }, matchingCartLineIds[`${i}`]);

        if (!result) {
          const found = cartLinesResult.find(record => {
            const item = JSON.parse(record);

            return item['CartLineID'] === matchingCartLineIds[`${i}`];
          });

          if (found) {
            const parsed = JSON.parse(found);

            const item: FormDetails = {
              CartLineID: parsed['CartLineID'],
              CartID: parsed['CartID'],
              CartLineComment: parsed['CartLineComment'],
              ParentCartLineID: parsed['ParentCartLineID'],
              ProductID: parsed['ProductID'],
              EventID: parsed['EventID'],
              EventName: parsed['EventName'],
              SerialID: parsed['SerialID'],
              SerialNumber: parsed['SerialNumber'],
              SerialName: parsed['SerialName'],
              Name_1: parsed['Name_1'],
              Name_2: parsed['Name_2'],
              Name_3: parsed['Name_3'],
              Name_4: parsed['Name_4'],
              Name_5: parsed['Name_5'],
              Name_6: parsed['Name_6'],
              Name_7: parsed['Name_7'],
              Name_8: parsed['Name_8'],
              Name_9: parsed['Name_9'],
              Name_10: parsed['Name_10'], 
              Name_11: parsed['Name_11'],
              Name_12: parsed['Name_12'],
              Name_13: parsed['Name_13'],
              Name_14: parsed['Name_14'],
              Name_15: parsed['Name_15'],
              Name_16: parsed['Name_16'],
              Name_17: parsed['Name_17'],
              Name_18: parsed['Name_18'],
              Name_19: parsed['Name_19'],
              Name_20: parsed['Name_20'],
              Remarks: parsed['Remarks'],
              Message: parsed['Message'],
              Category: parsed['Category'],
              DedicatedBy: parsed['DedicatedBy'],
              HasTaxDeduction: parsed['HasTaxDeduction'],
              Nric: parsed['Nric'],
              BuildingName: parsed['BuildingName'],
              UnitNumber: parsed['UnitNumber'],
              PostalCode: parsed['PostalCode'],
              PhoneNumber: parsed['PhoneNumber'],
              ChineseName: parsed['ChineseName'],
              Country: parsed['Country'],
              SchoolName: parsed['SchoolName'],
              CompanyName: parsed['CompanyName'],
              DonationOption: parsed['DonationOption'],
              BlessingOption: parsed['BlessingOption'],
              LightOption: parsed['LightOption'],
              ValidFrom: parsed['ValidFrom'],
              ValidTo: parsed['ValidTo'],
              DedicationPeriod: parsed['DedicationPeriod'],
              StartDate: parsed['StartDate'],
              Gender: parsed['Gender'],
              SurgeryDate: parsed['SurgeryDate'],
              BirthDate: parsed['BirthDate'],
              BirthTime: parsed['BirthTime'],
              BirthDate_2: parsed['BirthDate_2'],
              BirthTime_2: parsed['BirthTime_2'],
              DeathDate: parsed['DeathDate'],
              DeathTime: parsed['DeathTime'],
              DeceasedRelationship: parsed['DeceasedRelationship'],
              NameChange: parsed['NameChange'],
              OldName_1: parsed['OldName_1'],
              OldName_2: parsed['OldName_2'],
              NameNextKin: parsed['NameNextKin'],
              ContactNumber: parsed['ContactNumber'],
              ContactNumberNextKin: parsed['ContactNumberNextKin'],
              PassportNumber: parsed["PassportNumber"],
              PassportExpiryDate: parsed["PassportExpiryDate"],
              PassportIssuedDate: parsed["PassportIssuedDate"],
              Consent: parsed["Consent"],
              IsInsuranceIncluded: parsed["IsInsuranceIncluded"]
            };

            await generateErrorLog(props.context, 'ERROR ecomm-form-add-to-cart-source deleteBTGPCartLineAsync', '', item);
          }
        }
      }
    }
  }

  // ATTENTION: CORE LOGIC BEGINS HERE.
  for (let i = 0; i < props.names!.length; i++) {
    const cartEvent = typeof props.events![`${i}`] !== 'undefined' && props.events![`${i}`] !== '' ? props.events![`${i}`] : '';
    const cartEventArray = strSplit(cartEvent, ';');

    const cartEventID = typeof props.eventIDs![`${i}`] !== 'undefined' && props.eventIDs[`${i}`] !== '' ? props.eventIDs[`${i}`] : '';
    const cartEventIDArray = strSplit(cartEventID, ';');

    const cartSerialId = typeof props.serialIds![`${i}`] !== 'undefined' && props.serialIds[`${i}`] !== '' ? props.serialIds[`${i}`] : '';
    const cartSerialNumber = typeof props.serialNumbers![`${i}`] !== 'undefined' && props.serialNumbers[`${i}`] !== '' ? props.serialNumbers[`${i}`] : '';
    const cartSerialName = typeof props.serialNames![`${i}`] !== 'undefined' && props.serialNames[`${i}`] !== '' ? props.serialNames![`${i}`] : ''; 

    const cartName = typeof props.names![`${i}`] !== 'undefined' ? props.names![`${i}`] + '' : '';
    const cartNameArray = strSplit(cartName, ';');

    for (let r = cartNameArray.length - 1; r < 20; r++) {
      cartNameArray.push('');
    }

    const cartMessage = typeof props.messages![`${i}`] !== 'undefined' ? props.messages![`${i}`] + ';' : ';';
    const cartMessageTemp = cartMessage.substring(0, cartMessage.length - 1);
    const cartRemark = typeof props.remarks![`${i}`] !== 'undefined' ? props.remarks![`${i}`] : '';

    const cartCategory = typeof props.categories[`${i}`] !== 'undefined' ? props.categories![`${i}`] : '';

    const cartDedicatedBy = typeof props.dedicatedBy[`${i}`] !== 'undefined' ? props.dedicatedBy[`${i}`] : '';

    const cartHasTaxDeduction = typeof props.hasTaxDeductions[`${i}`] !== 'undefined' ? props.hasTaxDeductions[`${i}`] : false;
    const cartNric = typeof props.nrics[`${i}`] !== 'undefined' ? props.nrics[`${i}`] : '';

    const cartBuildingName = typeof props.buildingNames[`${i}`] !== 'undefined' ? props.buildingNames![`${i}`] : '';
    const cartUnitNumber = typeof props.unitNumbers[`${i}`] !== 'undefined' ? props.unitNumbers![`${i}`] : '';
    const cartPostalCode = typeof props.postalCodes[`${i}`] !== 'undefined' ? props.postalCodes![`${i}`] : '';
    const cartPhoneNumber = typeof props.phoneNumbers[`${i}`] !== 'undefined' ? String(props.phoneNumbers![`${i}`]) : '';
    const cartChineseName = typeof props.chineseNames[`${i}`] !== 'undefined' ? String(props.chineseNames![`${i}`]) : '';
    const cartNameNextKin = typeof props.nameNextKins[`${i}`] !== 'undefined' ? String(props.nameNextKins![`${i}`]) : '';
    const cartContactNumber = typeof props.contactNumbers[`${i}`] !== 'undefined' ? String(props.contactNumbers![`${i}`]) : '';
    const cartContactNumbersNextKin = typeof props.contactNumbersNextKin[`${i}`] !== 'undefined' ? String(props.contactNumbersNextKin![`${i}`]) : '';

    const cartPassportNumber = typeof props.passportNumbers[`${i}`] !== 'undefined' ? String(props.passportNumbers![`${i}`]) : '';

    const cartPassportExpiryDate = typeof props.passportExpiryDates[`${i}`] === 'undefined' || props.passportExpiryDates![`${i}`] === '' ? new Date(1900, 1, 1).toISOString() : props.passportExpiryDates![`${i}`];

    const cartPassportIssuedDate = typeof props.passportIssuedDates[`${i}`] === 'undefined' || props.passportIssuedDates![`${i}`] === '' ? new Date(1900, 1, 1).toISOString() : props.passportIssuedDates![`${i}`];

    const cartConsent = typeof props.consents[`${i}`] !== 'undefined' ? Number(props.consents![`${i}`]) : 0;

    const cartIsInsuranceIncluded = typeof props.isInsuranceIncluded[`${i}`] !== 'undefined' ? Number(props.isInsuranceIncluded![`${i}`]) : 0;


    const cartCountry = typeof props.countries[`${i}`] !== 'undefined' ? props.countries![`${i}`] : '';

    const cartSchoolName = typeof props.schoolNames[`${i}`] !== 'undefined' ? props.schoolNames![`${i}`] : '';

    const cartBirthDate = typeof props.birthDates[`${i}`] === 'undefined' || props.birthDates![`${i}`] === '' ? new Date(1900, 1, 1).toISOString() : props.birthDates![`${i}`];

    const cartBirthDates = typeof props.birthDates[`${i}`] !== 'undefined' ? props.birthDates[`${i}`].split(';') : [];
    const cartBirthTimes = typeof props.birthTimes[`${i}`] !== 'undefined' ? props.birthTimes[`${i}`].split(';') : [];

    const cartCollectionOption = typeof props.collectionOptions[`${i}`] !== 'undefined' ? props.collectionOptions![`${i}`] : '';
    const cartBlessingOption = typeof props.blessingOptions[`${i}`] !== 'undefined' ? props.blessingOptions![`${i}`] : '';
    const cartLightOption = typeof props.lightOptions[`${i}`] !== 'undefined' ? props.lightOptions![`${i}`] : '';

    const cartValidFrom = typeof props.validFroms[`${i}`] !== 'undefined' ? props.validFroms![`${i}`] !== '' ? props.validFroms![`${i}`] : new Date().toISOString() : new Date().toISOString();
    const cartValidTo = typeof props.validTos[`${i}`] !== 'undefined' ? props.validTos![`${i}`] !== '' ? props.validTos![`${i}`] : new Date().toISOString() : new Date().toISOString();

    const cartDedicationPeriod = typeof props.dedicationPeriods[`${i}`] !== 'undefined' ? props.dedicationPeriods![`${i}`] : '';
    const cartStartDate = typeof props.startDates[`${i}`] === 'undefined' || props.startDates![`${i}`] === '' ? new Date(1900, 1, 1).toISOString() : props.startDates![`${i}`];

    const cartGender = typeof props.genders[`${i}`] !== 'undefined' ? props.genders[`${i}`] : '';

    const cartCompanyName = typeof props.companyNames[`${i}`] !== 'undefined' ? props.companyNames![`${i}`] : '';

    const cartDeceasedRelationship = typeof props.deceasedRelationships[`${i}`] !== 'undefined' ? props.deceasedRelationships![`${i}`] : '';

    const cartDeathDate = typeof props.deathDates[`${i}`] === 'undefined' || props.deathDates![`${i}`] === '' ? new Date(1900, 1, 1).toISOString() : props.deathDates![`${i}`];
    const cartDeathTime = typeof props.deathTimes[`${i}`] !== 'undefined' ? props.deathTimes![`${i}`] : '';

    const cartSurgeryDate = typeof props.surgeryDates[`${i}`] === 'undefined' || props.surgeryDates![`${i}`] === '' ? new Date(1900, 1, 1).toISOString() : props.surgeryDates![`${i}`];

    const cartNameChange = typeof props.nameChanges[`${i}`] !== 'undefined' ? props.nameChanges![`${i}`] : '';
    const cartOldName = typeof props.oldNames[`${i}`] !== 'undefined' ? props.oldNames![`${i}`] : '';
    const cartOldNameArray = strSplit(cartOldName, ';');

    // If this is an event product, it will not have any sub product and it cannot be a renewable lamp.
    const cartQuantity = typeof props.quantities[`${i}`] !== 'undefined' ? cartEventArray.length > 1 ? 1 : props.quantities![`${i}`] : 1;
    const cartSubProductQuantity = typeof props.subProductQuantities[`${i}`] !== 'undefined' ? props.subProductQuantities[`${i}`] : 0;
    const cartHasSubProduct = props.subProduct != null && cartSubProductQuantity > 0;

    for (let r = cartOldNameArray.length - 1; r < 2; r++) {
      cartOldNameArray.push('');
    }

    let cartParentCartLineID = '';
    let cartEventAddedIndex = 0;
    let cartSubParentAdded = false;
    let cartSubProductAdded = false;

    while (cartEventAddedIndex < cartEventArray.length || (cartHasSubProduct && cartSubParentAdded && !cartSubProductAdded)) {
      let cartComment = uuidv4();

      while (!uuidValidateV4(cartComment)) {
        cartComment = uuidv4();
      }

      const addToCartResult = await cartState.addProductToCart({
        product: cartHasSubProduct && cartSubParentAdded ? props.subProduct : productToAdd,
        count: cartHasSubProduct && cartSubParentAdded ? cartSubProductQuantity : cartQuantity,
        availableQuantity: props.productAvailability![0].AvailableQuantity,
        additionalProperties: { orderQuantityLimitsFeatureIsEnabled: props.isOrderQuantityLimitsFeatureEnabled },
        enableStockCheck: false,
        isPriceKeyedIn: props.isPriceKeyedIn,
        customPrice: typeof props.customPriceAmount![`${i}`] !== 'undefined' ? props.customPriceAmount![`${i}`] : 0,
        isAddEmailDeliveryItemToCart: props.isAddEmailDeliveryItemToCart
      });

      const cartLineProductID: string = String(cartState.cart.CartLines![cartState.cart.CartLines!.length - 1].ProductId!);
      const cartLineID: string = cartState.cart.CartLines![cartState.cart.CartLines!.length - 1].LineId!;

      const item: FormDetails = {
        CartLineID: cartLineID, CartID: cartState.cart.Id, CartLineComment: cartComment, ParentCartLineID: cartEventAddedIndex > 0 ? cartParentCartLineID : '',
        ProductID: cartLineProductID, EventID: cartEventIDArray[`${cartEventAddedIndex}`],
        EventName: cartEventArray[`${cartEventAddedIndex}`],
        SerialID: cartSerialId, SerialNumber: cartSerialNumber, SerialName: cartSerialName,
        Name_1: cartNameArray[0], Name_2: cartNameArray[1], Name_3: cartNameArray[2], Name_4: cartNameArray[3], Name_5: cartNameArray[4],
        Name_6: cartNameArray[5], Name_7: cartNameArray[6], Name_8: cartNameArray[7], Name_9: cartNameArray[8], Name_10: cartNameArray[9],
        Name_11: cartNameArray[10], Name_12: cartNameArray[11], Name_13: cartNameArray[12], Name_14: cartNameArray[13], Name_15: cartNameArray[14], Name_16: cartNameArray[15], Name_17: cartNameArray[16], Name_18: cartNameArray[17], Name_19: cartNameArray[18], Name_20: cartNameArray[19],
        Remarks: cartRemark, Message: cartMessageTemp,
        Category: cartCategory, DedicatedBy: cartDedicatedBy,
        HasTaxDeduction: cartHasTaxDeduction, Nric: cartNric,
        BuildingName: cartBuildingName, UnitNumber: cartUnitNumber, PostalCode: cartPostalCode, PhoneNumber: cartPhoneNumber, ChineseName: cartChineseName, Country: cartCountry,
        SchoolName: cartSchoolName, CompanyName: cartCompanyName,
        DonationOption: cartCollectionOption, BlessingOption: cartBlessingOption, LightOption: cartLightOption,
        ValidFrom: cartValidFrom, ValidTo: cartValidTo,
        DedicationPeriod: cartDedicationPeriod,
        StartDate: cartStartDate,
        Gender: cartGender,
        SurgeryDate: cartSurgeryDate,
        BirthDate: cartBirthDate, BirthTime: cartBirthTimes[0] ? cartBirthTimes[0] : '',
        BirthDate_2: cartBirthDates[1] ? cartBirthDates[1] : '', BirthTime_2: cartBirthTimes[1] ? cartBirthTimes[1] : '',
        DeathDate: cartDeathDate, DeathTime: cartDeathTime,
        DeceasedRelationship: cartDeceasedRelationship,
        NameChange: cartNameChange, 
        OldName_1: cartOldNameArray[0], OldName_2: cartOldNameArray[1],
        NameNextKin: cartNameNextKin,
        ContactNumber: cartContactNumber,
        ContactNumberNextKin: cartContactNumbersNextKin,
        PassportNumber: cartPassportNumber,
        PassportExpiryDate: cartPassportExpiryDate,
        PassportIssuedDate: cartPassportIssuedDate,
        Consent: cartConsent,
        IsInsuranceIncluded: cartIsInsuranceIncluded
      };

      if (addToCartResult.status === 'SUCCESS') {
        if (cartEventAddedIndex === 0) {
            cartParentCartLineID = cartLineID;
        }

        let updateCart = cartState.cart;
        let updateCartLines: CartLine[] = cartState.cart.CartLines ? cartState.cart.CartLines : [];

        if (updateCartLines.length > 0) {
          updateCartLines![updateCartLines.length - 1].Comment = cartComment;

          await updateCartLinesAsync({ callerContext: props.context.actionContext }, updateCart.Id, updateCartLines);
        }

        let formDetails: FormDetails = {
          CartLineID: cartLineID, CartID: cartState.cart.Id, 
          CartLineComment: cartComment, ParentCartLineID: cartEventAddedIndex > 0 ? cartParentCartLineID : '', 
          ProductID: cartLineProductID, 
          EventID: cartEventIDArray[`${cartEventAddedIndex}`], EventName: cartEventArray[`${cartEventAddedIndex}`], 
          SerialID: cartSerialId, SerialNumber: cartSerialNumber, SerialName: cartSerialName, 
          Name_1: cartNameArray[0], Name_2: cartNameArray[1], Name_3: cartNameArray[2], Name_4: cartNameArray[3], 
          Name_5: cartNameArray[4], Name_6: cartNameArray[5], Name_7: cartNameArray[6], Name_8: cartNameArray[7], 
          Name_9: cartNameArray[8], Name_10: cartNameArray[9], Name_11: cartNameArray[10], Name_12: cartNameArray[11], 
          Name_13: cartNameArray[12], Name_14: cartNameArray[13], Name_15: cartNameArray[14], Name_16: cartNameArray[15], 
          Name_17: cartNameArray[16], Name_18: cartNameArray[17], Name_19: cartNameArray[18], Name_20: cartNameArray[19], 
          Remarks: cartRemark, Message: cartMessageTemp, Category: cartCategory, DedicatedBy: cartDedicatedBy, 
          HasTaxDeduction: cartHasTaxDeduction, Nric: cartNric, 
          BuildingName: cartBuildingName, UnitNumber: cartUnitNumber, 
          PostalCode: cartPostalCode, PhoneNumber: cartPhoneNumber, ChineseName: cartChineseName, Country: cartCountry, 
          SchoolName: cartSchoolName, CompanyName: cartCompanyName, 
          DonationOption: cartCollectionOption, BlessingOption: cartBlessingOption, LightOption: cartLightOption, 
          ValidFrom: cartValidFrom, ValidTo: cartValidTo, 
          DedicationPeriod: cartDedicationPeriod, 
          StartDate: cartStartDate,
          Gender: cartGender,
          SurgeryDate: cartSurgeryDate, 
          BirthDate: cartBirthDate, BirthTime: cartBirthTimes[0] ? cartBirthTimes[0] : '', 
          BirthDate_2: cartBirthDates[1] ? cartBirthDates[1] : '', BirthTime_2: cartBirthTimes[1] ? cartBirthTimes[1] : '', 
          DeathDate: cartDeathDate, DeathTime: cartDeathTime, 
          DeceasedRelationship: cartDeceasedRelationship, 
          NameChange: cartNameChange, 
          OldName_1: cartOldNameArray[0], OldName_2: cartOldNameArray[1],
          NameNextKin: cartNameNextKin,
          ContactNumber: cartContactNumber,
          ContactNumberNextKin: cartContactNumbersNextKin,
          PassportNumber: cartPassportNumber,
          PassportExpiryDate: cartPassportExpiryDate,
          PassportIssuedDate: cartPassportIssuedDate,
          Consent: cartConsent,
          IsInsuranceIncluded: cartIsInsuranceIncluded
        };

        let cartItem: CartItem = {
          Form: formDetails,
          IsValid: true,
          InvalidType: '',
          InCart: true,
          IsSubProduct: false
        };

        // Special case for a particular GP product using consecration form.
        if (!(!cartHasSubProduct || (cartHasSubProduct && !cartSubParentAdded))) {
          cartItem.Form.ProductID = String(props.subProduct.RecordId);
          cartItem.Form.ParentCartLineID = cartParentCartLineID;
          cartItem.Form.EventID = '';
          cartItem.Form.EventName = '';
          cartItem.Form.SerialID = '';
          cartItem.Form.SerialNumber = '';
          cartItem.Form.SerialName = '';
          cartItem.Form.Name_1 = cartNameArray[0];
          cartItem.Form.Name_2 = cartNameArray[1];
          cartItem.Form.Name_3 = '';
          cartItem.Form.Name_4 = '';
          cartItem.Form.Name_5 = '';
          cartItem.Form.Name_6 = '';
          cartItem.Form.Name_7 = '';
          cartItem.Form.Name_8 = '';
          cartItem.Form.Name_9 = '';
          cartItem.Form.Name_10 = '';
          cartItem.Form.Name_11 = '';
          cartItem.Form.Name_12 = '';
          cartItem.Form.Name_13 = '';
          cartItem.Form.Name_14 = '';
          cartItem.Form.Name_15 = '';
          cartItem.Form.Name_16 = '';
          cartItem.Form.Name_17 = '';
          cartItem.Form.Name_18 = '';
          cartItem.Form.Name_19 = '';
          cartItem.Form.Name_20 = '';
          cartItem.Form.Remarks = '';
          cartItem.Form.Message = '';
          cartItem.Form.Category = '';
          cartItem.Form.DedicatedBy = '';
          cartItem.Form.HasTaxDeduction = false;
          cartItem.Form.Nric = '';
          cartItem.Form.BuildingName = '';
          cartItem.Form.UnitNumber = '';
          cartItem.Form.PostalCode = '';
          cartItem.Form.PhoneNumber = '';
          cartItem.Form.Country = '';
          cartItem.Form.SchoolName = '';
          cartItem.Form.CompanyName = '';
          cartItem.Form.DonationOption = '';
          cartItem.Form.BlessingOption = '';
          cartItem.Form.LightOption = '';
          cartItem.Form.DedicationPeriod = '';
          cartItem.Form.DeathTime = '';
          cartItem.Form.NameChange = '';
          cartItem.Form.OldName_1 = '';
          cartItem.Form.OldName_2 = '';
          cartItem.Form.BirthDate_2 = '';
          cartItem.Form.BirthTime_2 = '';

          cartItem.IsSubProduct = true;

          cartSubProductAdded = true;
        }

        const result = await createOrUpdateCartline(props.context, cartItem);

        if (!result) {
          const errorMessageId = addToCartResult.errorDetails ? addToCartResult.errorDetails.ErrorResourceId ? addToCartResult.errorDetails.ErrorResourceId : '' : '';
          const errorMessageDetail = addToCartResult.errorDetails ? addToCartResult.errorDetails.LocalizedMessage ? addToCartResult.errorDetails.LocalizedMessage : '' : '';

          await generateErrorLog(props.context, 'ERROR ecomm-form-add-to-cart-source createOrUpdateBTGPCartLineAsync', errorMessageId + ':' + errorMessageDetail, item);

          propagateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: addToCartResult });
          
          setDisabled(false);
        }
      } else {
        const errorMessageId = addToCartResult.errorDetails ? addToCartResult.errorDetails.ErrorResourceId ? addToCartResult.errorDetails.ErrorResourceId : '' : '';
        const errorMessageDetail = addToCartResult.errorDetails ? addToCartResult.errorDetails.LocalizedMessage ? addToCartResult.errorDetails.LocalizedMessage : '' : '';

        await generateErrorLog(props.context, 'ERROR ecomm-form-add-to-cart-source cartState.addProductToCart', errorMessageId + ':' + errorMessageDetail, item);

        propagateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: addToCartResult });
        
        setDisabled(false);
      }

      if (cartEventAddedIndex < cartEventArray.length) {
        cartEventAddedIndex++;
      }

      if (cartHasSubProduct && !cartSubParentAdded) {
        cartSubParentAdded = true;
      }
    }
  }

  propagateResult(props);
};

const uuidValidateV4 = (uuid: string): boolean => {
  return uuidValidate(uuid) && uuidVersion(uuid) == 4;
};

const strSplit = (sentence: string, splitChar: string): string[] => {
    // let arrayPosition = 0;
    let oneWord = "";
    let newSentence = sentence + splitChar;
    let split = [];
    for (let j = 0; j < newSentence.length; j++) {
        if (newSentence[`${j}`] === splitChar) {
            split.push(oneWord);
            // arrayPosition++;
            oneWord = "";
        } else {
            oneWord += newSentence[`${j}`];
        }
    }
    return split;
};

const AddToCartComponentActions = {
  onClick: onClick
};

const AddToCart: React.FC<IProductDetailsAddToCartComponentProps> = (props: IProductDetailsAddToCartComponentProps) => {
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
    await AddToCartComponentActions.onClick(event, props, setDisabled, setModalOpen);
  };

  const priceComponent = props.data.price ? (
    <ProductDetailsPriceComponent
      data={{ price: props.data.price }}
      context={props.context}
      id={props.id}
      typeName={props.typeName}
      freePriceText={props.dialogStrings?.freePriceText}
      originalPriceText={props.dialogStrings?.originalPriceText}
      currentPriceText={props.dialogStrings?.currentPriceText}
    />) : '';

  const popupProps: IPopupProps = {
    context: props.context,
    className: 'msc-add-to-cart',
    id: props.id,
    typeName: props.typeName,
    data: { product: props.data.product, price: props.data.price },
    dialogStrings: props.dialogStrings,
    imageSettings: props.imageSettings,
    gridSettings: props.context.request.gridSettings,
    productQuantity: props.quantity !== undefined ? props.quantity : 1,
    priceComponent: priceComponent,
    navigationUrl: props.navigationUrl,
    modalOpen: modalOpen,
    setModalOpen: setModalOpen,
    telemetryContent: props.telemetryContent
  };

  const renderModalPopup = <Popup {...popupProps} />;
  const label = getLinkText(props);
  const payload = getPayloadObject(TelemetryEvent.AddToCart, props.telemetryContent!, label, '');
  const attributes = getTelemetryAttributes(props.telemetryContent!, payload);

  return (
    <>
      {renderModalPopup}
      <button 
        className={classnames('msc-add-to-cart ', props.className)} 
        {...attributes} 
        onClick={onClickHandler} 
        disabled={props.disabled || disabled || isIntermediateState(props) || shouldShowOutOfStock(props, false)}
      >
        {getLinkText(props)}
      </button>
    </>
  );
};

// Set default props.
AddToCart.defaultProps = { quantity: 1 };

const getLinkText = (props: IProductDetailsAddToCartComponentProps): string => {
    return shouldShowOutOfStock(props, false) ? props.outOfStockText : props.addToCartText;
};

const addToCartError = (props: IProductDetailsAddToCartComponentProps): IProductDetailsAddToCartFailureResult | undefined => {
    const { data, productAvailability, isCustomPriceSelected, customPriceAmount, maximumKeyInPrice, minimumKeyInPrice, defaultMaximumKeyInPrice = 1000, defaultMinimumKeyInPrice = 1 } = props;

    if (!data || !data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (data.product.Dimensions) {
        const missingDimensions = data.product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (productAvailability && productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // When Custom price is selected, if there is no keyed-in price or keyed-in price is out of limit, should return error.
    if (isCustomPriceSelected && (
        !customPriceAmount || customPriceAmount[0] > (maximumKeyInPrice || defaultMaximumKeyInPrice) || customPriceAmount[0] < (minimumKeyInPrice || defaultMinimumKeyInPrice))) {
        return { failureReason: 'INVALIDCUSTOMAMOUNT' };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IProductDetailsAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (props.context.app.config.enableStockCheck === undefined || props.context.app.config.enableStockCheck === false
        || props.isLoading || props.isUpdatingDimension || props.isLoadingDeliveryOptions || props.isUpdatingDeliveryOptions || props.isAddServiceItemToCart) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }
    const includedQuantityNumber = includeCurrentQuantity && props.quantity ? props.quantity : 1;

    return (props.productAvailability
        && props.productAvailability.AvailableQuantity !== undefined
        && props.productAvailability.AvailableQuantity >= includedQuantityNumber)
        ? false : true;
};

const isIntermediateState = (props: IProductDetailsAddToCartComponentProps): boolean => {
    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also not in intermediate state
            return false;
        }
    }

    if (!props.isLoading && !props.isUpdatingDimension && !props.isLoadingDeliveryOptions && !props.isUpdatingDeliveryOptions) {
        return false;
    }

    return true;
};

const propagateResult = (props: IProductDetailsAddToCartComponentProps): void => {
  if (props.onAdd) {
    props.onAdd();
  }
};

const propagateError = (props: IProductDetailsAddToCartComponentProps, result: IProductDetailsAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

// @ts-ignore
export const ProductDetailsAddToCartComponent: React.FunctionComponent<IProductDetailsAddToCartComponentProps> = msdyn365Commerce.createComponent<IProductDetailsAddtoCartComponent>(
    'AddToCart',
    { component: AddToCart, ...AddToCartComponentActions }
);