export const enum FOMOOrderStatus {
  CREATED = 'CREATED',
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
  CLOSED = 'CLOSED',
  ERROR = 'ERROR'
}

export const enum InventoryLvls {
  OOS = 'OOS',
  LIS = 'LIS',
  AVAIL = 'AVAIL'
}

export const enum Response {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}