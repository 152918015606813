import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackName?: any;
  callbackCategory?: any;
  callbackDedicatedBy?: any;
  callbackNamesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
}

const DeceasedCategoryNamesForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackName, callbackCategory, callbackDedicatedBy, callbackNamesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, allowMultipleRegistrations }) => {
  const [radioValue, setRadioValue] = useState([resource.categoryFamilyLabel[`${localeCode}`]]);
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [dedicatedBy, setDedicatedBy] = useState(['']);

  const formLabels = resource.deceasedCategoryNamesForm[`${localeCode}`];

  const noOfNames = formScheme.find(form => form.field_type === 'textBox')!.field_number!;
  const deceasedNames = formScheme.find(form => form.field_type === 'textBox')!.field_number2!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _onChangeRadioValue = (event: any, index: number) => {
    _setRadioValueArray(event.target.value, index);

    let ceremonyNameArray = ceremonyName.slice();
    let existingCeremonyName = ceremonyNameArray[`${index}`];
    let dedicatedNameArray = dedicatedBy.slice();
    dedicatedNameArray[`${index}`] = '';
    if (existingCeremonyName) {
      let existingNameArray = existingCeremonyName.split(';');


      if (event.target.value === resource.categoryFamilyLabel[`${localeCode}`]) {
        if (existingNameArray.length < noOfNames) {
          existingCeremonyName += ';'.repeat(noOfNames - existingNameArray.length - 1);
        } else { // More than
          while (existingNameArray.length > noOfNames) {
            existingNameArray.pop();
          }
          existingCeremonyName = existingNameArray.join(';');
        }
      } else {
        if (existingNameArray.length < deceasedNames) {
          existingCeremonyName += ';'.repeat(deceasedNames - existingNameArray.length - 1);
        } else { // More than
          while (existingNameArray.length > deceasedNames) {
            existingNameArray.pop();
          }
          existingCeremonyName = existingNameArray.join(';');
        }
      }
    }


    ceremonyNameArray[`${index}`] = existingCeremonyName;

    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedNameArray);

    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedNameArray);

  };

  const _setRadioValueArray = (value: string, index: number) => {
    let radioValueArray = radioValue.slice();

    while (typeof radioValueArray[`${index}`] === 'undefined') {
      radioValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    radioValueArray[`${index}`] = value;

    setRadioValue(radioValueArray);
    _checkNamesEntered(ceremonyName, dedicatedBy, radioValueArray)

    callbackCategory(radioValueArray);
  };

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];
    if (inputMultipleName) {
      if (!inputMultipleName.includes(';')) {
        inputMultipleName += ';'.repeat(noOfNames - 1);
      }

      const inputMutlipleNameSplit = inputMultipleName.split(';');

      inputMutlipleNameSplit[`${nameIndex}`] = inputName;
      inputName = inputMutlipleNameSplit.join(';');
    }
    _setCeremonyNameArray(inputName, index);
  };

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray[`${index}`] = '';
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedBy, radioValue);
  };

  const _setDedicatedByValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setDedicatedByArray(event.target.value.substring(0, maxLength - 1), index);
  };

  const _setDedicatedByArray = (name: string, index: number) => {
    let dedicatedByArray = dedicatedBy.slice();
    while (typeof dedicatedByArray[`${index}`] === 'undefined') {
      dedicatedByArray.push('');
    }
    dedicatedByArray[`${index}`] = name;
    setDedicatedBy(dedicatedByArray);
    callbackDedicatedBy(dedicatedByArray);
    _checkNamesEntered(ceremonyName, dedicatedByArray, radioValue);
  };

  const _checkNamesEntered = (ceremonyNameArray: string[], dedicatedByArray: string[], categoryArray: string[]) => {
    let namesEntered = true;
    if (!ceremonyNameArray || ceremonyNameArray.length === 0) {
      namesEntered = false;
    }
    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (!ceremonyNameArray[`${i}`] || ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }

      if (!categoryArray[`${i}`] || categoryArray[`${i}`] === resource.categoryDeceasedLabel[`${localeCode}`] && dedicatedByArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _addItem = () => {
    addRow(1);

    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    categoryValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    ceremonyNameArray.push('');
    dedicatedByArray.push('');

    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);
    _checkNamesEntered(ceremonyNameArray, dedicatedByArray, categoryValueArray);

    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    if (typeof categoryValueArray[`${index}`] !== 'undefined') {
      if (categoryValueArray.length > 1) {
        categoryValueArray.splice(index, 1);
      } else {
        categoryValueArray[0] = resource.categoryFamilyLabel[`${localeCode}`];
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    if (typeof dedicatedByArray[`${index}`] !== 'undefined') {
      if (dedicatedByArray.length > 1) {
        dedicatedByArray.splice(index, 1);
      } else {
        dedicatedByArray[0] = '';
      }
    }

    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedBy, radioValue);
    _checkNamesEntered(ceremonyName, dedicatedByArray, radioValue);
  }

  useEffect(() => {
    const existingRadioValue: string[] = [];
    const existingCeremonyName: string[] = [];
    const existingDedicatedBy: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const itemNames: string[] = formItems[`${i}`]['names'];
        const itemCategory = formItems[`${i}`]['category'];

        let itemNameCount = 0;

        if (itemCategory === resource.categoryFamilyLabel[`${localeCode}`]) {
          itemNameCount = noOfNames;
        } else {
          itemNameCount = deceasedNames;
        }

        let itemName = '';

        itemNames.map((name, index) => {
          if (index != 0 && index < itemNameCount) {
            itemName += ';' + name;
          } else {
            itemName += name;
          }
        });

        existingRadioValue.push(itemCategory);
        existingCeremonyName.push(itemName);
        existingDedicatedBy.push(formItems[`${i}`]['dedicatedBy']);
      }

      setCeremonyName(existingCeremonyName);
      setDedicatedBy(existingDedicatedBy);

      callbackName(existingCeremonyName);
      callbackDedicatedBy(existingDedicatedBy);

      _checkNamesEntered(existingCeremonyName, existingDedicatedBy, existingRadioValue);
    } else {
      existingRadioValue.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    setRadioValue(existingRadioValue);

    callbackCategory(existingRadioValue);
  }, [formItems]);

  const _buildCategoryField = (idx: number): JSX.Element | null => {
    if (typeof radioValue[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <p className='form-label'>{formLabels.category}</p>
          <div className='form-field row'>
            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.categoryFamilyLabel[`${localeCode}`]}
                checked={radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`])}
                aria-checked={radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`])}
                onChange={(event) => _onChangeRadioValue(event, idx)}
              />
              {resource.categoryFamilyLabel[`${localeCode}`]}
            </label>

            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.categoryDeceasedLabel[`${localeCode}`]}
                checked={radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`])}
                aria-checked={radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`])}
                onChange={(event) => _onChangeRadioValue(event, idx)}
              />
              {resource.categoryDeceasedLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    )
  }

  const _buildNameGroup = (idx: number, noOfNames: number): JSX.Element[] => {
    const nameLabel = formLabels.name;
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{nameLabel.endsWith('*') ? nameIndex === 0 ? nameLabel.substring(0, nameLabel.length - 1) + ' ' + (nameIndex + 1) + '*' : nameLabel.replace('*', ' ' + (nameIndex + 1)) : nameLabel + ' ' + nameIndex + 1}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayName(ceremonyName[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'category') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildCategoryField(idx)}
                          {radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`]) && noOfNames > 0 && _buildNameGroup(idx, noOfNames)}
                          {radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`]) && deceasedNames > 0 && _buildNameGroup(idx, deceasedNames)}
                          {radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`]) && deceasedNames > 0 &&
                            <div className='row'>
                              <div className='col-lg-6 form-field'>
                                <p className='form-label'>{formLabels.dedicatedBy}</p>
                                <input
                                  type='text'
                                  inputMode='text'
                                  value={dedicatedBy[`${idx}`]}
                                  aria-live='polite'
                                  role='spinbutton'
                                  onChange={(event: any) => _setDedicatedByValue(event, idx)}
                                  onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
                                  className='form-control'
                                  maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
                                />
                              </div>
                            </div>
                          }
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>
        }
      </form>
    </div>
  );
};

export default DeceasedCategoryNamesForm;