import React, { useState, useEffect } from 'react';

import DatePicker from 'react-date-picker';
import Select from 'react-select';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import countries from '../resources/countries.json';
import period from '../../../resources/dedicationPeriod.json';
import resource from '../resources/resource.json';
import time from '../resources/time.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackMultiplier: any;
  callbackDedicationPeriod: any;
  callbackName?: any;
  callbackBirthDate: any;
  callbackBirthTime: any;
  callbackBuilding: any;
  callbackUnitNumber: any;
  callbackPostalCode: any;
  callbackCountry: any;
  callbackNamesEntered?: any;
  callbackDateEntered: any;
  callbackAddressEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  userInfo: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
}

const ServicesMarriageForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackMultiplier, callbackDedicationPeriod, callbackName, callbackBirthDate, callbackBirthTime,
  callbackBuilding, callbackUnitNumber, callbackPostalCode, callbackCountry,
  callbackNamesEntered, callbackDateEntered, callbackAddressEntered,
  formItems, config, localeCode, actionContext, userInfo, isMobile, isEditMode, allowMultipleRegistrations }) => {
  const [dedicationPeriod, setDedicationPeriod] = useState([period[`${localeCode}`].period[0].label]);
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [birthDate, setBirthDate] = useState(['']);
  const [birthTime, setBirthTime] = useState(['']);
  const [buildingName, setBuildingName] = useState(['']);
  const [unitNumber, setUnitNumber] = useState(['']);
  const [postalCode, setPostalCode] = useState(['']);
  const [countryName, setCountry] = useState(['']);

  const [multipliers, setMultiplers] = useState([1]);

  const moment = require('moment');
  const formLabels = resource.servicesMarriageForm[`${localeCode}`];
  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;
  const noPersons = 2;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _setMultipler = (periodLabel: string, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(period.scale[0]);
    }

    const scaleIdx = period[`${localeCode}`].period.findIndex((item: { value: number; label: string; }) => item.label === periodLabel);

    valueArray[`${index}`] = period.scale[`${scaleIdx}`];

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setDedicationPeriod = (event: any, index: number) => {
    const valueArray = dedicationPeriod.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(period[`${localeCode}`].period[0].label);
    }

    valueArray[`${index}`] = event.label;

    setDedicationPeriod(valueArray);

    callbackDedicationPeriod(valueArray);

    _setMultipler(event.label, index);
  }

  const _setDisplayValue = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _setNamesInputValue = (event: any, index: number, elementIdx: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let storedElements = ceremonyName[`${index}`];

    if (!storedElements.includes(';')) {
      storedElements += ';'.repeat(1);
    }

    const valueArray = storedElements.split(';');

    valueArray[`${elementIdx}`] = event.target.value.substring(0, maxLength - 1);

    _setCeremonyNameArray(valueArray.join(';'), index);
  }

  const _setCeremonyNameArray = (value: string, index: number) => {
    const valueArray = ceremonyName.slice();

    // To handle adding of a new form record.
    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setCeremonyName(valueArray);

    callbackName(valueArray);

    _checkNameEntered(valueArray);
  }

  const _displayFirstDateValue = (value: string): string => {
    if (value.includes(';')) {
      const valueSplit = value.split(';');

      return valueSplit[0];
    }

    return value;
  }

  const _displaySecondDateValue = (value: string): string => {
    if (value.includes(';')) {
      const valueSplit = value.split(';');

      return _formatDateString(valueSplit[1]);
    }

    return value;
  }

  const _formatDateString = (value: string): string => {
    // Convert DD-MM-YYYY into YYYY-MM-DD to initialise DateTime object.
    if (value === '') {
      return '';
    }

    const birthDate = value.split('-');

    return birthDate[2] + '-' + birthDate[1] + '-' + birthDate[0];
  }

  const _setDatesInputValue = (value: Date, index: number, elementIdx: number) => {
    let storedElements = birthDate[`${index}`];

    if (!storedElements.includes(';')) {
      storedElements += ';'.repeat(1);
    }

    const valueArray = storedElements.split(';');

    const date = value ? value : new Date(1900, 1, 1);

    valueArray[`${elementIdx}`] = elementIdx === 0 ? date.toISOString() : moment(date).format('DD-MM-YYYY');

    _setBirthDate(valueArray.join(';'), index);
  }

  const _setBirthDate = (value: string, index: number) => {
    const valueArray = birthDate.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setBirthDate(valueArray);

    callbackBirthDate(valueArray);

    _checkDateEntered(valueArray);
  }

  const _setTimesInputValue = (event: any, index: number, elementIdx: number) => {
    let storedElements = birthTime[`${index}`];

    if (!storedElements.includes(';')) {
      storedElements += ';'.repeat(1);
    }

    const valueArray = storedElements.split(';');

    valueArray[`${elementIdx}`] = event.label;

    _setBirthTime(valueArray.join(';'), index);
  }

  const _setBirthTime = (value: string, index: number) => {
    const valueArray = birthTime.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setBirthTime(valueArray);

    callbackBirthTime(valueArray);
  }

  const _setBuildingName = (value: string, index: number) => {
    const valueArray = buildingName.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setBuildingName(valueArray);

    callbackBuilding(valueArray);

    _checkAddressEntered(buildingName, postalCode);
  }

  const _setUnitNumber = (value: string, index: number) => {
    const valueArray = unitNumber.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    valueArray[`${index}`].trim();

    setUnitNumber(valueArray);

    callbackUnitNumber(valueArray);
  }

  const _setPostalCode = (value: string, index: number) => {
    const valueArray = postalCode.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    if(value.length<=6){
      valueArray[`${index}`] = value;
    }else{
      toast.warning(resource.invalidPostalCodeMessage[`${localeCode}`]);
    }

    valueArray[`${index}`].trim();

    setPostalCode(valueArray);

    callbackPostalCode(valueArray);

    _checkAddressEntered(buildingName, postalCode);
  }

  const _setCountry = (event: any, index: number) => {
    const valueArray = countryName.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = event.label;

    setCountry(valueArray);

    callbackCountry(valueArray);
  }

  const _displayDedicationPeriod = (index: number): { value: string, label: string } => {
    if (dedicationPeriod.length > 0) {
      const selectValueArray = dedicationPeriod.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        return period[`${localeCode}`].period.find((item: { value: string, label: string }) => item.label === selectValueArray[`${index}`]);
      }
    }

    return { value: '', label: '' };
  }

  const _checkNameEntered = (valueArray: string[]) => {
    let checked = true;
    if (valueArray.length > 0) {
      for (let i = 0; i < valueArray.length; i++) {
        if (valueArray[`${i}`].length > 0 && valueArray[`${i}`].indexOf(";") > 0) {
          var namesArray = valueArray[`${i}`].split(";");
          for (let j = 0; j < namesArray.length; j++) {
            if (namesArray[`${j}`].replace(/;/g, '').trim() === '') {
              checked = false; break;
            }
          }
        } else {
          checked = false; break;
        }
      }
    }
    else {
      checked = false
    }

    callbackNamesEntered(checked);
  }

  const _checkDateEntered = (valueArray: string[]) => {
    let checked = true;

    if (valueArray.length > 0) {
      for (let i = 0; i < valueArray.length; i++) {
        if (valueArray[`${i}`].length > 0 && valueArray[`${i}`].indexOf(";") > 0) {
          var dateArray = valueArray[`${i}`].split(";");

          if (dateArray.length === noPersons) {
            for (let j = 0; j < dateArray.length; j++) {
              const value = dateArray[`${j}`].replace(/;/g, '').trim();

              if (value === '' || value.includes('1900')) {
                checked = false; break;
              }
            }
          } else {
            checked = false; break;
          }
        } else {
          checked = false; break;
        }
      }
    }
    else {
      checked = false
    }

    callbackDateEntered(checked);
  }

  const _checkAddressEntered = (buildingArray: string[], postalCodeArray: string[]) => {
    let result = true;

    for (let i = 0; i < buildingArray.length; ++i) {
      result = result && buildingArray[`${i}`] !== '' && postalCodeArray[`${i}`] !== '';
    }

    callbackAddressEntered(result);
  }

  const _initBuildingField = () => {
    if (userValid) {
      return userInfo.gmb_blockno.trim() + (userInfo.gmb_buildingno !== '' ? ', ' + userInfo.gmb_buildingno.trim() : '') + (userInfo.gmb_buildingname !== '' ? ', ' + userInfo.gmb_buildingname.trim() : '') + (userInfo.address1_line1 !== '' ? ', ' + userInfo.address1_line1.trim() : '') + (userInfo.address1_city !== '' ? ', ' + userInfo.address1_city.trim() : '');
    }

    return '';
  }

  const _initCountryField = () => {
    if (userValid) {
      const states = countries[`${localeCode}`].countries;

      const location = states.find((place: { value: string, label: string }) => place.label.toUpperCase() === userInfo.address1_country.toUpperCase());

      return location ? location.label : '';
    }

    return '';
  }

  const _addItem = () => {
    addRow(1);

    const multiplierArray = multipliers.slice();
    const dedicationPeriodArray = dedicationPeriod.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dateValueArray = birthDate.slice();
    const timeValueArray = birthTime.slice();
    const buildingNameArray = buildingName.slice();
    const unitNumberArray = unitNumber.slice();
    const postalCodeArray = postalCode.slice();
    const countryArray = countryName.slice();

    multiplierArray.push(period.scale[0]);
    dedicationPeriodArray.push(period[`${localeCode}`].period[0].label);
    ceremonyNameArray.push('');
    dateValueArray.push('');
    timeValueArray.push('');

    if (userValid) {
      buildingNameArray.push(_initBuildingField());
      unitNumberArray.push(userInfo.gmb_unitnofloorapartmentno);
      postalCodeArray.push(userInfo.address1_postalcode);
      countryArray.push(_initCountryField());
    } else {
      buildingNameArray.push('');
      unitNumberArray.push('');
      postalCodeArray.push('');
      countryArray.push(countries[`${localeCode}`].countries[0].label);
    }

    setMultiplers(multiplierArray);
    setDedicationPeriod(dedicationPeriodArray);
    setCeremonyName(ceremonyNameArray);
    setBirthDate(dateValueArray);
    setBirthTime(timeValueArray);
    setBuildingName(buildingNameArray);
    setUnitNumber(unitNumberArray);
    setPostalCode(postalCodeArray);
    setCountry(countryArray);

    callbackMultiplier(multiplierArray);
    callbackDedicationPeriod(dedicationPeriodArray);
    callbackName(ceremonyNameArray);
    callbackBirthDate(dateValueArray);
    callbackBirthTime(timeValueArray);
    callbackBuilding(buildingNameArray);
    callbackUnitNumber(unitNumberArray);
    callbackPostalCode(postalCodeArray);
    callbackCountry(countryArray);

    callbackNamesEntered(false);
    callbackDateEntered(true);

    _checkAddressEntered(buildingNameArray, postalCodeArray);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }
      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const multiplierArray = multipliers.slice();
    const dedicationPeriodArray = dedicationPeriod.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dateValueArray = birthDate.slice();
    const timeValueArray = birthTime.slice();
    const buildingNameArray = buildingName.slice();
    const unitNumberArray = unitNumber.slice();
    const postalCodeArray = postalCode.slice();
    const countryArray = countryName.slice();

    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = period.scale[0];
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof dedicationPeriodArray[`${index}`] !== 'undefined') {
      if (dedicationPeriodArray.length > 1) {
        dedicationPeriodArray.splice(index, 1);
      } else {
        dedicationPeriodArray[0] = period[`${localeCode}`].period[0].label;
      }

      setDedicationPeriod(dedicationPeriodArray);

      callbackDedicationPeriod(dedicationPeriodArray);
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }

      setCeremonyName(ceremonyNameArray);

      callbackName(ceremonyNameArray);
    }

    if (typeof dateValueArray[`${index}`] !== 'undefined') {
      if (dateValueArray.length > 1) {
        dateValueArray.splice(index, 1);
      } else {
        dateValueArray[0] = '';
      }

      setBirthDate(dateValueArray);

      callbackBirthDate(dateValueArray);
    }

    if (typeof timeValueArray[`${index}`] !== 'undefined') {
      if (timeValueArray.length > 1) {
        timeValueArray.splice(index, 1);
      } else {
        timeValueArray[0] = '';
      }

      setBirthTime(timeValueArray);

      callbackBirthTime(timeValueArray);
    }

    if (typeof buildingNameArray[`${index}`] !== 'undefined') {
      if (buildingNameArray.length > 1) {
        buildingNameArray.splice(index, 1);
      } else {
        buildingNameArray[0] = '';
      }

      setBuildingName(buildingNameArray);

      callbackAddressEntered(buildingNameArray);
    }

    if (typeof unitNumberArray[`${index}`] !== 'undefined') {
      if (unitNumberArray.length > 1) {
        unitNumberArray.splice(index, 1);
      } else {
        unitNumberArray[0] = '';
      }

      setUnitNumber(unitNumberArray);

      callbackUnitNumber(unitNumberArray);
    }

    if (typeof postalCodeArray[`${index}`] !== 'undefined') {
      if (postalCodeArray.length > 1) {
        postalCodeArray.splice(index, 1);
      } else {
        postalCodeArray[0] = '';
      }

      setPostalCode(postalCodeArray);

      callbackPostalCode(postalCodeArray);
    }

    if (typeof countryArray[`${index}`] !== 'undefined') {
      if (countryArray.length > 1) {
        countryArray.splice(index, 1);
      } else {
        countryArray[0] = countries[`${localeCode}`].countries[0].label;
      }

      setCountry(countryArray);

      callbackCountry(countryArray);
    }

    _checkNameEntered(ceremonyNameArray);
    _checkDateEntered(dateValueArray);
    _checkAddressEntered(buildingName, postalCode);
  }

  useEffect(() => {
    const existingMultiplier: number[] = [];
    const existingDedicatedPeriod: string[] = [];
    const existingCeremonyName: string[] = [];
    const existingBirthDate: string[] = [];
    const existingBirthTime: string[] = [];
    const existingBuildingName: string[] = [];
    const existingUnitNumber: string[] = [];
    const existingPostalCode: string[] = [];
    const existingCountry: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const selectedPeriod = formItems[`${i}`]['dedicationPeriod'] !== '' ? formItems[`${i}`]['dedicationPeriod'] : period[`${localeCode}`].period[0].label;
        const index = period[`${localeCode}`].period.findIndex((item: { value: number; label: string; }) => item.label === selectedPeriod);
        const itemNames: string[] = formItems[`${i}`]['names'];

        let itemName = '';

        itemNames.map((name, index) => {
          if (index !== 0 && index < noPersons) {
            itemName += ';' + name;
          } else {
            itemName += name;
          }
        });

        const birthDates = (formItems[`${i}`]['birthDate'] !== '' ? new Date(formItems[`${i}`]['birthDate']).toISOString() : new Date().toISOString()) + (formItems[`${i}`]['birthDate_2'] !== '' ? ';' + moment(formItems[`${i}`]['birthDate_2']).format('DD-MM-YYYY') : '');

        const birthTimes = formItems[`${i}`]['birthTime'] + (formItems[`${i}`]['birthTime_2'] !== '' ? ';' + formItems[`${i}`]['birthTime_2'] : '');

        existingMultiplier.push(period.scale[`${index}`]);
        existingDedicatedPeriod.push(selectedPeriod);
        existingCeremonyName.push(itemName);
        existingBirthDate.push(birthDates);
        existingBirthTime.push(birthTimes);
        existingBuildingName.push(formItems[`${i}`]['buildingName']);
        existingUnitNumber.push(formItems[`${i}`]['unitNumber']);
        existingPostalCode.push(formItems[`${i}`]['postalCode']);
        existingCountry.push(formItems[`${i}`]['country']);
      }

      setMultiplers(existingMultiplier);
      setDedicationPeriod(existingDedicatedPeriod);
      setCeremonyName(existingCeremonyName);
      setBirthDate(existingBirthDate);
      setBirthTime(existingBirthTime);
      setBuildingName(existingBuildingName);
      setUnitNumber(existingUnitNumber);
      setPostalCode(existingPostalCode);
      setCountry(existingCountry);

      callbackMultiplier(existingMultiplier);
      callbackDedicationPeriod(existingDedicatedPeriod);
      callbackName(existingCeremonyName);
      callbackBirthDate(existingBirthDate);
      callbackBirthTime(existingBirthTime);
      callbackBuilding(existingBuildingName);
      callbackUnitNumber(existingUnitNumber);
      callbackPostalCode(existingPostalCode);
      callbackCountry(existingCountry);

      _checkNameEntered(existingCeremonyName);
      _checkDateEntered(existingBirthDate);
      _checkAddressEntered(existingBuildingName, existingPostalCode);
    } else {
      if (userValid) {
        existingBuildingName.push(_initBuildingField());
        existingUnitNumber.push(userInfo.gmb_unitnofloorapartmentno);
        existingPostalCode.push(userInfo.address1_postalcode);
        existingCountry.push(_initCountryField());

        setBuildingName(existingBuildingName);
        setUnitNumber(existingUnitNumber);
        setPostalCode(existingPostalCode);
        setCountry(existingCountry);

        callbackBuilding(existingBuildingName);
        callbackUnitNumber(existingUnitNumber);
        callbackPostalCode(existingPostalCode);
        callbackCountry(existingCountry);

        _checkAddressEntered(existingBuildingName, existingPostalCode);
      }

      callbackDedicationPeriod(dedicationPeriod);
    }
  }, [formItems]);

  const _buildDedicationPeriod = (idx: number): JSX.Element | null => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.dedicationPeriod}</p>
          <Select
            value={_displayDedicationPeriod(idx)}
            options={period[`${localeCode}`].period}
            onChange={(event: any) => _setDedicationPeriod(event, idx)}
            className={'select'}
            isSearchable={false}
          />
        </div>
      </div>
    );
  }

  const _buildNameField = (idx: number, elementIdx: number): JSX.Element => {
    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.detailsPrompt + ' ' + (elementIdx + 1)}</p>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.name}</p>
          <input
            type='text'
            inputMode='text'
            value={_setDisplayValue(ceremonyName[`${idx}`], elementIdx)}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setNamesInputValue(event, idx, elementIdx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  const _buildBirthDateTime = (idx: number, elementIdx: number): JSX.Element | null => {
    const displayBirthDate = elementIdx === 0 ? _displayFirstDateValue(birthDate[`${idx}`]) : _displaySecondDateValue(birthDate[`${idx}`]);

    if (displayBirthDate === undefined) {
      return null;
    }

    const found = time.intervals.find(slot => slot.label === _setDisplayValue(birthTime[`${idx}`], elementIdx));

    const displayTime = found ? found : { value: '', label: '' };

    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.birthDate}</p>
          <DatePicker
            onChange={(date: Date) => _setDatesInputValue(date, idx, elementIdx)}
            value={displayBirthDate === '' || displayBirthDate.includes('1900') ? null : new Date(displayBirthDate)}
            format={'dd-MM-y'}
            maxDate={new Date()}
            className={'datepicker'}
            dayPlaceholder={''}
            monthPlaceholder={''}
            yearPlaceholder={''}
          />
        </div>

        <div className='col-lg-4 offset-lg-1 form-field'>
          <p className='form-label'>{formLabels.birthTime}</p>
          <Select
            value={displayTime}
            options={time.intervals}
            onChange={(event: any) => _setTimesInputValue(event, idx, elementIdx)}
            className={'select'}
            isSearchable={false}
          />
        </div>
      </div>
    );
  }

  const _buildAddressField = (idx: number): JSX.Element => {
    const states = countries[`${localeCode}`].countries;

    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.addressPrompt}</p>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.addressBuilding}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={(event: any) => _setBuildingName(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={buildingName[`${idx}`]}
            type='text'
            autoComplete="none"
          />

          <p className='form-label'>{formLabels.addressUnit}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={(event: any) => _setUnitNumber(event.target.value, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={unitNumber[`${idx}`]}
            type='text'
            autoComplete="none"
          />
        </div>

        <div className='col-lg-10 row'>
          <div className='col-lg-4 form-field'>
            <p className='form-label'>{formLabels.addressPostalCode}</p>
            <input
              type='text'
              inputMode='text'
              value={postalCode[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              onChange={(event: any) => _setPostalCode(event.target.value, idx)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              autoComplete="none"
            />
          </div>

          <div className='col-lg-5 offset-lg-1 form-field'>
            <p className='form-label'>{formLabels.addressCountry}</p>
            <Select
              placeholder={countryName[`${idx}`]}
              options={states}
              onChange={(event: any) => _setCountry(event, idx)}
              className={'select'}
              isSearchable={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'period') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildDedicationPeriod(idx)}
                          {_buildNameField(idx, 0)}
                          {_buildBirthDateTime(idx, 0)}
                          {_buildNameField(idx, 1)}
                          {_buildBirthDateTime(idx, 1)}
                          {_buildAddressField(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${multipliers[`${idx}`] * selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default ServicesMarriageForm;