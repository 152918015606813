import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackDedicatedBy?: any;
  callbackNamesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const DeceasedDedicatedByForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackEvent, callbackEventID, callbackName, callbackDedicatedBy, callbackNamesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents, allowMultipleRegistrations }) => {
  const [ceremonyValue, setCeremonyValue] = useState(['']);
  const [ceremonyIDValue, setCeremonyIDValue] = useState(['']);
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [dedicatedBy, setDedicatedBy] = useState(['']);

  const formLabels = resource.deceasedDedicatedByForm[`${localeCode}`];
  const deceasedNames = formScheme.find(form => form.field_name === 'deceasedName')!.field_number!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm(); 

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];

    if (!inputMultipleName.includes(';')) {
      inputMultipleName += ';'.repeat(noOfNames - 1);
    }

    let inputMutlipleNameSplit = inputMultipleName.split(';');

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(';');

    _setCeremonyNameArray(inputName, index);    
  };

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;
    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedBy);
  };

  const _setDedicatedByValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setDedicatedByArray(event.target.value.substring(0, maxLength - 1), index);
  };

  const _setDedicatedByArray = (name: string, index: number) => {
    let dedicatedByArray = dedicatedBy.slice();
    
    while (typeof dedicatedByArray[`${index}`] === 'undefined') {
      dedicatedByArray.push('');
    }
    dedicatedByArray[`${index}`] = name;
    setDedicatedBy(dedicatedByArray);
    callbackDedicatedBy(dedicatedByArray);
    _checkNamesEntered(ceremonyName, dedicatedByArray);
  };

  const _checkNamesEntered = (ceremonyNameArray: string[], dedicatedByArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (!ceremonyNameArray[`${i}`] || ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }

       //Check if the first element in the series of names is empty
       const ceremonyNameArraysplit = ceremonyNameArray[`${i}`].split(';');
       if (ceremonyNameArraysplit.length>0&&ceremonyNameArraysplit[0].trim() === '') {
         namesEntered = false;
         break;
       }

      if (dedicatedByArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _addItem = () => {
    addRow(1);

    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    ceremonyValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
    ceremonyIDValueArray.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
    ceremonyNameArray.push('');
    dedicatedByArray.push('');

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`][`${'cartLineId'}`]] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`][`${'cartLineId'}`]);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }  

  const _updateStateData = (index: number) => {
    const ceremonyValueArray = ceremonyValue.slice();
    const ceremonyIDValueArray = ceremonyIDValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    if (typeof ceremonyValueArray[`${index}`] !== 'undefined') {
      if (ceremonyValueArray.length > 1) {
        ceremonyValueArray.splice(index, 1);
      } else {
        ceremonyValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '';
      }
    }

    if (typeof ceremonyIDValueArray[`${index}`] !== 'undefined') {
      if (ceremonyIDValueArray.length > 1) {
        ceremonyIDValueArray.splice(index, 1);
      } else {
        ceremonyIDValueArray[0] = productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '';
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    if (typeof dedicatedByArray[`${index}`] !== 'undefined') {
      if (dedicatedByArray.length > 1) {
        dedicatedByArray.splice(index, 1);
      } else {
        dedicatedByArray[0] = '';
      }
    }

    setCeremonyValue(ceremonyValueArray);
    setCeremonyIDValue(ceremonyIDValueArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackEvent(ceremonyValueArray);
    callbackEventID(ceremonyIDValueArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedBy);
  }

  
  useEffect(() => {
    const existingCeremony: string[] = [];
    const existingCeremonyID: string[] = [];
    const existingCeremonyName: string[] = [];
    const existingDedicatedBy: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        existingCeremony.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
        existingCeremonyID.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
        existingDedicatedBy.push(formItems[`${i}`][`${'dedicatedBy'}`]);
        const itemNames: string[] = formItems[`${i}`][`${'names'}`];

        let itemName = '';

        itemNames.map((name, index) => {
          if (index != 0 && index < deceasedNames) {
            itemName += ';' + name;
          } else {
            itemName += name;
          }
        });
        
        existingCeremonyName.push(itemName);
      }      

      setCeremonyName(existingCeremonyName);
      setDedicatedBy(existingDedicatedBy);

      callbackName(existingCeremonyName);
      callbackDedicatedBy(existingDedicatedBy);

      _checkNamesEntered(existingCeremonyName, existingDedicatedBy);
    } else {
      existingCeremony.push(productEvents && productEvents.length > 0 ? productEvents[0]['name'] : '');
      existingCeremonyID.push(productEvents && productEvents.length > 0 ? productEvents[0]['id'] : '');
    }

    setCeremonyValue(existingCeremony);
    setCeremonyIDValue(existingCeremonyID);

    callbackEvent(existingCeremony);
    callbackEventID(existingCeremonyID);
  }, [formItems])

   const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _buildDeceasedNameGroup = (idx: number, noOfNames: number,): JSX.Element[] => {
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
          return (
            <div className='row'>
              <div className='col-lg-6 form-field'>
                <p className='form-label'>{formLabels.deceasedName}</p>
                <input
                  type='text'
                  inputMode='text'
                  value={_displayName(ceremonyName[`${idx}`], nameIndex)}
                  aria-live='polite'
                  role='spinbutton'
                  onChange={(event: any) => _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)}
                  onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
                  className='form-control'
                  maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
                />
              </div>
            </div>
          );
    });
  }

  const _buildDedicatedName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.dedicatedBy}</p>
          <input
            type='text'
            inputMode='text'
            value={dedicatedBy[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setDedicatedByValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'deceasedName') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildDeceasedNameGroup(idx, deceasedNames)}
                          {_buildDedicatedName(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    } 

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default DeceasedDedicatedByForm;