import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import Select from 'react-select'

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackMultiplier: any;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackDedicatedBy?: any;
  callbackEventsSelected?: any;
  callbackNamesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const EventDeceasedDedicatedByForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackMultiplier, callbackEvent, callbackEventID, callbackName, callbackDedicatedBy, callbackEventsSelected, callbackNamesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents,allowMultipleRegistrations }) => {
  const [selectValue, setSelectValue] = useState(['']);
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [ceremonyEventID, setEventID] = useState(['']);
  const [dedicatedBy, setDedicatedBy] = useState(['']);

  const [multipliers, setMultiplers] = useState([1]);

  const formLabels = resource.eventDeceasedDedicatedByForm[`${localeCode}`];

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    for (let i = 0; i < event.length; ++i) {
      inputEventDates += event[`${i}`].label + (i != event.length - 1 ? ';' : '');
      inputEventIDs += event[`${i}`].value + (i != event.length - 1 ? ';' : '');
    }

    const selectValueArray = selectValue.slice();
    const eventIDArray = ceremonyEventID.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    setSelectValue(selectValueArray);
    setEventID(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(event.length, index);

    _checkEventsEntered(selectValueArray);
  }

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (selectValue.length > 0) {
      const selectValueArray = selectValue.slice();
      const eventIDArray = ceremonyEventID.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _setNameInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setCeremonyNameArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedBy);
  }

  const _setDedicatedByValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setDedicatedByArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setDedicatedByArray = (name: string, index: number) => {
    const dedicatedByArray = dedicatedBy.slice();

    while (typeof dedicatedByArray[`${index}`] === 'undefined') {
      dedicatedByArray.push('');
    }

    dedicatedByArray[`${index}`] = name;

    dedicatedByArray[`${index}`].trim();

    setDedicatedBy(dedicatedByArray);

    callbackDedicatedBy(dedicatedByArray);

    _checkNamesEntered(ceremonyName, dedicatedByArray);
  }

  const _checkEventsEntered = (ceremonyEventArray: string[]) => {
    let eventsSelected = ceremonyEventArray.length > 0 ? true : false;

    for (let i = 0; i < ceremonyEventArray.length; i++) {
      if (ceremonyEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false;
        break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _checkNamesEntered = (ceremonyNameArray: string[], dedicatedByArray: string[]) => {
    let namesEntered = ceremonyNameArray.length > 0 ? true : false;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`] && ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }

      if (dedicatedByArray[`${i}`] && dedicatedByArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkNumberOfSelectedEvent = (index: number): number => {
    const count = selectValue.length > 0 && typeof selectValue[`${index}`] !== 'undefined' ? selectValue[`${index}`].split(';').length : 1;

    return count;
  }

  const _addItem = () => {
    addRow(1);

    const multiplierArray = multipliers.slice();
    const ceremonyEventArray = selectValue.slice();
    const ceremonyEventIDArray = ceremonyEventID.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    multiplierArray.push(1);
    ceremonyEventArray.push('');
    ceremonyEventIDArray.push('');
    ceremonyNameArray.push('');
    dedicatedByArray.push('');

    setMultiplers(multiplierArray);
    setSelectValue(ceremonyEventArray);
    setEventID(ceremonyEventIDArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackMultiplier(multiplierArray);
    callbackEvent(ceremonyEventArray);
    callbackEventID(ceremonyEventIDArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    callbackEventsSelected(false);
    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const multiplierArray = multipliers.slice();
    const ceremonyEventArray = selectValue.slice();
    const ceremonyEventIDArray = ceremonyEventID.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = 1;
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof ceremonyEventArray[`${index}`] !== 'undefined') {
      if (ceremonyEventArray.length > 1) {
        ceremonyEventArray.splice(index, 1);
      } else {
        ceremonyEventArray[0] = '';
      }
    }

    if (typeof ceremonyEventIDArray[`${index}`] !== 'undefined') {
      if (ceremonyEventIDArray.length > 1) {
        ceremonyEventIDArray.splice(index, 1);
      } else {
        ceremonyEventIDArray[0] = '';
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    if (typeof dedicatedByArray[`${index}`] !== 'undefined') {
      if (dedicatedByArray.length > 1) {
        dedicatedByArray.splice(index, 1);
      } else {
        dedicatedByArray[0] = '';
      }
    }

    setSelectValue(ceremonyEventArray);
    setEventID(ceremonyEventIDArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackEvent(ceremonyEventArray);
    callbackEventID(ceremonyEventIDArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    _checkEventsEntered(ceremonyEventArray);
    _checkNamesEntered(ceremonyNameArray, dedicatedBy);
    _checkNamesEntered(ceremonyName, dedicatedByArray);
  }

  useEffect(() => {
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const existingDedicatedBy: string[] = [];
    const namesArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const eventName: string = formItems[`${i}`]['eventName'];

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        namesArray.push(formItems[`${i}`]['names'][0]);
        existingDedicatedBy.push(formItems[`${i}`]['dedicatedBy']);
      }

      setCeremonyName(namesArray);
      setDedicatedBy(existingDedicatedBy);

      callbackName(namesArray);
      callbackDedicatedBy(existingDedicatedBy);

      _checkNamesEntered(namesArray, existingDedicatedBy);
    } else {
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
    }

    setMultiplers(multipliersArray);
    setSelectValue(eventNamesArray);
    setEventID(eventIDsArray);

    callbackMultiplier(multipliersArray);
    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);

    _checkEventsEntered(eventNamesArray);
  }, [formItems])

  const _buildEventDropdown = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.eventDate}</p>
          <Select
            isMulti
            value={_displayEventDates(idx)}
            closeMenuOnSelect={false}
            options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
            onChange={(event: any) => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  }

  const _buildDeceasedName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.deceasedName}</p>
          <input
            type='text'
            inputMode='text'
            value={ceremonyName[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildDedicatedName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.dedicatedBy}</p>
          <input
            type='text'
            inputMode='text'
            value={dedicatedBy[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event: any) => _setDedicatedByValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_type === 'dropdown') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildEventDropdown(idx)}
                          {_buildDeceasedName(idx)}
                          {_buildDedicatedName(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price! * _checkNumberOfSelectedEvent(idx)}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  )
};

export default EventDeceasedDedicatedByForm;