import React, { useState, useEffect } from 'react';

import DatePicker from 'react-date-picker';
//import Select from 'react-select';

import { useForm } from 'react-hook-form';
//import { toast } from 'react-toastify';

//import countries from '../resources/countries.json';
//import period from '../../../resources/dedicationPeriod.json';
import resource from '../resources/resource.json';
//import time from '../resources/time.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState, /*ITimeRange*/ } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackStartDate: any;
  callbackName: any;
  callbackNamesEntered: any;
  callbackDateEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  userInfo: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
}

const NamesDedicationStartDateForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackStartDate, callbackName,  
  callbackNamesEntered, callbackDateEntered, 
  formItems, config, localeCode, actionContext, isMobile, isEditMode, allowMultipleRegistrations }) => {

  const [startDates, setStartDates] = useState(['']);
  const [names, setNames] = useState(['']);
 
  const [multipliers, setMultiplers] = useState([1]);

  const formLabels = resource.nameDedicationDateForm[`${localeCode}`];
 

  const startDate = new Date();
  startDate.setDate(startDate.getDate() + 1);
  startDate.setHours(0, 0, 0, 0);

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

 

  const _setStartDate = (value: Date, index: number) => {
    const valueArray = startDates.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(startDate.toISOString());
    }

    value.setHours(0, 0, 0, 0);

    const date = value ? value.toISOString() : startDate.toISOString();

    valueArray[`${index}`] = date;

    setStartDates(valueArray);

    callbackStartDate(valueArray);

    _checkDateEntered(valueArray);
  }

  const _formatNameInput = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setName(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setName = (name: string, index: number) => {
    const valueArray = names.slice();

    // To handle adding of a new form record.
    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = name;

    valueArray[`${index}`].trim();

    setNames(valueArray);

    callbackName(valueArray);

    _checkNameEntered(valueArray);
  }

 

  const _checkNameEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        checked = false; break;
      }
    }

    callbackNamesEntered(checked);
  }

  const _checkDateEntered = (startDatesArray: string[]) => {
    let result = true;

    const count = startDatesArray.length;

    for (let i = 0; i < count; i++) {
      const startDateInvalid = startDatesArray[`${i}`] === undefined || startDatesArray[`${i}`] === '' ? true : false;

      if (startDateInvalid ) {
        result = false; break;
      }
    }

    callbackDateEntered(result);
  }

 

  const _addItem = () => {
    addRow(1);

    const multipliersArray = multipliers.slice();
    const startDatesArray = startDates.slice();
    const namesArray = names.slice();

    startDatesArray.push(startDate.toDateString());
    namesArray.push('');
    setMultiplers(multipliersArray);
    setStartDates(startDatesArray);
    setNames(namesArray);

    callbackStartDate(startDatesArray);
    callbackName(namesArray);

    callbackNamesEntered(false);
    
    _checkDateEntered(startDatesArray);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const multipliersArray = multipliers.slice();
    const startDatesArray = startDates.slice();
    const namesArray = names.slice();

      setMultiplers(multipliersArray);


    if (typeof startDatesArray[`${index}`] !== 'undefined') {
      if (startDatesArray.length > 1) {
        startDatesArray.splice(index, 1);
      } else {
        startDatesArray[0] = startDate.toISOString();
      }

      setStartDates(startDatesArray);

      callbackStartDate(startDatesArray);
    }

    if (typeof namesArray[`${index}`] !== 'undefined') {
      if (namesArray.length > 1) {
        namesArray.splice(index, 1);
      } else {
        namesArray[0] = '';
      }

      setNames(namesArray);

      callbackName(namesArray);
    }

    _checkNameEntered(namesArray);
    _checkDateEntered(startDatesArray);

  }

  useEffect(() => {
    const multipliersArray: number[] = [];
    const startDatesArray: string[] = [];
    const namesArray: string[] = [];


    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
     
        startDatesArray.push(formItems[`${i}`]['startDate'] ? formItems[`${i}`]['startDate'] : startDate.toISOString());
        namesArray.push(formItems[`${i}`]['names'][0]);

      }

      setMultiplers(multipliersArray);
      setNames(namesArray);

      callbackName(namesArray);
     
      _checkNameEntered(namesArray);
      
    } else {
      
      startDatesArray.push(startDate.toISOString());
     
    }

    setStartDates(startDatesArray);

    callbackStartDate(startDatesArray);

    _checkDateEntered(startDatesArray);
  }, [formItems])

  const _buildDedicationPeriod = (idx: number): JSX.Element | null => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.startDate}</p>
          <DatePicker
            onChange={(date: Date) => _setStartDate(date, idx)}
            value={startDates[`${idx}`] === '' || startDates[`${idx}`].includes('1900') ? null : new Date(startDates[`${idx}`])}
            format={'dd-MM-y'}
            minDate={startDate}
            className={'datepicker'}
            dayPlaceholder={'DD'}
            monthPlaceholder={'MM'}
            yearPlaceholder={'YYYY'}
          />
        </div>
      </div>
    );
  }

  const _buildNameField = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.name}</p>
          <input
            type='text'
            inputMode='text'
            value={names[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _formatNameInput(event, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            autoComplete='none'
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildNameField(idx)}
                          {_buildDedicationPeriod(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${multipliers[`${idx}`] * selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default NamesDedicationStartDateForm;